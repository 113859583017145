import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Chart from "react-apexcharts";
// import { useWeb3React } from "@web3-react/core";
import { SuperPoolGraphType } from "./type";
import { ApexOptions } from "apexcharts";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { stakePageAssets } from "../../assets/exports";
import { useAccount, useNetwork } from "wagmi";

const SuperPoolGraph = ({ card, loading }: SuperPoolGraphType) => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const { currentNetwork } = useTypedSelector((state) => state.uiReducer);
  const {
    superPoolsGraph: { eth, ethV2, binanceV2, polygonV2 },
  } = useTypedSelector((state) => state.apiReducer);

  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      width: 30,
      type: "area",
      zoom: {
        enabled: false,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
      foreColor: "#fff",
      fontFamily: "Poppins",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: false,
    },

    // xaxis: {
    //   show: false,
    // },

    grid: {
      show: false,
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
  });

  useEffect(() => {
    if (chain?.id) {
      // dispatch(getSuperPoolGraphData(web3React?.chainId))
    }
  }, [address, currentNetwork]);

  useEffect(() => {
    if (eth.stakesArray.length > 0 && card.title === "Ethereum V1") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: eth.stakesArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: eth.timestampArray,
          //   show: superPoolGraphDataEth.timeArray.length > 0,
          labels: {
            datetimeUTC: false,
          },
        },

        yaxis: {
          show: eth.stakesArray.length > 0,
        },

        grid: {
          show: eth.stakesArray.length > 0,
        },
      });
    } else if (ethV2.stakesArray.length > 0 && card.title === "Ethereum V2") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: ethV2.stakesArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: ethV2.timestampArray,
          //   show: ethV2.timestampArray.length > 0,
        },

        yaxis: {
          show: ethV2.stakesArray.length > 0,
        },

        grid: {
          show: ethV2.stakesArray.length > 0,
        },
      });
    } else if (polygonV2.stakesArray.length > 0 && card.title === "Polygon") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: polygonV2.stakesArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: polygonV2.timestampArray,
          //   show: superPoolGraphDataMaticV2.timeArray.length > 0,
        },

        yaxis: {
          show: polygonV2.stakesArray.length > 0,
        },

        grid: {
          show: polygonV2.stakesArray.length > 0,
        },
      });
    } else if (binanceV2.stakesArray.length > 0 && card.title === "Binance") {
      setOptions({});
      setSeries([
        {
          name: "Total Staked",
          data: binanceV2.stakesArray,
        },
      ]);

      setOptions({
        ...options,
        colors: [card.themeColor],
        fill: {
          colors: [card.themeColor],
        },
        xaxis: {
          type: "datetime",
          categories: binanceV2.timestampArray,
          //   show: superPoolGraphDataBinanceV2.timeArray.length > 0,
        },

        yaxis: {
          show: binanceV2.stakesArray.length > 0,
        },

        grid: {
          show: binanceV2.stakesArray.length > 0,
        },
      });
    }
  }, [card, eth, ethV2, polygonV2, binanceV2]);

  return (
    <Card elevation={1} className={classes.card}>
      {loading ? (
        <img src={stakePageAssets.DafiLogoAnimated} className={classes.logo} />
      ) : (
        <CardContent className={classes.cardContent}>
          <Box display="flex" alignItems="center" className={classes.header}>
            <img className={classes.image} src={card.icon} />
            <Typography className={classes.text}>{card.title}</Typography>
          </Box>

          <Chart options={options} series={series} type="area" height={350} />
        </CardContent>
      )}
    </Card>
  );
};

export default SuperPoolGraph;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,

    display: "flex",
    flexDirection: "column",
    boxShadow: "none",

    height: 420,
    // marginRight: 16,
    padding: 24,
    marginBottom: 16,
    overflow: "unset",

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      height: 450,
    },
  },

  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 500,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 20,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    overflow: "unset !important",
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
      alignItems: "flex-start",
    },
  },

  image: {
    width: 24,

    height: 24,
    marginRight: 10,
  },
  logo: {
    margin: "0px auto",
    width: 80,
    [theme.breakpoints.down(650)]: {
      width: 45,
    },
  },
}));
