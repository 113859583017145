import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  APIActionCreators,
  ContractActionCreators,
  UIActionCreators,
} from "../state";

export const useAPIActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(APIActionCreators, dispatch);
};

export const useUIActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(UIActionCreators, dispatch);
};

export const useContractActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(ContractActionCreators, dispatch);
};
