import { ThemeProvider } from "@material-ui/core";
import { Web3ReactProvider } from "@web3-react/core";
import { FC } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Snackbar from "./components/Misc/Snackbar";
import Routes from "./router";
import { store } from "./state/store";
import { theme } from "./theme";
import { getLibrary } from "./utils/wallet";

// For Slider
import "./utils/slick/slick.css";
import "./utils/slick/slick-theme.css";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  arbitrum,
  goerli,
  mainnet,
  polygon,
  polygonMumbai,
  bsc,
} from "wagmi/chains";

const chains = [arbitrum, mainnet, polygon, polygonMumbai, goerli, bsc];
const projectId = "02bca0404e214292df5b3fd124b82bca";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const App: FC = () => {
  return (
    <>
      <ReduxProvider store={store}>
        {/* <Web3ReactProvider getLibrary={getLibrary}> */}
        <>
          <WagmiConfig config={wagmiConfig}>
            <ThemeProvider theme={theme}>
              <Router>
                <Layout>
                  <Routes />
                  <Snackbar />
                </Layout>
              </Router>
            </ThemeProvider>
          </WagmiConfig>
          {/* </Web3ReactProvider> */}
          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
      </ReduxProvider>
    </>
  );
};

export default App;
