import { FC, useEffect, useState } from "react";
import * as appConstants from "../../utils/app-constants";
import * as dashboardAssets from "../../assets/exports/dashboard";
import { Grid, makeStyles } from "@material-ui/core";
import DashboardCard from "./DashboardCard";
import { DashboardCardDataType } from "./type";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const DashboardCards: FC = () => {
  const [cardsData, setCardsData] = useState<Array<DashboardCardDataType>>([]);

  const { dashboardCardsData } = useTypedSelector((state) => state.contract);
  const { totalTokensStaked } = useTypedSelector((state) => state.apiReducer);

  const classes = useStyles();

  useEffect(() => {
    setCardsData([
      {
        text: appConstants.APP_TOTAL_STAKED_TEXT,
        value: Number(totalTokensStaked),

        icon: dashboardAssets.TotalDafiStaked,
        public: true,
      },

      {
        text: appConstants.APP_FEES_COLLECTED_TEXT,
        value: Number(dashboardCardsData.feesCollected),
        icon: dashboardAssets.TotalFeesCollectedUpdated,
        public: false,
        infoText:
          "The total network fees collected, which is redistributed between all active users gradually",
      },
      {
        text: appConstants.APP_YOUR_STAKED_TEXT,
        value: Number(dashboardCardsData.userDafiStaked),
        icon: dashboardAssets.DafiStaked,
      },

      {
        text: appConstants.APP_REWARDS_TEXT,
        value: Number(dashboardCardsData.rewards),
        icon: dashboardAssets.DafiRewards,
      },
      {
        text: appConstants.APP_POTENTIAL_REWARDS_TEXT,
        value: Number(dashboardCardsData.potentialRewards),
        icon: dashboardAssets.Potential,
        infoText:
          "This is the quantity your dToken rewards would multiply to at maximum network demand, ie. Potential APY",
      },
    ]);
  }, [dashboardCardsData, totalTokensStaked]);

  return (
    <>
      {cardsData.map((card: any, index: number) => (
        <Grid item className={classes.card} key={index}>
          <DashboardCard card={card} index={index} />
        </Grid>
      ))}
    </>
  );
};

export default DashboardCards;

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.between("sm", "lg")]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.between(600, 1050)]: {
      flex: 1,
    },

    [theme.breakpoints.down(600)]: {
      margin: "0 auto",
    },
  },
}));
