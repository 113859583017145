import { Dispatch } from "react";
import { contractFunctions } from "../../contract";
import { ContractActionType } from "../action-types/ContractActionType";
import { ContractAction } from "../actions/ContractAction";

export const makeInitialContractDataCalls =
  (account: string, network: number) => async (dispatch: any) => {
    dispatch(getTokenBalance(account));
    dispatch(getUserDafiStaked(account));
    dispatch(getFeesCollected(network));
    dispatch(getMinimumStakeAmount());
    dispatch(calculateStakingPeriod(account));
    dispatch(getDemandFactor());

    if (network === 1) {
      dispatch(getRewardAndPotentialRewardV1(account));
    } else {
      dispatch(getRewardAndPotentialReward(account));
    }
  };

export const afterActionContractDataCalls =
  (account: string, network: number) => async (dispatch: any) => {
    dispatch(getTokenBalance(account));
    dispatch(getUserDafiStaked(account));
    dispatch(getFeesCollected(network));

    if (network === 1) {
      dispatch(getRewardAndPotentialRewardV1(account));
    } else {
      dispatch(getRewardAndPotentialReward(account));
    }
  };

export const getTokenBalance =
  (account: string) => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let balance = await contractFunctions.getBalance(account);

      dispatch({
        type: ContractActionType.SET_TOKEN_BALANCE,
        payload: Number(balance),
      });
    } catch (error) {
      console.log("getTokenBalance => ", error);
    }
  };

export const setTokenBalance =
  (balance: number) => (dispatch: Dispatch<ContractAction>) => {
    dispatch({
      type: ContractActionType.SET_TOKEN_BALANCE,
      payload: balance,
    });
  };

export const getRewards =
  (account: string) => async (dispatch: Dispatch<ContractAction>) => {};

export const getUserDafiStaked =
  (account: string) => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let userDafiStaked = await contractFunctions.totalAmountStakedForAccount(
        account
      );

      console.log("getUserDafiStaked => ", userDafiStaked);

      dispatch({
        type: ContractActionType.SET_DASHBOARD_CARDS_DATA,
        payload: {
          userDafiStaked: Number(contractFunctions.fromWei(userDafiStaked)),
        },
      });
    } catch (error) {
      console.log("getUserDafiStaked error => ", error);
    }
  };

export const getFeesCollected =
  (network: number) => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let { feesCollectedFormatted } =
        await contractFunctions.getTotalFeesCollected(network === 1);

      dispatch({
        type: ContractActionType.SET_DASHBOARD_CARDS_DATA,
        payload: {
          feesCollected: feesCollectedFormatted,
        },
      });
    } catch (error) {
      console.log("feesCollected error => ", error);
    }
  };

export const getMinimumStakeAmount =
  () => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let minStakeAmount = await contractFunctions.getMinimumStakeAmount();

      dispatch({
        type: ContractActionType.SET_MINIMUM_STAKE_AMOUNT,
        payload: Number(minStakeAmount),
      });
    } catch (error) {
      console.log("getMinimumStakeAmount => ", error);
    }
  };

export const getDemandFactor =
  () => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let { demandFactorFormatted } = await contractFunctions.getDemandFactor();

      dispatch({
        type: ContractActionType.SET_DEMAND_FACTOR,
        payload: Number(demandFactorFormatted),
      });
    } catch (error) {
      console.log("getDemandFactor => ", error);
    }
  };

export const getRewardAndPotentialRewardV1 =
  (account: string) => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let { rewards, potentialRewards } =
        await contractFunctions.getRewardAndPotentialRewardV1(account);

      dispatch({
        type: ContractActionType.SET_REWARD_POTENTIAL_REWARD,
        payload: {
          rewards: Number(rewards),
          potentialRewards: Number(potentialRewards),
        },
      });
    } catch (error) {
      console.log("getRewardAndPotentialRewardV1 => ", error);
    }
  };

export const getRewardAndPotentialReward =
  (account: string) => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let { rewards, potentialRewards } =
        await contractFunctions.getRewardAndPotentialReward(account);

      dispatch({
        type: ContractActionType.SET_REWARD_POTENTIAL_REWARD,
        payload: {
          rewards: Number(rewards),
          potentialRewards: Number(potentialRewards),
        },
      });
    } catch (error) {
      console.log("getRewardAndPotentialReward => ", error);
    }
  };

export const getRewardsByAmount =
  (
    account: string,
    input: number,
    setPartialRewardLoading?: (loading: boolean) => void
  ) =>
  async (dispatch: Dispatch<ContractAction>) => {
    try {
      let { rewards, potentialRewards } =
        await contractFunctions.getRewardAndPotentialReward(account, input);

      dispatch({
        type: ContractActionType.SET_REWARDS_BY_AMOUNT,
        payload: Number(rewards),
      });
      if (setPartialRewardLoading) setPartialRewardLoading(false);
    } catch (error) {
      console.log("getRewardsByAmount => ", error);
    }
  };

export const calculateStakingPeriod =
  (account: string) => async (dispatch: Dispatch<ContractAction>) => {
    try {
      let { createdOn } = await contractFunctions.getUserStakeDetails(account);

      let minStakingPeriod = await contractFunctions.getMinimumStakePeriod();

      console.log("calculateStakingPeriod res => ", {
        createdOn,
        minStakingPeriod,
      });

      let currentTimestamp = Date.now() / 1000;

      dispatch({
        type: ContractActionType.SET_STAKING_DETAILS,
        payload: {
          stakingPeriod:
            currentTimestamp - createdOn >= Number(minStakingPeriod),
          stakingPeriodValue: createdOn,
          minStakingPeriod,
        },
      });
    } catch (error) {
      console.log("calculateStakingPeriod => ", error);
    }
  };

export const resetContractData =
  () => async (dispatch: Dispatch<ContractAction>) => {
    dispatch({
      type: ContractActionType.RESET_CONTRACT_DATA,
    });
  };
