import { makeStyles, Box } from "@material-ui/core";
import { marketAssets } from "../../../assets/exports";
import { appConstants } from "../../../utils";
import Text from "../Text";
import { TweetCardType } from "./type";

const TweetCard = ({ text, attachment }: TweetCardType) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <img
        src={marketAssets.DafiTwitter}
        style={{ marginRight: 12, borderRadius: 100, height: 50 }}
      />

      <Box>
        <Text
          text={`${appConstants.APP_TOKEN_NAME} Protocol`}
          fontSize={20}
          lineHeight={19}
          marginBottom={8}
        />

        <Text
          text={`${appConstants.APP_TWITTER_HANDLE}`}
          fontSize={16}
          lineHeight={19}
          marginBottom={6}
          color="rgba(255, 255, 255, 0.27)"
        />

        <Text
          text={text}
          customStyles={classes.tweet}
          fontSize={14}
          lineHeight={19}
          marginBottom={16}
        />

        {attachment && (
          <Box display="flex" justifyContent="center">
            <img src={attachment?.url} className={classes.tweetMedia} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TweetCard;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",

    borderBottom: "2px solid rgba(255, 255, 255, 0.03)",
    padding: "16px 0",
  },

  tweetMedia: {
    height: 300,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      height: "100%",
    },
  },

  tweet: {
    whiteSpace: "pre-wrap",
  },
}));
