import { Dispatch } from "react";
import { UIActionType } from "../action-types/UIActionType";
import { UIAction } from "../actions/UIAction";
import { DataLoadingsType, SnackbarDataType } from "../Type/UITypes";

export const setCurrentNetwork =
  (network: number) => (dispatch: Dispatch<UIAction>) => {
    dispatch({
      type: UIActionType.SET_CURRENT_NETWORK,
      payload: network,
    });
  };

export const resetUIData = () => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.RESET_UI_DATA,
  });
};

export const setAppLoading =
  (loading: boolean) => (dispatch: Dispatch<UIAction>) => {
    dispatch({
      type: UIActionType.SET_APP_LOADING,
      payload: loading,
    });
  };

export const setDataLoadings =
  (loadingType: DataLoadingsType) => (dispatch: Dispatch<UIAction>) => {
    console.log("setDataLoadings", loadingType);
    dispatch({
      type: UIActionType.SET_DATA_LOADINGS,
      payload: loadingType,
    });
  };

export const setFirstAppLoading =
  (loading: boolean) => (dispatch: Dispatch<UIAction>) => {
    dispatch({
      type: UIActionType.SET_FIRST_APP_LOADING,
      payload: loading,
    });
  };

export const setNetwork =
  (networkSet: boolean) => (dispatch: Dispatch<UIAction>) => {
    dispatch({
      type: UIActionType.SET_NETWORK,
      payload: networkSet,
    });
  };

export const setSnackbar =
  (details: SnackbarDataType, open: boolean = true) =>
  (dispatch: Dispatch<UIAction>) => {
    dispatch({
      type: UIActionType.SET_SNACKBAR,
      payload: { ...details, open: open },
    });
  };
