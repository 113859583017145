import { Box, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MessageModalType } from "./type";

const MessageModal = ({ onSuccess, message }: MessageModalType) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.contentContainer}>
        <Box
          display="flex"
          justifyContent="flex-end"
          onClick={onSuccess}
          marginBottom={"20px"}
        >
          <CloseIcon style={{ color: "#fff", cursor: "pointer" }} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.text}>{message}</Typography>

          {/* <BlockBtn
          label={"Close"}
          handleClick={onSuccess}
          
        /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default MessageModal;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 18,
    fontWeight: 500,
    color: "#fff",
    marginBottom: 24,
    lineHeight: "10px",
  },

  text: {
    fontWeight: 400,
    marginBottom: 30,
    fontSize: 16,
    lineHeight: "20px",
    color: "#fff",
    textAlign: "center",
  },

  contentContainer: {
    width: 400,
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 1) -23.88%, rgba(15, 8, 36, 1) 100%)",
    border: "2px solid rgba(255, 255, 255, 0.05)",

    borderRadius: 15,
    padding: 20,
    display: "flex",
    minHeight: 200,

    flexDirection: "column",
    justifyContent: "center",
    margin: "0 20px",

    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));
