import { Box, Button, makeStyles, Typography, Theme } from "@material-ui/core";

import CopyToClipboard from "react-copy-to-clipboard";
import { dashboardAssets, leaderboardAssets } from "../../assets/exports";
import { useUIActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";
import { conciseAddress, sixDigitsFormatter } from "../../utils/formatters";
import { SelfRankType } from "./type";

const SelfRank = ({
  selfRank: { amount, rankNo, rank, account },
}: SelfRankType) => {
  const classes = useStyles({ rank, rankNo });

  const { isCrown } = useTypedSelector((state) => state.apiReducer);

  const { setSnackbar } = useUIActions();

  let avatar =
    rank === "Voyager"
      ? leaderboardAssets.Voyager
      : rank === "Supernova" && rankNo === 1
      ? leaderboardAssets.Master1
      : rank === "Supernova" && rankNo === 2
      ? leaderboardAssets.Master2
      : rank === "Supernova" && rankNo === 3
      ? leaderboardAssets.Master3
      : rank === "Cosmonaut"
      ? leaderboardAssets.Cosmonaut
      : rank === "Lunar"
      ? leaderboardAssets.Lunar
      : rank === "Apollo"
      ? leaderboardAssets.Apollo
      : leaderboardAssets.Pioneer;

  let AbsoluteImage1 =
    rank === "Voyager" ? (
      <img
        src={leaderboardAssets.Vector}
        style={{
          position: "absolute",
          height: 300,
          right: 0,
          zIndex: -1,
          top: "18%",
        }}
      />
    ) : (
      <img
        src={leaderboardAssets.Vector}
        style={{
          position: "absolute",
          height: 300,
          right: 0,
          zIndex: -1,
          top: "18%",
        }}
      />
    );

  const getAbsoluteImage1 = (rank: any) => {
    return rank === "Voyager" ? (
      <img
        src={leaderboardAssets.Vector}
        style={{
          position: "absolute",
          height: 300,
          right: 0,
          zIndex: -1,
          top: "18%",
        }}
      />
    ) : rank === "Pioneer" ? (
      <img
        src={leaderboardAssets.Moon}
        style={{
          position: "absolute",
          height: 350,
          right: 0,
          zIndex: -1,
          top: "13%",
        }}
      />
    ) : rank === "Lunar" ? (
      <img
        src={leaderboardAssets.StarShoot}
        style={{
          position: "absolute",
          height: 370,
          right: 10,
          zIndex: -1,
          top: "0%",
        }}
      />
    ) : rank === "Apollo" ? (
      <img
        src={leaderboardAssets.SolarSystem}
        style={{
          position: "absolute",
          height: 350,
          right: 0,
          // zIndex: -1,
          top: "12%",
        }}
      />
    ) : rank === "Cosmonaut" ? (
      <img
        src={leaderboardAssets.Rocket}
        style={{
          position: "absolute",
          height: 700,
          right: 0,
          zIndex: -1,
          top: "12%",
        }}
      />
    ) : rank === "Supernova" && rankNo === 1 ? (
      <img
        src={leaderboardAssets.Supernova1}
        style={{
          position: "absolute",
          height: 350,
          right: 0,
          zIndex: -1,
          top: "10%",
        }}
      />
    ) : rank === "Supernova" && rankNo === 2 ? (
      <img
        src={leaderboardAssets.Supernova2}
        style={{
          position: "absolute",
          height: 500,
          right: 0,
          zIndex: -1,
          top: "0%",
        }}
      />
    ) : rank === "Supernova" && rankNo === 3 ? (
      <img
        src={leaderboardAssets.Supernova3}
        style={{
          position: "absolute",
          height: 600,
          right: 0,
          zIndex: -1,
          top: "5%",
        }}
      />
    ) : (
      <img
        src={leaderboardAssets.Vector}
        style={{
          position: "absolute",
          height: 300,
          right: 0,
          zIndex: -1,
          top: "18%",
        }}
      />
    );
  };
  return (
    <Box className={classes.container}>
      {getAbsoluteImage1(rank)}
      <Box className={classes.contentContainer}>
        <Box
          // display="flex"
          alignItems="center"
          marginBottom="30px"
          marginTop="20px"
          width="fit-content"
        >
          <Box display="flex" width="fit-content">
            <Typography className={classes.rankText}>#{rankNo}</Typography>
            <Box>
              <Typography className={classes.heading}>{rank}</Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {isCrown && (
              <img
                src={dashboardAssets.Crown}
                className={classes.crown}
                alt="crown"
              />
            )}
            <Typography className={classes.address}>
              {conciseAddress(account)}
            </Typography>

            <CopyToClipboard
              text={account}
              onCopy={() => {
                setSnackbar({
                  message: "Address Copied",
                  severity: "success",
                });
              }}
            >
              <Button className={classes.copyBtn}>
                <img src={dashboardAssets.Copy} style={{ height: 20 }} />
              </Button>
            </CopyToClipboard>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        ></Box>

        <Box display="flex" justifyContent="center">
          <img className={classes.avatarImage} src={avatar} alt="avatar" />
        </Box>

        <Box className={classes.stakeCircle}>
          <Typography variant="subtitle1" className={classes.mainRank}>
            {sixDigitsFormatter(Number(amount))}
          </Typography>
          <Typography className={classes.rankPara}>Super Staked</Typography>
          <img
            src={leaderboardAssets.CoinGraphic}
            style={{
              position: "absolute",
              height: 140,
              right: 0,
              zIndex: -1,
              bottom: 0,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { rank: any; rankNo: any }>((theme) => ({
  container: {
    // height: "80vh",
    marginRight: "27px",

    // marginTop: "20px",
    [theme.breakpoints.only("xs")]: {
      marginRight: 0,
      height: "100%",
      marginBottom: "10px",
      marginTop: "20px",
    },
    [theme.breakpoints.between("sm", 1350)]: {
      // marginRight: 0,
      // height: "100%",
      marginBottom: "10px",
      marginRight: 0,

      marginTop: "20px",
      height: "100%",
    },
    background: ({ rank, rankNo }) =>
      rankNo === 1 && rank === "Supernova"
        ? appConstants.SUPERNOVA_1_BG
        : rankNo === 2 && rank === "Supernova"
        ? appConstants.SUPERNOVA_2_BG
        : rankNo === 3 && rank === "Supernova"
        ? appConstants.SUPERNOVA_3_BG
        : rank === "Cosmonaut"
        ? appConstants.COSMONAUT_BG
        : rank === "Apollo"
        ? appConstants.APOLLO_BG
        : rank === "Lunar"
        ? appConstants.LUNAR_BG
        : rank === "Voyager"
        ? appConstants.VOYAGER_BG
        : appConstants.PIONEER_BG,
    // border: "1px solid rgba(230, 231, 233, 0.1)",

    boxShadow: "0px 0px 27.7108px #FFFFFF",
    borderRadius: "25px",

    position: "relative",
    width: 400,
    overflow: "hidden",
    height: "100%",
    [theme.breakpoints.down(1350)]: {
      margin: "20px auto",
    },

    [theme.breakpoints.between(1350, 1460)]: {
      width: 320,
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentContainer: {
    padding: "40px 20px",
    height: "100%",
  },

  copyBtn: {
    padding: 0,
    minWidth: "auto",
    width: 30,
    height: 30,
  },
  mainRank: {
    color: "white",
    fontSize: "70px ",
    lineHeight: "70px",
    fontWeight: 800,
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px ",
      lineHeight: "26px",
    },
  },

  stakeCircle: {
    width: 280,
    height: 280,
    border: "25px solid",

    borderColor: ({ rank, rankNo }) =>
      rankNo === 1 && rank === "Supernova"
        ? appConstants.SUPERNOVA_1_BORDER
        : rankNo === 2 && rank === "Supernova"
        ? appConstants.SUPERNOVA_2_BORDER
        : rankNo === 3 && rank === "Supernova"
        ? appConstants.SUPERNOVA_3_BORDER
        : rank === "Cosmonaut"
        ? appConstants.COSMONAUT_BORDER
        : rank === "Apollo"
        ? appConstants.APOLLO_BORDER
        : rank === "Lunar"
        ? appConstants.LUNAR_BORDER
        : rank === "Voyager"
        ? appConstants.VOYAGER_BORDER
        : appConstants.PIONEER_BORDER,
    borderRadius: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      width: 150,
      height: 150,
      border: "15px solid #ccc",
    },

    position: "relative",
  },
  crown: {
    width: "22px",
    height: "22px",
    marginRight: "10px",
  },
  avatarImage: {
    height: 180,
    // width: 180,
    [theme.breakpoints.only("xs")]: {
      width: 140,
      height: 140,
    },

    marginBottom: 50,
  },

  heading: {
    fontSize: "35px",
    color: "white",
    fontWeight: 800,
    lineHeight: "35px",
    marginBottom: 12,

    [theme.breakpoints.between(1350, 1460)]: {
      fontSize: "30px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px",
    },
  },
  rankText: {
    fontSize: "56px",
    color: "white",
    fontWeight: 800,
    lineHeight: "43px",
    marginRight: "10px",
    display: "flex",
    alignSelf: "start",

    [theme.breakpoints.between(1350, 1460)]: {
      fontSize: "37px",
      marginRight: "6px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px",
    },
  },
  rankPara: {
    color: "white",
    fontSize: "21px",
    lineHeight: "25px",
    fontWeight: 600,
    [theme.breakpoints.only("xs")]: {
      fontSize: "12px",
    },
  },
  address: {
    fontSize: "22px",
    color: "white",
    [theme.breakpoints.between(1350, 1460)]: {
      fontSize: "19px",
    },
    lineHeight: "20px",
    [theme.breakpoints.only("xs")]: {
      fontSize: "12px",
    },
  },
}));

export default SelfRank;
