import { useSelector } from "react-redux";
import { Close } from "@material-ui/icons";

import {
  Dialog,
  Modal,
  Container,
  Box,
  IconButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

import BlockButton from "../Buttons/BlockButton";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { StakeModalType } from "./type";

const StakeModal = ({
  open,
  onClose,
  text,
  modalFunc,
  loadText,
  userInput,
  approvalValue,
}: StakeModalType) => {
  const classes = useStyles();
  const { appLoading } = useTypedSelector((state) => state.uiReducer);

  console.log("StakeModal: ", {
    userInput,
    approvalValue,
    loadText,
  });

  return (
    <>
      <Modal
        open={open}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        className={classes.dialog}
        // PaperProps={{
        //   style: { background: "transparent", top: 20 },
        // }}
        classes={{
          paper: classes.dialogPosition,
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container className={classes.container}>
          <div className={classes.box}>
            <IconButton onClick={onClose} className={classes.closeIcon}>
              <Close style={{ color: "#fff" }} />
            </IconButton>
          </div>
          <div className={classes.centerAlign}>
            {appLoading && (
              <>
                <CircularProgress className={classes.spinner} size={50} />
                <div className={classes.headerText}>
                  {`${
                    Number(userInput) <= Number(approvalValue)
                      ? "Transaction"
                      : "Approval"
                  }  in process`}
                </div>
              </>
            )}
            {appLoading ? (
              <div className={classes.infoText}>{loadText}</div>
            ) : (
              <div className={classes.infoText}>{text}</div>
            )}
            {!appLoading && (
              <BlockButton label={"Proceed"} onClick={() => modalFunc()} />
            )}
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default StakeModal;

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  closeIcon: {
    padding: "0px",
  },
  container: {
    background: theme.palette.cardBgColor,
    color: "#fff",
    paddingTop: "20px",
    paddingBottom: "20px",
    borderRadius: "10px",
    width: "332px",
    "@media(max-width:550px)": {},
  },
  dialog: {
    background: "rgba(15, 8, 36, 0.55)",
    border: "1px solid rgba(230, 231, 233, 0.1)",
    boxShadow:
      "inset 0px 23.0177px 33.051px -33px rgba(255, 255, 255, 0.5), inset 0px 4.13137px 6.49216px -2.36078px rgba(255, 255, 255, 0.25), inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12), inset 0px 57.8392px 59.0196px -28.3294px rgba(255, 255, 255, 0.12), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)",
    backdropFilter: "blur(5px)",
    minWidth: 200,
  },
  dialogPosition: {
    position: "absolute",

    transform: "translateY(30vh)",
    "@media(max-width:550px)": {
      transform: "translateY(50%)",
    },
  },
  infoImg: {
    marginBottom: "24px",
  },
  infoText: {
    textAlign: "center",
    marginBottom: "26px",
    width: "80%",
  },
  headerText: {
    textAlign: "center",
    marginBottom: "5px",
    width: "90%",
  },
  centerAlign: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  spinner: {
    color: "#68E4FF",
    marginBottom: "34px",
  },
}));
