import { makeStyles, Typography, Button, Box } from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { isMobile } from "react-device-detect";
import { FC, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { dashboardAssets } from "../../assets/exports";
import { contractFunctions } from "../../contract";
import { initializeContractStuff } from "../../contract/functions";
import {
  useAPIActions,
  useContractActions,
  useUIActions,
} from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { DRAWER_WIDTH, SUPPORTED_CHAIN_IDS } from "../../utils/app-constants";
import Header from "../Header";
import ResponsiveDrawer from "../Sidebar";
import ConnectWallet from "../Wallet/ConnectWallet";
import { LayoutProps } from "./types";
import { useLocation } from "react-router-dom";
import { useAccount, useNetwork, usePublicClient } from "wagmi";

const Layout: FC<LayoutProps> = ({ children }) => {
  const { makeInitialApiDataCalls, makeOffWalletApiDataCalls, resetAPIData } =
    useAPIActions();

  const { setCurrentNetwork, setFirstAppLoading, setNetwork, setSnackbar } =
    useUIActions();

  const { makeInitialContractDataCalls, setTokenBalance, resetContractData } =
    useContractActions();

  const apiReducer = useTypedSelector((state) => state.apiReducer);
  const { currentNetwork, firstAppLoading, networkSet, appLoading } =
    useTypedSelector((state) => state.uiReducer);
  const contract = useTypedSelector((state) => state.contract);

  // const {  library } = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const provider = usePublicClient({ chainId: chain?.id });
  console.log("chainId  => ", chain?.id);

  const location = useLocation();

  const classes = useStyles();

  const [showLaunchScreen, setShowLaunchScreen] = useState(true);

  useEffect(() => {
    if (chain?.id === undefined && networkSet) {
      setNetwork(false);
      setCurrentNetwork(0);
      resetContractData();
      resetAPIData();
      // setSnackbar({
      //   open: true,
      //   message: "Please connect to a network",
      //   severity: "error",
      // });
    } else if (chain?.id && SUPPORTED_CHAIN_IDS.indexOf(chain?.id!) === -1) {
      setNetwork(false);
      setCurrentNetwork(0);
      resetContractData();
      resetAPIData();
      setSnackbar({
        open: true,
        message: "Please connect to a supported network",
        severity: "error",
      });
    }
  }, [chain?.id]);

  useEffect(() => {
    console.log("networkSet: ", {
      networkSet,
      currentNetwork,
      chainId: chain?.id,
    });
    if (chain?.id && !networkSet) {
      if (currentNetwork === 1) {
        setCurrentNetwork(1); // Ethereum V1
        initializeContractStuff(1, provider, setNetwork);
      } else if (
        currentNetwork === 2 ||
        (currentNetwork === 0 && chain?.id === 1)
      ) {
        setCurrentNetwork(2); // Ethereum V2
        initializeContractStuff(2, provider, setNetwork);
      } else if (
        currentNetwork === 3 ||
        (currentNetwork === 0 && chain?.id === 137)
      ) {
        setCurrentNetwork(3); // Polygon
        initializeContractStuff(3, provider, setNetwork);
      } else if (
        currentNetwork === 4 ||
        (currentNetwork === 0 && chain?.id === 56)
      ) {
        setCurrentNetwork(4); // Binance
        initializeContractStuff(
          4,
          "https://bsc-dataseed.binance.org/",
          setNetwork
        );
      }
    }
  }, [chain?.id, networkSet]);

  useEffect(() => {
    console.log("currentNetwork => ", { currentNetwork, networkSet, address });

    if (isMobile) {
      setTimeout(() => {
        setFirstAppLoading(false);
      }, 3000);
    }
    setTimeout(() => {
      setFirstAppLoading(false);
    }, 3000);

    if (currentNetwork && address && networkSet) {
      // initializeContractStuff(library?.currentProvider, currentNetwork);
      makeInitialContractDataCalls(address, currentNetwork);
      makeInitialApiDataCalls(address, currentNetwork);
    } else if (!address || currentNetwork === 0) {
      makeOffWalletApiDataCalls();
    }
  }, [address, networkSet, currentNetwork]);

  // For chain change using metamask
  useEffect(() => {
    if (chain?.id && networkSet && currentNetwork !== 0) {
      setNetwork(false);
      console.log("chain?.id code running");
      if (chain?.id === 1) {
        setCurrentNetwork(2); // Ethereum V2
        initializeContractStuff(2, provider, setNetwork);
      } else if (chain?.id === 56) {
        setCurrentNetwork(4); // Binance
        initializeContractStuff(4, provider, setNetwork);
      } else if (chain?.id === 137) {
        setCurrentNetwork(3); // Polygon
        initializeContractStuff(3, provider, setNetwork);
      }
    }
  }, [chain?.id]);

  const displayMessage = (id: any) => {
    console.log("displayMessage: ", id);
    if (id !== undefined) {
      if (id !== 1 && id !== 56 && id !== 137) {
        // setSnackbar({
        //   severity: "warning",
        //   message: "You are on unsupported network",
        // });
        // deactivateWallet();
      }
    }
  };

  const debounceDisplayMessage = useCallback(
    _.debounce(displayMessage, 1000),
    []
  );

  window?.ethereum?.on("chainChanged", function (networkId: any) {
    debounceDisplayMessage(parseInt(networkId, 16));
  });

  return (
    <div className={classes.root}>
      {/* <ConnectWallet /> */}

      {firstAppLoading ? (
        <div className="full-page-loader">
          <img
            src={dashboardAssets.DafiLoader}
            className="full-page-loader-image"
          />
        </div>
      ) : showLaunchScreen ? (
        <div className="launch-screen">
          <Box className={classes.textContainer}>
            <Typography className={classes.heading}>
              Sit back, relax, and get rewarded with our redefined, redesigned,
              and revamped Staking 2.0 module.
            </Typography>
            <Button
              className={classes.btnText}
              onClick={() => setShowLaunchScreen(false)}
            >
              Super Stake now!
            </Button>
          </Box>
        </div>
      ) : (
        <>
          <ResponsiveDrawer />
          <div className={classes.page}>
            <Header />
            <div className={classes.toolbar} style={{ height: "100%" }}>
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Layout;

const useStyles = makeStyles((theme) => {
  return {
    page: {
      [theme.breakpoints.up("lg")]: {
        padding: 20,
        marginTop: 0,
      },
      width: "100%",
      padding: "0 16px",

      marginTop: "100px",

      maxWidth: "1400px",
      margin: "0 auto",
    },
    root: {
      display: "flex",
      overflow: "hidden",
    },

    drawer: {
      width: DRAWER_WIDTH,

      background: "transparent",
    },

    drawerPaper: {
      width: DRAWER_WIDTH,

      background: "transparent",
      opacity: 0.75,
      border: "1px solid rgba(230, 231, 233, 0.1)",
    },
    toolbar: theme.mixins.toolbar,

    title: {
      padding: theme.spacing(2),
      color: "#fff",
    },

    listItemText: {
      color: "rgba(255,255,255,.7)",
      fontSize: 22,
      lineHeight: "27px",
    },

    listItemTextActive: {
      color: "rgba(255,255,255)",
      fontSize: 22,
      lineHeight: "27px",
    },

    active: {
      color: "#fff",
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },

    textContainer: {
      width: "700px",
      marginLeft: 200,
      marginTop: 100,

      [theme.breakpoints.down(1000)]: {
        marginLeft: 100,
        marginTop: 100,
      },

      [theme.breakpoints.down(800)]: {
        marginLeft: 30,
        marginTop: 100,
      },

      [theme.breakpoints.down(600)]: {
        width: "500px",
      },

      [theme.breakpoints.down(450)]: {
        width: "400px",
      },

      [theme.breakpoints.down(400)]: {
        marginLeft: 10,
        width: "340px",
      },
    },

    heading: {
      fontSize: "48px",
      lineHeight: "57px",
      fontWeight: 700,
      color: "#fff",
      fontFamily: "Roboto",
      marginBottom: 20,

      [theme.breakpoints.down(800)]: {
        fontSize: "48px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down(600)]: {
        fontSize: "48px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down(550)]: {
        fontSize: "42px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down(500)]: {
        fontSize: "35px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down(450)]: {
        fontSize: "30px",
        lineHeight: "47px",
      },
    },

    btnText: {
      backgroundColor: "#fff",
      width: 200,

      fontSize: "16px",
      lineHeight: "31px",
      fontWeight: 600,

      "&:hover": {
        backgroundColor: "rgba(255,255,255,.8)",
      },
    },
  };
});
