import { LocalGasStation } from "@material-ui/icons";
import StakeModal from "../Modal/StakeModal";
import AmountInput from "../Misc/AmountInput";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BlockButton from "../Buttons/BlockButton";
// import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { stakePageAssets } from "../../assets/exports";
import { getBalanceNumberFormatted, hasDecimal } from "../../utils/formatters";
import { appConstants } from "../../utils";
import { contractFunctions } from "../../contract";
import {
  useAPIActions,
  useContractActions,
  useUIActions,
} from "../../hooks/useActions";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import Web3 from "web3";
import { APP_TOKEN_NAME } from "../../utils/app-constants";
import { networkContractAddresses } from "../../contract/functions/contractInit";

const Stake = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const { currentNetwork, appLoading, networkSet } = useTypedSelector(
    (state) => state.uiReducer
  );

  const { tokenBalance, minStakingAmount } = useTypedSelector(
    (state) => state.contract
  );

  const { estimateGasPrice } = useTypedSelector((state) => state.apiReducer);

  const { setAppLoading, setSnackbar } = useUIActions();

  const { afterActionContractDataCalls } = useContractActions();

  const { afterActionAPIDataCalls } = useAPIActions();

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  const [approvalValue, setApprovalValue] = useState("0");

  const [userInput, setUserInput] = useState("");

  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  useEffect(() => {
    // async function getApprovalAsync() {
    //   if (address) {
    //     let approval = await contractFunctions.checkApproval(address);
    //     setApprovalValue(contractFunctions.fromWei(approval));
    //   }
    // }
    // if (currentNetwork) getApprovalAsync();

    if (currentNetwork) {
      getApprovalValue();
    }
  }, [address, currentNetwork, chain?.id, open]);

  const getApprovalValue = async () => {
    console.log("getApprovalValue called");
    let approval = await contractFunctions.checkApproval(address!);
    setApprovalValue(contractFunctions.fromWei(approval));
  };

  const onApprovalCallback = async () => {
    let counter = 0;

    getApprovalValue();

    setTimeout(() => {
      setSnackbar({
        message: "Updating values",
        open: true,
      });

      getApprovalValue();
    }, 5000);

    let interval = setInterval(() => {
      if (counter < 5) {
        setSnackbar({
          message: "Updating values",
          open: true,
        });

        getApprovalValue();

        counter = counter + 1;
      } else {
        clearInterval(interval);
      }
    }, 5000);
  };

  console.log("approvalValue", approvalValue);

  let modalFunc = () => {
    if (Number(userInput) > Number(approvalValue)) {
      // contractFunctions.giveApproval(
      //   userInput,
      //   setApprovalValue,
      //   address!,
      //   setAppLoading,
      //   setOpen,
      //   setSnackbar,
      //   onApprovalCallback
      // );
      setAppLoading(true);
      if (approveWrite) approveWrite();
    } else {
      onStake(userInput, tokenBalance);
    }
  };

  const { config: approveConfig } = usePrepareContractWrite({
    //@ts-ignore
    address: networkContractAddresses[currentNetwork][3],
    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],

    functionName: "approve",
    args: [
      //@ts-ignore
      networkContractAddresses[currentNetwork][0],
      //@ts-ignore
      Web3.utils.toWei(userInput.toString() || "0"),
    ],
  });
  const { data: approveData, write: approveWrite } = useContractWrite({
    ...approveConfig,
    // args: [Web3.utils.toWei(value.toString())]
  });
  const { isLoading: approveIsLoading, isSuccess: approveIsSuccess } =
    useWaitForTransaction({
      hash: approveData?.hash,
    });

  const { config } = usePrepareContractWrite({
    //@ts-ignore
    address: networkContractAddresses[currentNetwork][0],
    abi: [
      {
        inputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "stake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],

    functionName: "stake",
    //@ts-ignore
    args: [Web3.utils.toWei(userInput.toString() || "0")],
  });
  const { data, write } = useContractWrite({
    ...config,
    // args: [Web3.utils.toWei(value.toString())]
  });
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const onStake = async (value: any, balance: any) => {
    value = Number(value);
    balance = Number(balance);

    console.log("onStake called => ", value, balance);

    if (value >= minStakingAmount && value <= balance) {
      // contractFunctions.stakeAmount(
      //   value,
      //   address!,
      //   setAppLoading,
      //   setOpen,
      //   setSnackbar,
      //   setUserInput,
      //   onStakeCallback
      // );
      setAppLoading(true);
      if (write) write();
    } else if (value > balance) {
      setSnackbar({ message: "Not enough balance", severity: "error" });
    } else if (value > approvalValue) {
      setSnackbar({
        message: "Value should not be greater than allowance",
        severity: "error",
      });
    } else {
      setSnackbar({
        message: "Amount should be greater than or equal to 2500",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (isSuccess && data?.hash) {
      setSnackbar({
        message: `You've staked ${userInput} ${APP_TOKEN_NAME}`,
        severity: "success",
      });
      setOpen(false);
      setAppLoading(false);

      setUserInput("");
      onStakeCallback();
    }
  }, [isLoading, isSuccess, data]);

  useEffect(() => {
    if (approveIsSuccess && approveData?.hash) {
      setSnackbar({
        message: `Approval given for amount ${userInput}`,
        severity: "success",
      });
      setOpen(false);
      setAppLoading(false);

      setUserInput("");
      onApprovalCallback();
    }
  }, [approveIsLoading, approveIsSuccess, approveData]);

  const onStakeCallback = () => {
    let counter = 0;

    setTimeout(() => {
      setSnackbar({
        message: "Updating values",
        open: true,
      });

      afterActionContractDataCalls(address!, currentNetwork);
      afterActionAPIDataCalls(address!, currentNetwork);
    }, 5000);

    let interval = setInterval(() => {
      if (counter < 5) {
        setSnackbar({
          message: "Updating values",
          open: true,
        });

        afterActionContractDataCalls(address!, currentNetwork);
        afterActionAPIDataCalls(address!, currentNetwork);

        console.log("Yeah it is being called every 10 seconds");

        counter = counter + 1;
      } else {
        clearInterval(interval);
      }
    }, 10000);
  };

  const onChangeInput = (input: any) => {
    //if check to ensure that input does not accept spacez
    if (hasDecimal(input)) {
      let split = input.split(".");
      if (split[0].length > 18 || split[1].length > 6) {
        return;
      }
    } else if (input.length > 18) {
      return;
    }

    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    if (Number.isNaN(Number(input))) {
      if (Number(input) < minStakingAmount) {
        setInputErrText(
          `Insufficient amount. Minimum staking amount must be ${minStakingAmount}`
        );
        setShowInputErr(true);
      } else if (Number(input)) {
        setInputErrText("Your current balance is less than the amount entered");
        setShowInputErr(true);
      }
      return;
    }
    setShowInputErr(false);
    setInputErrText("");
    if (Number(input) <= tokenBalance) {
      // input = Math.abs(input);

      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
      if (Number(input) < minStakingAmount) {
        setInputErrText(
          `Insufficient amount. Minimum staking amount must be ${minStakingAmount}`
        );
        setShowInputErr(true);
      }
    } else {
      if (Number(input)) {
        setUserInput(input);
        setInputErrText("Your current balance is less than the amount entered");
        setShowInputErr(true);
      }
    }
  };

  const onMaxClick = () => {
    if (Number(tokenBalance) >= minStakingAmount) {
      setShowInputErr(false);
      setInputErrText("");
      setUserInput(getBalanceNumberFormatted(tokenBalance));
    } else {
      setShowInputErr(true);
      setInputErrText(
        `Insufficient amount. Minimum staking amount must be ${minStakingAmount}`
      );
      setUserInput(getBalanceNumberFormatted(tokenBalance));
    }
  };

  const handleClickOpen = () => {
    if (Number(userInput) <= Number(approvalValue)) {
      setModalText(
        `Are you sure that you want to stake ${userInput} ${appConstants.APP_TOKEN_NAME}?`
      );
      setModalLoadText(`Staking ${userInput} ${appConstants.APP_TOKEN_NAME}`);
    } else if (Number(userInput) > Number(approvalValue)) {
      setModalText(`Get approval for amount ${userInput}`);
      setModalLoadText(``);
    }

    setOpen(true);
  };

  return (
    <div className={classes.container}>
      <StakeModal
        open={open}
        onClose={() => setOpen(false)}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        userInput={userInput}
        approvalValue={approvalValue}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.headerContainer}>
                <div></div>
                <Typography className={classes.title}>Super Stake</Typography>
                {currentNetwork === 3 ? (
                  <div className={classes.gasContainer}></div>
                ) : (
                  <div className={classes.gasContainer}>
                    <LocalGasStation className={classes.gasIcon} />
                    <Typography className={classes.gasTitle}>
                      {estimateGasPrice ? estimateGasPrice?.safeGasPrice : 0}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.horizontalLine}></div>
              {currentNetwork === 1 && (
                <div className={classes.withdrawInfo}>
                  <img
                    src={stakePageAssets.Info}
                    className={classes.infoIcon}
                  />
                  <Typography className={classes.InfoText}>
                    To stake, please switch to v2
                  </Typography>
                </div>
              )}

              <AmountInput
                userBalance={tokenBalance}
                userInput={userInput}
                inputErrText={inputErrText}
                showInputErr={showInputErr}
                disabled={currentNetwork === 1 || !networkSet}
                maxDisabled={
                  tokenBalance <= 0 || !networkSet || currentNetwork === 1
                }
                onChangeInput={onChangeInput}
                onMaxClick={onMaxClick}
                type="stake"
              />

              <div className={classes.marginBtm}></div>

              <BlockButton
                label={!appLoading ? "Approve" : "Transaction Pending"}
                onClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  Number(userInput) <= Number(approvalValue) ||
                  !address
                }
              />
              <div className={classes.marginBtm}></div>
              <BlockButton
                label={!appLoading ? "Stake" : "Transaction Pending"}
                onClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  appLoading ||
                  !userInput ||
                  Number(userInput) > Number(approvalValue) ||
                  !address
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Stake;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },
  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },

  InfoText: {
    fontSize: "14px",
    lineHeight: "19px",
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#ffffff",
    marginLeft: 40,
  },

  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  headerContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "38px",
    justifyContent: "space-between",
  },

  gasIcon: {
    color: "#23FCCF",
    marginRight: "5px",
  },

  gasTitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#fff",
  },

  gasContainer: {
    display: "flex",
    alignItems: "center",
  },

  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,

    color: "#FFF",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },
}));
