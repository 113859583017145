export const API_BASE_URL = "https://backend.dafiprotocol.io";
export const API_DEV_BASE_URL = "http://54.86.186.137/api";
export const GRAPH_BASE_URL =
  "https://api.thegraph.com/subgraphs/name/danialansari";

export const GRAPH_BASE_URL_2 =
  "https://api.thegraph.com/subgraphs/name/pro123908";

export const COIN_GECKO_API = "https://api.coingecko.com/api/v3";

export const APP_FONT_NAME = "Poppins";

export const DRAWER_WIDTH = 250;

export const APP_NAME = "DAFI";
export const APP_DASHBOARD_TITLE = `${APP_NAME} Dashboard`;
export const APP_STAKE_PAGE_TITLE = `${APP_NAME} Super Staking`;

export const APP_SUPERPOOL_PAGE_TITLE = `${APP_NAME} Super Pool`;

export const APP_MARKET_PAGE_TITLE = `${APP_NAME} Market`;

export const APP_RANKS_PAGE_TITLE = `${APP_NAME} Ranks`;

export const APP_TOTAL_STAKED_TEXT = "Total DAFI Staked";

export const APP_YOUR_STAKED_TEXT = "Your DAFI Staked";
export const APP_FEES_COLLECTED_TEXT = "Fees Collected";
export const APP_REWARDS_TEXT = "Rewards";
export const APP_POTENTIAL_REWARDS_TEXT = "Potential";

export const CHAIN_IDS = [1, 137, 42, 80001, 4, 56];

export const APP_TOKEN_NAME = "DAFI";

export const APP_UNSTAKING_MESSAGE =
  "A 25% toll is deducted from your dDAFI rewards when exiting a pool or claiming rewards. These tokens are then distributed amongst Super Stakers, boosting APY.";

export const AMOUNT_FEE_RATIO = 0.75;

export const COIN_CIRCULATING_SUPPLY = 888364182;

export const COIN_UNISWAP_LINK =
  "https://v2.info.uniswap.org/pair/0xcd4a2f72e3d646e8addab74a68c2175d6a36b0e3";

export const COIN_ASCENDEX_LINK =
  "https://m.ascendex.com/en/cashtrade-spottrading/usdt/dafi";

export const COIN_PANCAKESWAP_LINK =
  "https://pancakeswap.finance/info/pool/0x3d07760aab91acc2cc7a2b36233fe9bca7ceaec4";

export const COIN_GATEIO_LINK = "https://www.gate.io/en/trade/DAFI_USDT";

export const APP_TWITTER_HANDLE = "@DafiProtocol";

export const COUNT_PER_PAGE = 10;

export const VOYAGER_BG =
  "radial-gradient(89.47% 89.29% at 26.43% 10.71%, rgba(207, 128, 255, 0.5) 0%, rgba(83, 14, 137, 0.5) 67.19%, rgba(216, 0, 194, 0.5) 100%);";

export const SUPERNOVA_1_BG =
  "radial-gradient(89.47% 89.29% at 26.43% 10.71%, rgba(0, 245, 255, 0.5) 0%, rgba(5, 87, 90, 0.5) 100%);";

export const SUPERNOVA_2_BG =
  "radial-gradient(89.47% 89.29% at 26.43% 10.71%, rgba(165, 176, 241, 0.5) 0%, rgba(52, 10, 247, 0.5) 100%)";
export const SUPERNOVA_3_BG =
  "radial-gradient(89.47% 89.29% at 26.43% 10.71%, rgba(227, 193, 249, 0.5) 0%, rgba(142, 60, 194, 0.5) 100%)";

export const COSMONAUT_BG =
  "radial-gradient(89.47% 89.29% at 26.43% 10.71%, rgba(0, 223, 254, 0.5) 0%, rgba(36, 5, 160, 0.5) 60.42%, rgba(106, 9, 204, 0.5) 100%)";

export const APOLLO_BG =
  "radial-gradient(78.66% 95.94% at 35.47% 4.06%, rgba(255, 87, 49, 0.5) 0%, rgba(186, 0, 94, 0.5) 69.83%, rgba(94, 19, 119, 0.5) 94.78%)";

export const LUNAR_BG =
  "radial-gradient(89.47% 89.29% at 26.43% 10.71%, rgba(255, 166, 83, 0.5) 0%, rgba(143, 48, 22, 0.5) 41.67%, rgba(62, 7, 6, 0.5) 100%)";

export const PIONEER_BG =
  "radial-gradient(106.03% 105.85% at 26.43% 10.71%, rgba(235, 235, 235, 0.5) 0%, rgba(31, 34, 65, 0.5) 64.06%, rgba(1, 13, 130, 0.5) 100%)";

export const SUPERNOVA_1_BORDER = "#4BEAF5";

export const SUPERNOVA_2_BORDER = "#AEB0FF";
export const SUPERNOVA_3_BORDER = "#E3C1F980";
export const COSMONAUT_BORDER = "#00DFFE80";
export const VOYAGER_BORDER = "#C375F4";
export const APOLLO_BORDER = "#FF573180";
export const LUNAR_BORDER = "#F59E52";
export const PIONEER_BORDER = "#EBEBEB80";

export const SUPPORTED_CHAIN_IDS = [1, 137, 56];
