import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { GraphTimeRangeButtonType } from "./type";

const GraphTimeRangeButton = ({
  text,
  selected,
  onClick,
}: GraphTimeRangeButtonType) => {
  const classes = useStyles({ selected });

  return (
    <Button
      onClick={onClick}
      className={selected ? classes.selectedContainer : classes.container}
    >
      <Typography className={classes.btnText}>{text}</Typography>
    </Button>
  );
};

export default GraphTimeRangeButton;

const useStyles = makeStyles<Theme, { selected: boolean }>((theme) => ({
  container: {
    minWidth: 40,
    height: 30,
    marginRight: 5,
  },

  selectedContainer: {
    background: "rgba(15, 8, 36, 0.55)",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    borderRadius: "10px",
    minWidth: 40,
    height: 30,
    marginRight: 5,
  },
  btnText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: (props) => (props.selected ? "#fff" : "#fafafa"),
    opacity: (props) => (props.selected ? 1 : 0.6),
  },
}));
