import { createTheme } from "@material-ui/core";
import { APP_FONT_NAME } from "../utils/app-constants";

export const theme = createTheme({
  typography: {
    fontFamily: APP_FONT_NAME,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: "#fff",
  },

  palette: {
    cardBgColor:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    snackbarColor:
      "linear-gradient(182.75deg,rgba(96, 83, 132, 0.7) -94.02%,rgba(15, 8, 36, 7) 153.25%)",

    themeColor1: "#fff",
    themeColor1RGBA: "255,255,255",
    themeGradient1:
      "linear-gradient(0deg, #5FD5F5, #5FD5F5), linear-gradient(0deg, #230538, #230538), linear-gradient(107.63deg, #302B63 -2.79%, #302B63 103.77%);",
    themeBoxShadow1:
      "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)",
    border: "2px solid rgba(255, 255, 255, 0.13)",

    textGradient1:
      "-webkit-linear-gradient(rgba(255, 169, 129, 1), rgba(255, 78, 128, 1))",

    textGradient2:
      "-webkit-linear-gradient(rgba(135, 151, 255, 1), rgba(181, 93, 255, 1))",
    textColor: "rgba(255,255,255,.8)",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});
