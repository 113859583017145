import { APIActionType } from "../action-types/APIActionType";
import { Action, superPoolsGraphKeys } from "../actions/APIAction";
import { APIState } from "../Type/APITypes";

const initialState: APIState = {
  rewardGraph: {
    rewardArray: [],
    timestampArray: [],
  },
  totalTokensStaked: 0,
  allPoolsStaked: {
    bsc: "",
    bscV2: "",
    eth: "",
    ethV2: "",
    maticV2: "",
  },
  superPoolsGraph: {
    eth: {
      stakesArray: [],
      timestampArray: [],
    },
    ethV2: {
      stakesArray: [],
      timestampArray: [],
    },
    polygonV2: {
      stakesArray: [],
      timestampArray: [],
    },
    binanceV2: {
      stakesArray: [],
      timestampArray: [],
    },
  },
  coinMarketData: {
    coinMarketGraph: {
      pricesArray: [],
      timestampArray: [],
    },
    coinPrice: 0,
    coin24hVolume: 0,
    coin24hPriceChange: 0,
    sevenDayHighLow: [],
    chainTokensHolders: {
      bnbUsers: "",
      ethUsers: "",
      polygonUsers: "",
    },
  },

  protocolTwitterData: [],
  transactionsHistory: [],
  topUsers: [],
  leaderboardStats: {
    top100: [],
    allUsers: [],
  },

  estimateGasPrice: {
    fastGasPrice: 0,
    safeGasPrice: 0,
    proposeGasPrice: 0,
  },

  allPoolsAPY: {
    bscV2APY: 0,
    ethAPY: 0,
    ethV2APY: 0,
    maticV2APY: 0,
  },

  allPoolsPotentialAPY: {
    bscV2PotentialAPY: 0,
    ethPotentialApy: 0,
    ethV2PotentialAPY: 0,
    maticV2PotentialAPY: 0,
  },

  isCrown: false,
};

const reducer = (state: APIState = initialState, action: Action) => {
  switch (action.type) {
    case APIActionType.SET_ALL_POOLS_STAKED:
      return {
        ...state,
        allPoolsStaked: action.payload,
      };

    case APIActionType.SET_REWARD_GRAPH:
      return {
        ...state,
        rewardGraph: action.payload,
      };

    case APIActionType.SET_SUPER_POOLS_GRAPH: {
      let key = Object.keys(action.payload)[0] as superPoolsGraphKeys;
      return {
        ...state,
        superPoolsGraph: {
          ...state.superPoolsGraph,
          [key]: action.payload[key],
        },
      };
    }

    case APIActionType.SET_COIN_MARKET_GRAPH:
      return {
        ...state,
        coinMarketData: {
          ...state.coinMarketData,
          coinMarketGraph: action.payload,
        },
      };

    case APIActionType.SET_COIN_PRICE:
      return {
        ...state,
        coinMarketData: {
          ...state.coinMarketData,
          coinPrice: action.payload,
        },
      };

    case APIActionType.SET_COIN_PRICE_24HV:
      return {
        ...state,
        coinMarketData: {
          ...state.coinMarketData,
          coin24hVolume: action.payload,
        },
      };

    case APIActionType.SET_COIN_PRICE_24HPC:
      return {
        ...state,
        coinMarketData: {
          ...state.coinMarketData,
          coin24hPriceChange: action.payload,
        },
      };

    case APIActionType.SET_COIN_PRICE_7DHL:
      return {
        ...state,
        coinMarketData: {
          ...state.coinMarketData,
          sevenDayHighLow: action.payload,
        },
      };

    case APIActionType.SET_CHAIN_TOKENS_HOLDERS:
      return {
        ...state,
        coinMarketData: {
          ...state.coinMarketData,
          chainTokensHolders: action.payload,
        },
      };

    case APIActionType.SET_PROTOCOL_TWITTER_DATA:
      return {
        ...state,
        protocolTwitterData: action.payload,
      };

    case APIActionType.SET_TOTAL_TOKENS_STAKED:
      return {
        ...state,
        totalTokensStaked: action.payload,
      };

    case APIActionType.SET_TRANSACTIONS_HISTORY:
      return {
        ...state,
        transactionsHistory: action.payload,
      };

    case APIActionType.SET_TOP_USERS:
      return {
        ...state,
        topUsers: action.payload,
      };

    case APIActionType.SET_LEADERBOARD_STATS:
      return {
        ...state,
        leaderboardStats: action.payload,
      };

    case APIActionType.SET_ESTIMATE_GAS:
      return {
        ...state,
        estimateGasPrice: action.payload,
      };

    case APIActionType.SET_ALL_POOLS_APY:
      return {
        ...state,
        allPoolsAPY: action.payload,
      };

    case APIActionType.SET_ALL_POOLS_POTENTIAL_APY:
      return {
        ...state,
        allPoolsPotentialAPY: action.payload,
      };

    case APIActionType.SET_CROWN:
      return {
        ...state,
        isCrown: action.payload,
      };

    case APIActionType.RESET_API_DATA:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
