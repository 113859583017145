import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableContainer from "@material-ui/core/TableContainer";

import { Button } from "@material-ui/core";

import {
  conciseAddress,
  pad,
  sixDigitsFormatter,
} from "../../utils/formatters";

import { CopyToClipboard } from "react-copy-to-clipboard";
// import { useSnackbar } from "../hooks/uiHooks";
import moment from "moment";
import { dashboardAssets } from "../../assets/exports";
import { TransactionTableType } from "./type";
import { contractFunctions } from "../../contract";

const TransactionTable = ({ transactions, txNo }: TransactionTableType) => {
  const classes = useStyles();

  //   const { showSnackbarF } = useSnackbar();

  return (
    <TableContainer className={classes.container}>
      <table className={classes.tableTemp} style={{ height: "100%" }}>
        <thead className={classes.tableHeaderTemp}>
          <tr className={classes.tableRowHeaderTemp}>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "13%" }}
            >
              No.
            </th>
            <th
              className={[
                classes.tableHeaderHeadingTemp,
                classes.rankColumn,
              ].join(" ")}
              // style={{ width: "30%" }}
            >
              Amount
            </th>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "30%" }}
            >
              Address
            </th>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "30%" }}
            >
              Time
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBodyTemp}>
          {transactions.map((row, index) => (
            <tr
              key={row.id}
              style={{ padding: "10px 0" }}
              className={classes.tableRowBodyTemp}
            >
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "13%" }}
              >
                {pad(txNo + index + 1)}
              </td>

              <td
                className={[
                  classes.tableRowDivBodyTemp,
                  classes.rankColumn,
                ].join(" ")}
                // style={{ width: "30%" }}
              >
                {sixDigitsFormatter(
                  Number(contractFunctions.fromWei(row.amount))
                ) + ` DAFI`}
              </td>
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "30%" }}
              >
                <div style={{ minWidth: 100 }}>
                  {conciseAddress(row.staker)}
                </div>

                <CopyToClipboard
                  text={row.staker}
                  onCopy={() => {
                    // showSnackbarF({
                    //   message: "Address Copied",
                    //   severity: "success",
                    // });
                  }}
                >
                  <Button className={classes.copyBtn}>
                    <img src={dashboardAssets.Copy} />
                  </Button>
                </CopyToClipboard>
              </td>
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "30%" }}
              >
                {moment(Number(row.time) * 1000).fromNow(true)} ago
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default TransactionTable;
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    height: "100%",
    background: "transparent",
  },

  rowText: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "20px",
    // border: "none",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "10px 0",
  },
  tableRowHeaderTemp: {
    display: "block",
    "&::after": {
      content: " ",
      display: "block",
      visibility: "hidden",
      clear: "both",
    },
  },
  addressRow: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "20px",
    // border: "none",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "10px 0",
  },

  headerRowText: {
    color: "#BFB8BF",
    fontSize: "14px",
    lineHeight: "24px",
    border: "none",
    background: "transparent",
    padding: "10px 0",
  },
  rankColumn: {
    width: "27% !important",
    paddingLeft: "10px",
    [theme.breakpoints.down(800)]: {
      paddingLeft: "20px",
    },
  },
  tableRowBodyTemp: {
    display: "block",
    "&:after": {
      content: " ",
      display: "block",
      visibility: "hidden",
      clear: "both",
    },
  },
  tableHeader: {},

  copyBtn: {
    padding: 0,
    minWidth: "auto",
  },
  tableRowDivBodyTemp: {
    display: "inline-flex",
    width: "19.2%",
    float: "left",
    color: "white",
  },
  tableHeaderHeadingTemp: {
    display: "block",
    textAlign: "left",
    color: "#BFB8BF",
    height: "30px",
    width: "19.2%",
    float: "left",
  },
  tableTemp: {
    width: "100%",
    overflow: "hidden",
    minWidth: "635px",
  },
  tableHeaderTemp: { display: "block" },
  tableBodyTemp: {
    display: "block",
    overflowY: "auto",
    height: "88%",
  },
}));
