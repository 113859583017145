import { stakingManagerContract, tokenContract, web3 } from ".";
import {
  AddChainToMetamaskType,
  ChainChangeRequestType,
  CheckApprovalType,
  FormatEtherType,
  FromWeiType,
  GetBalanceType,
  GiveApprovalType,
  RegisterTokenType,
} from "./types";

export const fromWei: FromWeiType = (value) => {
  return web3.utils.fromWei(value.toString(), "ether");
};

export const toWei: FromWeiType = (value) => {
  return web3.utils.toWei(value.toString());
};

export const giveApproval: GiveApprovalType = async (
  amount,
  setApprovalValue,
  account,
  setLoading,
  setModalOpen,
  showSnackbarFunc,
  onApprovalSuccess
) => {
  setLoading(true);
  try {
    await tokenContract.methods
      .approve(
        stakingManagerContract._address,
        web3.utils.toWei(amount.toString())
      )
      .send({ from: account })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);

          if (receipt) {
            showSnackbarFunc({
              message: `Approval given for amount ${amount}`,
              severity: "success",
            });
            setModalOpen(false);
            setLoading(false);
            setApprovalValue(amount);
            onApprovalSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        // if (confirmationNumber === 1) {
        //   showSnackbarFunc({
        //     message: `Approval given for amount ${amount}`,
        //     severity: "success",
        //   });
        //   setModalOpen(false);
        //   setLoading(false);
        //   setApprovalValue(amount);
        // }
      });
  } catch (error) {
    setLoading(false);
    setModalOpen(false);
    showSnackbarFunc({ message: "Approval failed", severity: "error" });
    setApprovalValue("0");
    console.log(error);
  }
};

export const checkApproval: CheckApprovalType = async (account) => {
  return await tokenContract.methods
    .allowance(account, stakingManagerContract._address)
    .call();
};

export const getBalance: GetBalanceType = async (account) => {
  console.log("getBalance => ", {
    account,
    tokenContract,
    stakingManagerContract,
  });
  let balance = await tokenContract.methods.balanceOf(account).call();
  return web3.utils.fromWei(balance, "ether");
};

export const formatEther: FormatEtherType = (number) => {
  return number ? number / 1000000000000000000 : 0;
};

export const registerToken: RegisterTokenType = async () => {
  const tokenAddress = tokenContract._address;
  const tokenSymbol = "DAFI";
  const tokenDecimals = 18;
  // const tokenImage = dafiSingle;

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          // image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};

export const chainChangeRequest: ChainChangeRequestType = async (
  chainId,
  updateNetwork,
  setFirstAppLoading
) => {
  let chainData = {};
  if (chainId === "0x89") {
    chainData = {
      chainId: "0x89",
      chainName: "Polygon",
      rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com/"],
    };
  } else if (chainId === "0x38") {
    chainData = {
      chainId: "0x38",
      chainName: "Binance",
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      blockExplorerUrls: ["https://bscscan.com"],
    };
  }
  try {
    console.log("Web3 => ", { web3, ethereum: window.ethereum });
    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(function () {
        window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId,
              },
            ],
          })
          .then(() => {
            setFirstAppLoading(true);
            setTimeout(() => {
              updateNetwork();
            }, 4000);
          })
          .catch((error: any) => {
            console.log(error.code === 4902);
            if (error.code === 4902) {
              addChainToMetamask(chainData, updateNetwork);
            }
          });
      })
      .catch((error: any) => {
        console.log(error);
      });
  } catch (error) {
    console.log("Caught => ", error);
  }
};

export const addChainToMetamask: AddChainToMetamaskType = async (
  chainData,
  updateNetwork
) => {
  try {
    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(function () {
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [chainData],
          })
          .then(() => {
            updateNetwork();
          });
      });
  } catch (error) {
    console.log(error);
  }
};
