import {
  Snackbar as SnackbarMui,
  Slide,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import { useUIActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const Snackbar = () => {
  const {
    snackbar: { open, message, severity, transactionHash },
  } = useTypedSelector((state) => state.uiReducer);

  const { setSnackbar } = useUIActions();

  const TransitionUp = (props: any) => {
    return <Slide {...props} direction="down" />;
  };

  const classes = useStyles();

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={(e, reason) => {
        console.log("reason => ", reason);
        if (reason === "clickaway") {
          return;
        }
        setSnackbar(
          {
            message: "",
            severity: "info",
          },
          false
        );
      }}
      classes={{ anchorOriginTopCenter: classes.root }}
      TransitionComponent={TransitionUp}
    >
      <Alert
        onClose={() => setSnackbar({ message: "", severity: "info" }, false)}
        severity={severity}
        classes={{ action: classes.iconColor }}
      >
        <Typography className={classes.text}>{message}</Typography>
      </Alert>
    </SnackbarMui>
  );
};

export default Snackbar;

const useStyles = makeStyles((theme) => ({
  root: {},

  snackbarStyles: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
  },

  text: {
    color: theme.palette.textColor,
  },

  iconColor: {
    color: "#fff",
  },
}));
