import { makeStyles, Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { stakePageAssets } from "../../assets/exports";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";

import HolderCard from "../Misc/Market/HoldersCard";
import PieChart from "../Misc/Market/PieChart";
import Text from "../Misc/Text";

const WalletHoldersCard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const {
    coinMarketData: { chainTokensHolders },
  } = useTypedSelector((state) => state.apiReducer);

  useEffect(() => {
    if (Object.keys(chainTokensHolders).length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [chainTokensHolders]);
  return (
    <Box className={classes.container}>
      <Text
        text={`${appConstants.APP_TOKEN_NAME} Users`}
        fontWeight={600}
        fontSize={18}
        lineHeight={20}
      />

      <Box className={classes.chartAndValuesContainer}>
        {loading ? (
          <img
            src={stakePageAssets.DafiLogoAnimated}
            className={classes.logo}
          />
        ) : (
          <>
            <Box height="100%">
              <PieChart
                total={
                  Number(Number(chainTokensHolders?.ethUsers) - 4) +
                  Number(chainTokensHolders?.polygonUsers) +
                  Number(chainTokensHolders?.bnbUsers)
                }
                walletHolders={chainTokensHolders}
              />
            </Box>

            <Box display="flex" justifyContent="center" mt="7px" mb="7px">
              <HolderCard
                value={Number(chainTokensHolders?.ethUsers) - 4}
                type="ERC20"
              />

              <HolderCard
                value={chainTokensHolders?.polygonUsers}
                type="Polygon"
              />

              <HolderCard value={chainTokensHolders?.bnbUsers} type="BEP20" />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WalletHoldersCard;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,
    marginRight: 16,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1100)]: {
      padding: 16,
      marginBottom: 16,
    },

    [theme.breakpoints.down(650)]: {
      padding: 16,

      marginRight: 0,
      marginBottom: 16,
    },
  },
  logo: {
    margin: "0px auto",
    width: 80,
    [theme.breakpoints.down(650)]: {
      width: 45,
    },
  },
  chartAndValuesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flex: 1,
  },
}));
