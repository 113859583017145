import { dashboardAssets } from "../../assets/exports";

export const menuItems = [
  {
    text: "Dashboard",
    path: "/",
    icon: dashboardAssets.DashboardDisabled,
    activeIcon: dashboardAssets.DashboardActive,
  },
  {
    text: "Stake",
    path: "/stake",
    icon: dashboardAssets.StakeDisabled,
    activeIcon: dashboardAssets.StakeActive,
  },
  {
    text: "Superpool",
    path: "/super-pool",
    icon: dashboardAssets.SuperpoolDisabled,
    activeIcon: dashboardAssets.SuperpoolActive,
  },
  {
    text: "Market",
    path: "/market",
    icon: dashboardAssets.MarketDisabled,
    activeIcon: dashboardAssets.MarketActive,
  },
  {
    text: "Ranks",
    path: "/ranks",
    icon: dashboardAssets.RankingDisabled,
    activeIcon: dashboardAssets.RankingActive,
  },
  {
    text: "Bridge",
    path: "/swap",
    icon: dashboardAssets.BridgeDisabled,
    activeIcon: dashboardAssets.BridgeActive,
  },
];
