import { stakingManagerContract, web3 } from ".";
import { APP_TOKEN_NAME } from "../../utils/app-constants";
import {
  StakeAmountType,
  UnstakeAmountType,
  WithdrawAmountType,
} from "./types";

export const stakeAmount: StakeAmountType = async (
  amount,
  account,
  setLoading,
  setModalOpen,
  showSnackbarFunc,
  setUserInput,
  onStakeSuccess
) => {
  setLoading(true);
  try {
    await stakingManagerContract.methods
      .stake(web3.utils.toWei(amount.toString()))
      .send({ from: account })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);

          if (receipt) {
            showSnackbarFunc({
              message: `You've staked ${amount} ${APP_TOKEN_NAME}`,
              severity: "success",
            });
            setModalOpen(false);
            setLoading(false);

            setUserInput("");
            onStakeSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", async function (confirmationNumber, receipt) {});
  } catch (err) {
    showSnackbarFunc({
      message: "Staking failed or rejected",
      severity: "error",
    });
    setModalOpen(false);
    setLoading(false);
    console.log(err);
  }
};

export const unStakeAmount: UnstakeAmountType = async (
  amount,
  account,
  setLoading = () => {},
  setModalOpen = () => {},
  showSnackbarFunc = () => {},
  setUserInput,
  onStakeSuccess
) => {
  setLoading(true);
  try {
    console.log("amount account", { amount, account });
    await stakingManagerContract.methods
      .unstake(web3.utils.toWei(amount.toString()))
      .send({ from: account })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);

          if (receipt) {
            showSnackbarFunc({
              message: `You've unstaked ${amount} DAFI`,
              severity: "success",
            });
            setModalOpen(false);
            setLoading(false);

            setUserInput("");
            onStakeSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", async function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
        }
      });
  } catch (err) {
    showSnackbarFunc({
      message: "Unstaking failed or rejected",
      severity: "error",
    });
    setModalOpen(false);
    setLoading(false);
    console.log(err);
  }
};

export const withdrawAmount: WithdrawAmountType = async (
  account,
  partialClaim,
  amount,
  setLoading = () => {},
  setModalOpen = () => {},
  showSnackbarFunc = () => {},
  setUserInput,
  onStakeSuccess
) => {
  setLoading(true);
  try {
    await stakingManagerContract.methods
      .claimRewards(partialClaim, web3.utils.toWei(amount.toString()))
      .send({ from: account })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);

          if (receipt) {
            showSnackbarFunc({
              message: "You've successfully withdrawn your rewards",
              severity: "success",
            });
            setModalOpen(false);
            setLoading(false);
            setUserInput("");
            onStakeSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", function (confirmationNumber, receipt) {});
  } catch (err) {
    showSnackbarFunc({
      message: "Withdrawal failed or rejected",
      severity: "error",
    });
    setModalOpen(false);
    setLoading(false);
    console.log(err);
  }
};
