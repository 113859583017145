import { makeStyles, Box, Theme } from "@material-ui/core";
import Text from "../Text";
import { HoldersCardType } from "./type";

const HolderCard = ({ type, value }: HoldersCardType) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.holderCard}>
        <Box
          className={classes.circle}
          style={{
            backgroundColor:
              type === "ERC20"
                ? "#9DBBFF"
                : type === "BEP20"
                ? "#F4A026"
                : "#8627E1",
          }}
        ></Box>

        <Text text={value} fontWeight={400} fontSize={16} lineHeight={20} />
      </Box>

      <Text
        text={`${type} holders`}
        fontWeight={400}
        fontSize={12}
        lineHeight={20}
      />
    </Box>
  );
};

export default HolderCard;

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: "16px",
  },
  holderCard: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    justifyContent: "center",
    [theme.breakpoints.between(1279, 1418)]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.between(650, 904)]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down(379)]: {
      justifyContent: "flex-start",
    },
  },

  circle: {
    height: 12,
    width: 12,
    borderRadius: 100,
    marginRight: 5,
    background: "#1CDDC2",
  },
}));
