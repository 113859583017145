import { Box, CardContent, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { dashboardAssets } from "../../assets/exports";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CustomCard from "../Misc/CustomCard";
import PoolBottle from "../Misc/PoolBottle";
import Text from "../Misc/Text";

const PoolDominanceCard = () => {
  const classes = useStyles();

  const { allPoolsStaked } = useTypedSelector((state) => state.apiReducer);

  const [polygonPool, setPolygonPool] = useState(0);
  const [ethV2Pool, setEthV2Pool] = useState(0);
  const [bscV2Pool, setBscV2Pool] = useState(0);

  useEffect(() => {
    if (allPoolsStaked.bscV2) {
      let totalStaked =
        Number(allPoolsStaked.maticV2) +
        Number(allPoolsStaked.bscV2) +
        Number(allPoolsStaked.ethV2);

      if (allPoolsStaked?.maticV2) {
        setPolygonPool((Number(allPoolsStaked.maticV2) * 100) / totalStaked);
      }

      if (allPoolsStaked?.bscV2)
        setBscV2Pool((Number(allPoolsStaked.bscV2) * 100) / totalStaked);

      if (allPoolsStaked?.ethV2)
        setEthV2Pool((Number(allPoolsStaked.ethV2) * 100) / totalStaked);
    }
  }, [allPoolsStaked]);

  return (
    <CustomCard customStyles={classes.card}>
      <CardContent className={classes.cardContent}>
        <Text
          text="Pool Dominance"
          customStyles={classes.text}
          fontSize={18}
          lineHeight={24}
        />

        <Box display="flex" justifyContent="center">
          <PoolBottle
            gradient="linear-gradient(169.06deg, #F6610F 1.93%, #F3A929 97%)"
            height={`${bscV2Pool}`}
            labelColor="#F3BA2F"
            icon={dashboardAssets.Binance}
            label="Binance"
          />

          <PoolBottle
            gradient="linear-gradient(169.06deg, #BBD0FF 1.93%, #77A1FF 97%)"
            height={`${ethV2Pool}`}
            labelColor="#56B8E4"
            icon={dashboardAssets.Ethereum}
            label="Ethereum V2"
          />

          <PoolBottle
            gradient="linear-gradient(169.06deg, #6210E1 1.93%, #8C2BE2 97%)"
            height={`${polygonPool}`}
            marginRight={false}
            labelColor="#a37ce6"
            icon={dashboardAssets.Polygon}
            label="Polygon"
          />
        </Box>
      </CardContent>
    </CustomCard>
  );
};

export default PoolDominanceCard;

const useStyles = makeStyles((theme) => ({
  card: {},

  text: {
    marginBottom: 12,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
}));
