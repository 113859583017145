import { makeStyles, Box, Link } from "@material-ui/core";
import { marketAssets } from "../../assets/exports";
import { appConstants } from "../../utils";
import Text from "../Misc/Text";

const ExchangesCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Text
        text="Exchanges"
        fontWeight={500}
        fontSize={24}
        lineHeight={19}
        marginRight={30}
        customStyles={classes.heading}
      />

      <Box className={classes.exchangeBtnContainer}>
        <Link
          href={appConstants.COIN_PANCAKESWAP_LINK}
          target="_blank"
          className={[classes.linkBtn, classes.pancakeSwapBtn].join(" ")}
        >
          <img src={marketAssets.Pancakeswap} style={{ marginRight: 5 }} />

          <Text
            text="Pancakeswap"
            fontWeight={400}
            fontSize={14}
            lineHeight={10}
            color="#090613"
          />
        </Link>
        <Link
          href={appConstants.COIN_ASCENDEX_LINK}
          target="_blank"
          className={[classes.linkBtn, classes.ascendexBtn].join(" ")}
        >
          <img src={marketAssets.AscendEx} style={{ marginRight: 5 }} />

          <Text
            text="AscendEx"
            fontWeight={400}
            fontSize={14}
            lineHeight={10}
            color="#090613"
          />
        </Link>
        <Link
          href={appConstants.COIN_UNISWAP_LINK}
          target="_blank"
          className={[classes.linkBtn, classes.uniswapBtn].join(" ")}
        >
          <img
            src={marketAssets.Uniswap}
            style={{ marginRight: 5, marginBottom: 4 }}
          />

          <Text
            text="Uniswap"
            fontWeight={400}
            fontSize={14}
            lineHeight={10}
            color="#090613"
          />
        </Link>
        <Link
          href={appConstants.COIN_GATEIO_LINK}
          target="_blank"
          className={[classes.linkBtn, classes.gateioBtn].join(" ")}
        >
          <img
            src={marketAssets.GateIO}
            style={{ marginRight: 5, height: 30 }}
          />

          <Text
            text="Gate.io"
            fontWeight={400}
            fontSize={14}
            lineHeight={10}
            color="#090613"
          />
        </Link>
      </Box>
    </Box>
  );
};

export default ExchangesCard;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 95,
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",

    paddingBottom: 0,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
    padding: "0 24px",

    [theme.breakpoints.down(1100)]: {
      padding: 16,
      alignItems: "center",
    },

    [theme.breakpoints.down(650)]: {
      padding: 16,

      alignItems: "flex-start",
      marginRight: 0,
      flexDirection: "column",
      minHeight: "auto",
    },
  },

  heading: {
    [theme.breakpoints.between(1100, 1500)]: {
      marginRight: "14px !important",
    },
    [theme.breakpoints.down(650)]: {
      marginBottom: 24,
      marginRight: 0,
    },
  },

  exchangeBtn: {
    marginRight: 15,

    borderRadius: 10,
    padding: 0,

    [theme.breakpoints.down(650)]: {
      marginBottom: 16,
      width: "90%",
      marginRight: 0,
    },
  },

  exchangeBtnContainer: {
    display: "flex",
    flex: 1,

    [theme.breakpoints.down(650)]: {
      flexDirection: "column",

      width: "100%",
      alignItems: "center",
    },
  },
  gateioBtn: {
    flex: "0.9 !important",
    [theme.breakpoints.between(1100, 1500)]: {
      // maxWidth: "107px",
      marginRight: "0px !important",
    },
  },
  uniswapBtn: {
    flex: "0.9 !important",
    [theme.breakpoints.between(1100, 1500)]: {
      // maxWidth: "109px",
      marginRight: "10px !important",
    },
  },
  ascendexBtn: {
    flex: "1.1 !important",
    [theme.breakpoints.between(1100, 1500)]: {
      // maxWidth: "128px",
      marginRight: "10px !important",
    },
  },
  pancakeSwapBtn: {
    flex: "1.2 !important",
    [theme.breakpoints.between(1100, 1500)]: {
      // maxWidth: "142px",
      marginRight: "10px !important",
    },
  },
  linkBtn: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    minHeight: 45,
    marginRight: 15,

    borderRadius: 10,
    padding: 0,

    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "#e0e0e0",

    [theme.breakpoints.down(650)]: {
      marginBottom: 16,
      width: "90%",
      marginRight: 0,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },

  disabledButton: {
    backgroundColor: "#7d7d7d !important",
  },
}));
