import { Box, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
// import { useWeb3React } from "@web3-react/core";
import ExchangesCard from "./ExchangesCard";
import TwitterFeedCard from "./TwitterFeedCard";
import WalletHoldersCard from "./WalletHoldersCard";
import { appConstants } from "../../utils";
import MarketGraphCard from "./MarketGraphCard";
import TokenStatsCard from "./TokenStatsCard";

const Market = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();

  //   useEffect(() => {
  //     dispatch(web3Actions.getTotalWalletHoldersCount());
  //     dispatch(
  //       web3Actions.getCoinMarketChart(moment().subtract(4, "hours").unix())
  //     );
  //     dispatch(web3Actions.getCoinCurrentPrice());
  //     dispatch(web3Actions.getCoinOtherData());

  //     dispatch(web3Actions.getTwitterTweets());

  //     if (networkSet)
  //       dispatch(web3Actions.getCoinDemandFactor(web3React?.chainId));
  //   }, [networkSet]);

  useEffect(() => {
    let textContent: HTMLTitleElement = document.querySelector("title")!;
    textContent.textContent = appConstants.APP_MARKET_PAGE_TITLE;
  }, []);

  return (
    <>
      <Grid container className={classes.cardsContainer}>
        <Grid item xs={12} md={7} lg={8}>
          <MarketGraphCard />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <TokenStatsCard />
        </Grid>
      </Grid>

      <Grid container className={classes.exchangeCardTablet}>
        <Grid item xs={12} md={12} lg={12}>
          <ExchangesCard />
        </Grid>
      </Grid>

      <Grid container className={classes.walletHolderFeedContainer}>
        <Grid item xs={12} md={5} lg={4} style={{ marginBottom: 16 }}>
          <WalletHoldersCard />
        </Grid>
        <Grid item xs={12} md={7} lg={8} style={{ marginBottom: 16 }}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box className={classes.exchangeCardNonTablet}>
              <ExchangesCard />
            </Box>
            <TwitterFeedCard />
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.walletHolderFeedContainerTablet}>
        <Grid item xs={5} md={5} lg={4}>
          <WalletHoldersCard />
        </Grid>
        <Grid item xs={7} md={7} lg={8}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box className={classes.exchangeCardNonTablet}>
              <ExchangesCard />
            </Box>
            <TwitterFeedCard />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Market;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    paddingRight: 16,
  },

  cardsContainer: {
    marginBottom: 16,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },

  exchangeCardTablet: {
    display: "none",

    [theme.breakpoints.down(1100)]: {
      display: "flex",
    },
  },
  exchangeCardNonTablet: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  walletHolderFeedContainer: {
    display: "none",
    [theme.breakpoints.down(650)]: {
      display: "flex",
    },
  },

  walletHolderFeedContainerTablet: {
    display: "none",
    marginBottom: 24,
    [theme.breakpoints.up(650)]: {
      display: "flex",
      marginBottom: 0,
    },
  },
}));
