import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import moment from "moment";

import MarketGraph from "./MarketGraph";
import GraphTimeRangeButton from "../Buttons/GraphTimeRangeButton";
import Text from "../Misc/Text";
import { useAPIActions } from "../../hooks/useActions";
import { appConstants } from "../../utils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { stakePageAssets } from "../../assets/exports";

const MarketGraphCard = () => {
  const classes = useStyles();

  const { getCoinMarketGraph } = useAPIActions();

  const {
    coinMarketData: { coinPrice },
  } = useTypedSelector((state) => state.apiReducer);

  const {
    appLoading,
    dataLoadings: { coinMarketGraphLoading },
  } = useTypedSelector((state) => state.uiReducer);

  console.log("coinMarketGraphLoading => ", coinMarketGraphLoading);

  const [currentTimeFrame, setCurrentTimeFrame] = useState("4h");

  const timeFrames = ["4h", "1d", "1w", "1m", "All time"];

  const onTimeFrameClick = async (timeFrame: string) => {
    setCurrentTimeFrame(timeFrame);
    if (timeFrame == "4h") {
      await getCoinMarketGraph(moment().subtract(4, "hours").unix());
    } else if (timeFrame == "1d") {
      await getCoinMarketGraph(moment().subtract(1, "day").unix());
    } else if (timeFrame == "1w") {
      await getCoinMarketGraph(moment().subtract(1, "week").unix());
    } else if (timeFrame == "1m") {
      await getCoinMarketGraph(moment().subtract(1, "month").unix());
    } else if (timeFrame == "All time") {
      await getCoinMarketGraph(moment().subtract(10, "year").unix());
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Text
          text={`${appConstants.APP_TOKEN_NAME}/USD: ${coinPrice}`}
          fontWeight={500}
          fontSize={24}
          lineHeight={20}
          customStyles={classes.heading}
        />
        <Box className={classes.timeRangeContainer}>
          <Text
            text="Time Range:"
            fontWeight={600}
            fontSize={12}
            lineHeight={12}
            color="#fafafa"
            marginRight={8}
          />

          <Box>
            {timeFrames.map((timeFrame) => (
              <GraphTimeRangeButton
                selected={timeFrame === currentTimeFrame}
                onClick={() => onTimeFrameClick(timeFrame)}
                text={timeFrame}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {coinMarketGraphLoading ? (
        <img src={stakePageAssets.DafiLogoAnimated} className={classes.logo} />
      ) : (
        <Box>
          <MarketGraph />
        </Box>
      )}
    </Box>
  );
};

export default MarketGraphCard;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "100%",
    minHeight: "600px",
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,
    marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      marginRight: 0,
      marginBottom: 16,
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  heading: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },

  timeRangeContainer: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      justifyContent: "space-between",
    },
  },
  logo: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    margin: "0px auto",
    width: 85,
    [theme.breakpoints.down(500)]: {
      width: 56,
    },
  },
}));
