export enum APIActionType {
  SET_REWARD_GRAPH = "SET_REWARD_GRAPH",
  SET_ALL_POOLS_STAKED = "SET_ALL_POOLS_STAKED",
  SET_SUPER_POOLS_GRAPH = "SET_SUPER_POOLS_GRAPH",
  SET_COIN_MARKET_GRAPH = "SET_COIN_MARKET_GRAPH",
  SET_COIN_PRICE = "SET_COIN_PRICE",
  SET_COIN_PRICE_24HV = "SET_COIN_PRICE_24HV",
  SET_COIN_PRICE_24HPC = "SET_COIN_PRICE_24HPC",
  SET_COIN_PRICE_7DHL = "SET_COIN_PRICE_7DHL",
  SET_CHAIN_TOKENS_HOLDERS = "SET_CHAIN_TOKENS_HOLDERS",
  SET_PROTOCOL_TWITTER_DATA = "SET_PROTOCOL_TWITTER_DATA",
  SET_TOTAL_TOKENS_STAKED = "SET_TOTAL_TOKENS_STAKED",
  SET_TRANSACTIONS_HISTORY = "SET_TRANSACTIONS_HISTORY",
  SET_TOP_USERS = "SET_TOP_USERS",
  SET_LEADERBOARD_STATS = "SET_LEADERBOARD_STATS",
  SET_ESTIMATE_GAS = "SET_ESTIMATE_GAS",

  SET_ALL_POOLS_APY = "SET_ALL_POOLS_APY",
  SET_ALL_POOLS_POTENTIAL_APY = "SET_ALL_POOLS_POTENTIAL_APY",
  SET_CROWN = "SET_CROWN",
  RESET_API_DATA = "RESET_API_DATA",
}
