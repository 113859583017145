export enum ContractActionType {
  SET_TOKEN_BALANCE = "SET_TOKEN_BALANCE",
  SET_MINIMUM_STAKE_AMOUNT = "SET_MINIMUM_STAKE_AMOUNT",
  SET_STAKING_DETAILS = "SET_STAKING_DETAILS",
  SET_DASHBOARD_CARDS_DATA = "SET_DASHBOARD_CARDS_DATA",
  SET_DEMAND_FACTOR = "SET_DEMAND_FACTOR",
  SET_REWARD_POTENTIAL_REWARD = "SET_REWARD_POTENTIAL_REWARD",
  SET_REWARDS_BY_AMOUNT = "SET_REWARDS_BY_AMOUNT",
  RESET_CONTRACT_DATA = "RESET_CONTRACT_DATA",
}
