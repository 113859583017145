import ReactDOM from "react-dom";
import App from "./App";

let underMaintenance = false;

if (!underMaintenance) {
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  if (prompt("Enter password to continue:") === "dafi_dev") {
    ReactDOM.render(<App />, document.getElementById("root"));
  }
}
