import { UIActionType } from "../action-types/UIActionType";
import { UIAction } from "../actions/UIAction";
import { UIState } from "../Type/UITypes";

const initialState: UIState = {
  currentNetwork: 0,
  appLoading: false,
  isTrustWallet: false,
  firstAppLoading: true,
  networkSet: false,

  snackbar: {
    open: false,
    severity: "success",
    message: "",
    transactionHash: "",
  },

  dataLoadings: {
    coinMarketGraphLoading: false,
  },
};

const reducer = (state: UIState = initialState, action: UIAction) => {
  switch (action.type) {
    case UIActionType.SET_CURRENT_NETWORK:
      return {
        ...state,
        currentNetwork: action.payload,
      };

    case UIActionType.SET_APP_LOADING:
      return {
        ...state,
        appLoading: action.payload,
      };

    case UIActionType.SET_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload,
      };

    case UIActionType.SET_FIRST_APP_LOADING:
      return {
        ...state,
        firstAppLoading: action.payload,
      };

    case UIActionType.SET_NETWORK:
      return {
        ...state,
        networkSet: action.payload,
      };

    case UIActionType.SET_DATA_LOADINGS:
      return {
        ...state,
        dataLoadings: {
          ...state.dataLoadings,
          ...action.payload,
        },
      };

    case UIActionType.RESET_UI_DATA:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
