import { CardContent, List, makeStyles } from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { leaderboardAssets } from "../../assets/exports";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CustomCard from "../Misc/CustomCard";
import Text from "../Misc/Text";
import TopUserRow from "../Misc/TopUserRow";
import { useAccount, useNetwork } from "wagmi";

let ranksLabels: any = {
  Voyager: leaderboardAssets.Voyager,
  Cosmonaut: leaderboardAssets.Cosmonaut,
  Supernova: leaderboardAssets.Master1,
  Apollo: leaderboardAssets.Apollo,
  Pioneer: leaderboardAssets.Pioneer,
  Lunar: leaderboardAssets.Lunar,
};

const gradients = [
  "linear-gradient(180deg, #F66511 0%, #F3B22C 100%)",
  "linear-gradient(180deg, #F61F11 0%, #B32CF3 100%)",
  "linear-gradient(180deg, #4DF611 0%, #2CF3E7 100%)",
  "linear-gradient(180deg, #11CDF6 0%, #2C34F3 100%)",
  "linear-gradient(180deg, #1151F6 0%, #BF2CF3 100%)",
];

const TopUsersCard = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const { topUsers, leaderboardStats } = useTypedSelector(
    (state) => state.apiReducer
  );

  const [topFiveUsersData, setTopFiveUsersData] = useState<any>({});

  const [topFiveUsers, setTopFiveUsers] = useState<Array<any>>([]);

  useEffect(() => {
    if (address && leaderboardStats?.allUsers?.length > 0) {
      let allUsers = leaderboardStats?.allUsers;
      allUsers.map((item) => {
        topUsers.map((topUser) => {
          if (topUser.staker.toLowerCase() === item.id) {
            setTopFiveUsersData((val: any) => ({
              ...val,
              [item.id]: { ...item },
            }));
          }
        });
      });
    }
  }, [address, chain, leaderboardStats, topUsers]);

  // console.log(topFiveUsersData, topFiveUsers, topUsers);

  useEffect(() => {
    if (Object.keys(topFiveUsersData).length > 0) {
      setTopFiveUsers([]);
      topUsers.map((user, index) => {
        setTopFiveUsers((arr: any) => {
          let icon;

          if (topFiveUsersData[user?.staker]?.rank) {
            icon = ranksLabels[topFiveUsersData[user.staker].rank];
          } else {
            icon = null;
          }
          return [
            ...arr,
            {
              address: user.staker,
              amount: user.amount,
              gradient: gradients[index],

              icon,
            },
          ];
        });
      });
    }
  }, [topFiveUsersData]);

  return (
    <CustomCard>
      <CardContent className={classes.cardContent}>
        <Text
          text="Top 5 Users (7D)"
          customStyles={classes.text}
          fontSize={18}
          lineHeight={24}
        />

        <List style={{ padding: 0 }}>
          {topFiveUsers.map((user, index) => (
            <TopUserRow
              address={user.address}
              amount={user.amount}
              gradient={user.gradient}
              icon={user.icon}
              showBorder={topUsers.length !== index + 1}
              key={index}
            />
          ))}
        </List>
      </CardContent>
    </CustomCard>
  );
};

export default TopUsersCard;

const useStyles = makeStyles({
  text: {
    marginBottom: 30,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
});
