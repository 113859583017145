export default async (route: string, body?: any, method?: any) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(`${route}`, {
    method: method ? method : "get",
    headers,
    body: body && JSON.stringify(body),
  });

  const responseJSON = await response.json();

  return { payload: responseJSON, status: response.status };
};
