import { Box, CardContent, makeStyles } from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { ApexOptions } from "apexcharts";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useAPIActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { calculateSpecificDaysToTimestamp } from "../../utils/formatters";

import GraphFilterButton from "../Buttons/GraphFilterButton";
import CustomCard from "../Misc/CustomCard";
import Text from "../Misc/Text";
import { useAccount, useNetwork } from "wagmi";

const RewardGraphCard = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      width: 30,
      type: "area",
      zoom: {
        enabled: false,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
      foreColor: "#fff",
      fontFamily: "Poppins",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: false,
    },

    // xaxis: {
    //   show: false,
    // },

    grid: {
      show: false,
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
  });

  const { rewardGraph } = useTypedSelector((state) => state.apiReducer);
  const { currentNetwork } = useTypedSelector((state) => state.uiReducer);
  const {
    stakingDetails: { stakingPeriodValue },
    dashboardCardsData: { rewards },
  } = useTypedSelector((state) => state.contract);

  const { getRewardGraph } = useAPIActions();

  const [filters, setFilters] = useState([true, false, false, false]);
  const [filter, setFilter] = useState("day");

  const [sevenDaysGraph, setSevenDaysGraph] = useState(false);

  const [thirtyDaysGraph, setThirtyDaysGraph] = useState(false);

  useEffect(() => {
    setFilter("day");
    setFilters([true, false, false, false]);
  }, [address]);

  useEffect(() => {
    if (Number(stakingPeriodValue) > 0) {
      setSevenDaysGraph(
        calculateSpecificDaysToTimestamp(stakingPeriodValue, 7)
      );
      setThirtyDaysGraph(
        calculateSpecificDaysToTimestamp(stakingPeriodValue, 30)
      );
    }
  }, [stakingPeriodValue]);

  useEffect(() => {
    if (rewardGraph.timestampArray.length > 0) {
      let newRewardArray = [...rewardGraph.rewardArray];
      let newTimestampArray = [...rewardGraph.timestampArray];

      console.log("rewardsX => ", rewards);

      if (rewards && rewards >= 0) {
        newRewardArray.push(Number(rewards.toFixed(5)));
        newTimestampArray.push(Date.now());
      }

      setSeries([
        {
          name: "Reward",
          data: newRewardArray,
        },
      ]);

      setOptions({
        ...options,
        colors: ["#F65C0D"],
        xaxis: {
          type: "datetime",
          categories: newTimestampArray,
          // show: rewardGraph.timestampArray.length > 0,
          labels: {
            datetimeUTC: false,
          },
        },

        yaxis: {
          show: rewardGraph.rewardArray.length > 0,
          decimalsInFloat: 5,
        },
        grid: {
          show: rewardGraph.rewardArray.length > 0,
        },
      });
    } else {
      setSeries([
        {
          name: "Reward",
          data: [],
        },
      ]);

      setOptions({
        ...options,
        colors: ["#F65C0D"],
        xaxis: {
          type: "datetime",
          categories: [],
          // show: false,
        },

        yaxis: {
          show: false,
          decimalsInFloat: 0,
        },
        grid: {
          show: false,
        },
      });
    }
  }, [rewardGraph, rewards]);

  useEffect(() => {
    (async () => {
      if (filter && currentNetwork) {
        // setLoading(true);

        getRewardGraph(address!, currentNetwork, filter);
        // setLoading(false);
      }
    })();
  }, [filter, currentNetwork]);

  return (
    <CustomCard customStyles={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
          alignItems="center"
          className={classes.header}
        >
          <Text
            text="Reward Balance"
            customStyles={classes.text}
            fontSize={18}
            lineHeight={24}
          />
          <Box>
            <GraphFilterButton
              text="1 Day"
              active={filters[0]}
              onClick={() => {
                setFilter("day");

                setFilters([true, false, false, false]);
              }}
            />
            <GraphFilterButton
              text="7 Days"
              active={filters[1]}
              onClick={() => {
                if (sevenDaysGraph) {
                  setFilter("week");
                  setFilters([false, true, false, false]);
                }
              }}
            />
            <GraphFilterButton
              text="30 Days"
              active={filters[2]}
              onClick={() => {
                if (thirtyDaysGraph) {
                  setFilter("month");
                  setFilters([false, false, true, false]);
                }
              }}
            />
            <GraphFilterButton
              text="All"
              active={filters[3]}
              onClick={() => {
                // if (thirtyDaysGraph) {
                setFilter("allTime");
                setFilters([false, false, false, true]);
                // }
              }}
            />
          </Box>
        </Box>
        {/* {loading ? (
      <div style={{ height: "100%" }}>
        <img src={dafiLogoAnimated} className={classes.logo} />
      </div>
    ) : (
      // <>
      <Chart options={options} series={series} type="area" height={350} />
      // </>
    )} */}
        <Chart options={options} series={series} type="area" height={350} />
      </CardContent>
    </CustomCard>
  );
};

export default RewardGraphCard;

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    marginRight: 16,
    position: "relative",

    overflow: "unset",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      height: 500,
      marginRight: 0,
    },
  },
  loaderBox: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  logo: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    margin: "0px auto",
    width: 85,
    [theme.breakpoints.down(500)]: {
      width: 56,
    },
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    overflow: "unset !important",
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 12,
      alignItems: "flex-start",
    },
  },
}));
