import { FC } from "react";
import { useRoutes } from "react-router-dom";
import Bridge from "../components/Bridge";
import Dashboard from "../components/Dashboard/Dashboard";
import Market from "../components/Market";
import NotFound from "../components/Misc/NotFound";
import Ranks from "../components/Ranks";
import StakeTabs from "../components/Stake";
import SuperPool from "../components/SuperPool";

const Routes: FC = () => {
  return useRoutes([
    {
      path: "/",

      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "/stake",
          element: <StakeTabs />,
        },
        {
          path: "/super-pool",
          element: <SuperPool />,
        },
        {
          path: "/market",
          element: <Market />,
        },
        {
          path: "/ranks",
          element: <Ranks />,
        },
        {
          path: "/swap",
          element: <Bridge />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
};

export default Routes;
