import {
  makeStyles,
  ListItem,
  List,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Theme,
} from "@material-ui/core";
import { dashboardAssets } from "../../assets/exports";
import { conciseAddress } from "../../utils/formatters";
import { TopUserRowStyleType } from "../Dashboard/type";
import { TopUserRowType } from "./type";

const TopUserRow = ({
  address,
  gradient,
  showBorder,
  icon,
}: TopUserRowType) => {
  const classes = useStyles({ gradient, showBorder });
  return (
    <ListItem className={classes.listItem}>
      <Box display="flex" style={{ minWidth: 70 }}>
        <img src={icon} className={classes.rowCircle} />
      </Box>

      <ListItemText
        primary={
          <Typography className={classes.rowText}>
            {conciseAddress(address)}
          </Typography>
        }
      />

      <ListItemSecondaryAction>
        <img src={dashboardAssets.TopUserCup} style={{ width: 30 }} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default TopUserRow;

const useStyles = makeStyles<Theme, TopUserRowStyleType>({
  rowText: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "20px",
    border: "none",
  },

  rowCircle: {
    height: 40,
  },

  listItem: {
    padding: "12px 0px",
    borderBottom: (props) =>
      props.showBorder ? "1px solid rgba(255, 255, 255, 0.06)" : "none",
  },
});
