import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";
import RanksTable from "../Misc/Ranks/RanksTable";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useUIActions } from "../../hooks/useActions";
import { appConstants } from "../../utils";

const RanksTableCard = ({ data }: any) => {
  const classes = useStyles();

  const { leaderboardStats } = useTypedSelector((state) => state.apiReducer);

  const [filteredArray, setFilteredArray] = useState<any>([]);

  const [trimmedHistory, setTrimmedHistory] = useState<any>([]);
  const [currentPageCount, setCurrentPageCount] = useState(0);

  useEffect(() => {
    setFilteredArray(leaderboardStats.top100);
    setCurrentPageCount(0);
  }, [leaderboardStats]);

  useEffect(() => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          currentPageCount * appConstants.COUNT_PER_PAGE,
          appConstants.COUNT_PER_PAGE * (currentPageCount + 1)
        )
      );
    }
  }, [filteredArray]);

  const getPrevTrimmedArrayElements = () => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          (currentPageCount - 1) * appConstants.COUNT_PER_PAGE,
          appConstants.COUNT_PER_PAGE * currentPageCount
        )
      );
      setCurrentPageCount((val) => val - 1);

      // 0 - 5
      // 5 - 10
      // 10 -15
    }
  };

  const getNextTrimmedArrayElements = () => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          (currentPageCount + 1) * appConstants.COUNT_PER_PAGE,
          appConstants.COUNT_PER_PAGE * (currentPageCount + 2)
        )
      );
      setCurrentPageCount((val) => val + 1);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <RanksTable
          trimmedHistory={trimmedHistory}
          txNo={appConstants.COUNT_PER_PAGE * currentPageCount}
        />
      </CardContent>
      <Box className={classes.paginationContainer}>
        <Button
          className={classes.paginationBtn}
          onClick={getPrevTrimmedArrayElements}
          disabled={currentPageCount === 0}
        >
          <ArrowBackIosOutlined className={classes.paginationIcon} />
        </Button>
        <Typography className={classes.paginationText}>
          {currentPageCount + 1} of{" "}
          {filteredArray?.length <= appConstants.COUNT_PER_PAGE
            ? "1"
            : Math.ceil(filteredArray?.length / appConstants.COUNT_PER_PAGE)}
        </Typography>
        <Button
          className={classes.paginationBtn}
          onClick={getNextTrimmedArrayElements}
          disabled={
            (currentPageCount + 1) * appConstants.COUNT_PER_PAGE >=
            filteredArray?.length
          }
        >
          <ArrowForwardIosOutlined className={classes.paginationIcon} />
        </Button>
      </Box>
    </Card>
  );
};

export default RanksTableCard;

const useStyles = makeStyles((theme) => ({
  card: {
    justifyContent: "space-between",
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",
    border: "1px solid rgba(230, 231, 233, 0.1)",
    borderRadius: 25,

    display: "flex",

    flexDirection: "column",
    boxShadow: "none",

    height: 430,

    padding: 24,
    marginTop: 12,

    // marginBottom: 16,
    marginRight: 16,
    paddingBottom: 6,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      height: 500,
      marginRight: 0,
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: "40px",
    },
  },

  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: 12,
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 20,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "scroll",
    border: 0,
    padding: 0,
  },

  image: {
    alignItems: "center",
    width: 80,
    marginBottom: 24,
    height: 80,
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 12,
    },
  },

  filterButtonsContainer: {},

  paginationContainer: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down(600)]: {
      padding: "5px 0",
    },

    height: 50,
  },

  paginationText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(255,255,255,.8)",
    margin: "0 5px",
  },
  paginationBtn: {
    minWidth: "auto",
  },

  paginationIcon: {
    color: "#fff",
    fontSize: 16,
  },
}));
