import { networkDemandContract, stakingDatabaseContract, web3 } from ".";
import BigNumber from "bignumber.js";
import {
  BigNumberVariableType,
  GetDemandFactorType,
  GetDistributePerSecondType,
  GetMaxDAFIType,
  GetMinimumStakeAmountType,
  GetMinimumStakePeriodType,
  GetPoolDetailsType,
  GetProgramDurationType,
  GetTotalFeesCollectedType,
  GetUserStakeDetailsType,
  TotalAmountStakedForAccountType,
  TotalOverallAmountStakedType,
} from "./types";
import { fromWei, toWei } from "./utilFunctions";

const EIGHT_DECIMALS = 100_000_000;
const EIGHTEEN_DECIMALS = 1000000000000000000;

export const totalAmountStakedForAccount: TotalAmountStakedForAccountType =
  async (account) => {
    return await stakingDatabaseContract.methods.totalStakedFor(account).call();
  };

export const totalOverallAmountStaked: TotalOverallAmountStakedType =
  async () => {
    let value = await stakingDatabaseContract.methods.getTotalStaked().call();

    return web3.utils.fromWei(value, "ether");
  };

export const getDemandFactor: GetDemandFactorType = async () => {
  let demandFactor = await networkDemandContract.methods
    .calculateNetworkDemand()
    .call();

  return {
    demandFactorFormatted: Number(demandFactor) / 100000000,
    demandFactor,
  };
};

export const getMaxDAFI: GetMaxDAFIType = async () => {
  return await stakingDatabaseContract.methods.getMaxDAFI().call();
};

export const getProgramDuration: GetProgramDurationType = async () => {
  return await stakingDatabaseContract.methods.getProgramDuration().call();
};

export const getTotalFeesCollected: GetTotalFeesCollectedType = async (
  v1 = false
) => {
  let feesCollected;
  if (v1) {
    feesCollected = await stakingDatabaseContract.methods
      .getFeesDeposited()
      .call();
  } else {
    feesCollected = await stakingDatabaseContract.methods
      .getTotalFeesCollected()
      .call();
  }

  return {
    feesCollected,
    feesCollectedFormatted: Number(fromWei(feesCollected)),
  };
};

export const getUserStakeDetails: GetUserStakeDetailsType = async (account) => {
  console.log("stakeDetails start ", { account, stakingDatabaseContract });

  let stakeDetails = await stakingDatabaseContract.methods
    .getUserStake(account)
    .call();
  console.log("stakeDetails", stakeDetails);
  return await stakingDatabaseContract.methods.getUserStake(account).call();
};

export const getPoolDetails: GetPoolDetailsType = async () => {
  let accumulatedPoolWeight = await stakingDatabaseContract.methods
    .getAccumulatedPoolWeight()
    .call();

  let accumulatedFeeWeight = await stakingDatabaseContract.methods
    .getAccumulatedFeeWeight()
    .call();

  let pool = await stakingDatabaseContract.methods.getPool().call();

  return {
    accumulatedPoolWeight,
    accumulatedFeeWeight,
    pool,
  };
};

export const getDistributePerSecond: GetDistributePerSecondType = async () => {
  return await stakingDatabaseContract.methods.getDistributePerSecond().call();
};

export const getMinimumStakeAmount: GetMinimumStakeAmountType = async () => {
  return fromWei(
    await stakingDatabaseContract.methods.getMinimumStakeAmount().call()
  );
};

export const getMinimumStakePeriod: GetMinimumStakePeriodType = async () => {
  return await stakingDatabaseContract.methods.getMinimumStakePeriod().call();
};

export const getRewardAndPotentialRewardV1 = async (account: string) => {
  let { totalUnclaimed, lastStakingAccumulatedWeight, amount } =
    await getUserStakeDetails(account);

  let { currentAccumulatedWeight, lastDemandFactor } =
    await stakingDatabaseContract.methods.getPool().call();

  let { demandFactor } = await getDemandFactor();

  let currentAccumulatedWeightFinal =
    Number(currentAccumulatedWeight) / 100000000 -
    36679286115784263601611 / 100000000;
  let lastStakingAccumulatedWeightFinal =
    lastStakingAccumulatedWeight / 100000000;

  let lastDemandFactorFinal = Number(lastDemandFactor) / 100000000;
  let demandFactorFinal = Number(demandFactor) / 100000000;

  const poolWeight = new BigNumber(currentAccumulatedWeight);

  let fix = new BigNumber("36679286115784263601611");

  let finalFix = poolWeight.minus(fix).toString();

  let rewardPartial =
    Number(fromWei(totalUnclaimed)) +
    (Number(finalFix) / 100000000 - lastStakingAccumulatedWeightFinal) *
      Number(fromWei(amount));

  let rewards = (rewardPartial / lastDemandFactorFinal) * demandFactorFinal;
  let potentialRewards = (rewardPartial / lastDemandFactorFinal) * 1;

  return {
    rewards,
    potentialRewards,
  };
};

export const getRewardAndPotentialReward = async (
  account: string,
  input = 0
) => {
  let fullOrPartial = toWei(`${input}`);

  let time = new BigNumber(Math.floor(Date.now() / 1000));

  console.log("dafiAccurateRewardSimulation => ", {
    time,
    fullOrPartial,
    input,
  });

  let { rewards, potentialRewards } = await claimReward(
    time,
    fullOrPartial,
    account
  );
  console.log("hello");
  console.log(
    "reward disbursed is ",
    rewards,
    "potential reward disbursed is",
    potentialRewards
  );

  return {
    rewards,
    potentialRewards,
  };
};

const claimReward = async (time: any, fullOrPartial: any, account: any) => {
  console.log("Inside claimReward", { time, fullOrPartial, account });

  // console.log(
  //   "lastUpdatedOn BigNumberVariableType",
  //   await stakingDatabaseContract.methods.pool().call()
  // );
  let {
    lastUpdatedOn,
    currentAccumulatedWeight,
    currentPoolWeight,
    accumulatedFeeWeight,
    currentFeeWeight,
  }: BigNumberVariableType = await stakingDatabaseContract.methods
    .getPool()
    .call();

  let firstRebaseTime = new BigNumber(
    await stakingDatabaseContract.methods.getFirstRebaseTime().call()
  );
  // console.log("firstRebaseTime", firstRebaseTime);
  let programDuration = new BigNumber(
    await stakingDatabaseContract.methods.getProgramDuration().call()
  );
  // console.log("programDuration", programDuration);

  let feeDeposited = new BigNumber(
    await stakingDatabaseContract.methods.getFeesDeposited().call()
  );

  // console.log("feeDeposited", feeDeposited);

  let blockTimeStamp = new BigNumber(time);
  let elapsedTime = blockTimeStamp.minus(lastUpdatedOn);

  let maxDafi = new BigNumber(
    await stakingDatabaseContract.methods.getMaxDAFI().call()
  );

  let dDAFIDistributedCurrent = new BigNumber(
    await stakingDatabaseContract.methods.getDistributePerSecond().call()
  ).multipliedBy(elapsedTime);

  let totaldDAFIDistributed = new BigNumber(
    await stakingDatabaseContract.methods.getdDAFIDistributed().call()
  ).plus(dDAFIDistributedCurrent);

  let MDICurrent = maxDafi
    .minus(totaldDAFIDistributed)
    .div(programDuration.minus(blockTimeStamp.minus(firstRebaseTime)));

  let totalStaked = new BigNumber(
    await stakingDatabaseContract.methods.getTotalStaked().call()
  );
  let tempCurrentPoolWeight = MDICurrent.multipliedBy(
    elapsedTime.multipliedBy(EIGHT_DECIMALS)
  ).div(totalStaked);
  let tempCurrentFeeWeight = feeDeposited
    .multipliedBy(EIGHT_DECIMALS)
    .div(totalStaked);

  lastUpdatedOn = blockTimeStamp;
  currentAccumulatedWeight = tempCurrentPoolWeight.plus(
    currentAccumulatedWeight
  );
  currentPoolWeight = tempCurrentPoolWeight;
  accumulatedFeeWeight = tempCurrentFeeWeight.plus(accumulatedFeeWeight);
  currentFeeWeight = tempCurrentFeeWeight;

  // console.log(
  //   lastUpdatedOn.toString(),
  //   currentAccumulatedWeight.toString(),
  //   currentPoolWeight.toString(),
  //   accumulatedFeeWeight.toString(),
  //   currentFeeWeight.toString()
  // );
  // console.log("Ddafi distributed", totaldDAFIDistributed.toString());
  // console.log("Ddafi dps", MDICurrent.toString());
  // console.log("temp fee weight ", currentFeeWeight.toString());
  // console.log("temp pool weight ", currentPoolWeight.toString());

  let e = await rebaseStake(
    account,
    currentAccumulatedWeight,
    accumulatedFeeWeight,
    time,
    fullOrPartial
  );

  return e;
};

const rebaseStake = async (
  user: any,
  currentWeight: any,
  currentFeeWeight: any,
  time: any,
  fullOrPartial: any
) => {
  let {
    amount,
    createdOn,
    lastUpdatedOn,
    totalUnclaimed,
    feeBalance,
    lastStakingAccumulatedWeight,
    lastAccumulatedFeeWeight,
  }: BigNumberVariableType = await stakingDatabaseContract.methods
    .userStakes(user)
    .call();

  lastUpdatedOn = new BigNumber(lastUpdatedOn);
  totalUnclaimed = new BigNumber(totalUnclaimed);
  feeBalance = new BigNumber(feeBalance);
  lastStakingAccumulatedWeight = new BigNumber(lastStakingAccumulatedWeight);
  // console.log(
  //   "Total Unclaimed",
  //   Number(totalUnclaimed.toString()) / EIGHTEEN_DECIMALS
  // );

  let currentAccumulatedWeight = new BigNumber(currentWeight);
  let lastAccumulatedWeight = lastStakingAccumulatedWeight;
  let newReward = currentAccumulatedWeight
    .minus(lastAccumulatedWeight)
    .multipliedBy(amount)
    .div(EIGHT_DECIMALS);
  let currentAccumulatedFeeWeight = new BigNumber(currentFeeWeight);
  let newFee = currentAccumulatedFeeWeight
    .minus(lastAccumulatedFeeWeight)
    .multipliedBy(amount)
    .div(EIGHT_DECIMALS);
  lastStakingAccumulatedWeight = currentAccumulatedWeight;
  // console.log("new reward", newReward.toString());
  // console.log("new fee", newFee.toString());

  totalUnclaimed = totalUnclaimed.plus(newReward);
  lastUpdatedOn = time;
  feeBalance = newFee.plus(feeBalance);
  lastAccumulatedFeeWeight = currentAccumulatedFeeWeight;
  // console.log("LastStakingAcc", lastStakingAccumulatedWeight.toString());
  // console.log(
  //   "totalUnclaimed",
  //   totalUnclaimed.div(EIGHTEEN_DECIMALS).toString()
  // );
  // console.log("lastUpdatedOn", lastUpdatedOn!.toString());
  // console.log("feeBalance", feeBalance.toString());
  // console.log("lastAccumulatedFeeWeight", lastAccumulatedFeeWeight.toString());

  let d = await computer(totalUnclaimed, feeBalance, fullOrPartial);

  return d;
};

const computer = async (
  unclaimed: any,
  feeBalance: any,
  fullOrPartial: any
) => {
  let demandFactor =
    Number(
      await networkDemandContract.methods.calculateNetworkDemand().call()
    ) / EIGHT_DECIMALS;
  let slashFee =
    Number(await stakingDatabaseContract.methods.getRewardFee().call()) / 100;
  // console.log(demandFactor, slashFee);
  //((W-Wi/1e8 * A) + U)
  let rewards = new BigNumber(fullOrPartial == 0 ? unclaimed : fullOrPartial);
  // console.log(rewards.div(EIGHTEEN_DECIMALS).toString());
  //((W-Wi/1e8 * A) + U) * D/Dmax
  let rewardDF = rewards.multipliedBy(demandFactor);
  //((W-Wi/1e8 * A) + U) * D/Dmax + (F-Fi/1e8 * A)
  let rewardPlusFeeBal = rewardDF.plus(feeBalance);
  let potentialReward = rewards.plus(feeBalance);
  let fee = rewardPlusFeeBal.multipliedBy(slashFee);
  let rewardToDisburse = rewardPlusFeeBal.minus(fee);
  let potentialRewardToDisburse = potentialReward.minus(
    potentialReward.multipliedBy(slashFee)
  );
  // console.log("feeBalance", feeBalance.div(EIGHTEEN_DECIMALS).toString());
  // console.log("unclaimed passed", rewards.div(EIGHTEEN_DECIMALS).toString());
  // console.log("rewardDF passed", rewardDF.div(EIGHTEEN_DECIMALS).toString());
  // console.log(
  //   "rewardPlusFeeBal passed",
  //   rewardPlusFeeBal.div(EIGHTEEN_DECIMALS).toString()
  // );
  // console.log(
  //   "rewardToDisburse passed",
  //   rewardToDisburse.div(EIGHTEEN_DECIMALS).toString()
  // );
  // console.log(
  //   "potential reward",
  //   potentialReward.div(EIGHTEEN_DECIMALS).toString()
  // );
  // console.log(
  //   "potentialRewardToDisburse",
  //   potentialRewardToDisburse.div(EIGHTEEN_DECIMALS).toString()
  // );

  return {
    rewards: rewardPlusFeeBal.div(EIGHTEEN_DECIMALS).toString(),
    potentialRewards: potentialReward.div(EIGHTEEN_DECIMALS).toString(),
  };
};
