import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { PoolBottleType } from "./type";

const PoolBottle = ({
  gradient,
  height,
  marginRight = true,
  labelColor,
  label,
  icon,
}: PoolBottleType) => {
  const classes = useStyles();

  return (
    <div style={{ marginRight: marginRight ? "20px" : "0" }}>
      <div className="progress-label" style={{ color: labelColor }}>
        {Number(height) ? `${Number(height).toFixed(2)}%` : "0%"}
      </div>
      <div className="progress progress-bar-vertical">
        <div
          className="progress-bar"
          style={{
            height: `${height}%`,
            background: gradient,
          }}
        ></div>
      </div>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <img src={icon} style={{ marginBottom: 5, height: 30 }} />
        <div className={classes.label}>{label}</div>
      </Box>
    </div>
  );
};

export default PoolBottle;

const useStyles = makeStyles({
  label: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "24px",
    color: "#fff",
  },
});
