import { FC } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { TextType } from "./type";

const Text: FC<TextType> = ({
  text,
  customStyles,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  textAlign,
  marginBottom,
  marginTop,
  marginRight,
}) => {
  const classes = useStyles({
    color,
    fontSize,
    lineHeight,
    fontWeight,
    textAlign,
    marginBottom,
    marginRight,
    marginTop,
  });

  return (
    <Typography className={`${classes.text} ${customStyles}`}>
      {text}
    </Typography>
  );
};

export default Text;

const useStyles = makeStyles<Theme, TextType>((theme) => ({
  text: {
    color: ({ color }) => (color ? color : theme.palette.textColor),
    fontSize: ({ fontSize }) => (fontSize ? fontSize : "18px"),
    lineHeight: ({ lineHeight }) => (lineHeight ? `${lineHeight}px` : "24px"),
    fontWeight: ({ fontWeight }) => (fontWeight ? fontWeight : 500),
    textAlign: ({ textAlign }) => textAlign && textAlign,
    marginBottom: ({ marginBottom }) => marginBottom && marginBottom,
    marginRight: ({ marginRight }) => marginRight && marginRight,
    marginTop: ({ marginTop }) => marginTop && marginTop,
  },
}));
