import { dashboardAssets } from "../../assets/exports";

export const networkOptions = [
  {
    title: "Ethereum V1",

    icon: dashboardAssets.Ethereum,
    chainId: "0x1",
    network: 1,
  },
  {
    title: "Ethereum V2",

    icon: dashboardAssets.Ethereum,
    chainId: "0x1",
    network: 2,
  },
  {
    title: "Polygon",
    icon: dashboardAssets.Polygon,
    chainId: "0x89",
    network: 3,
  },
  {
    title: "Binance",
    icon: dashboardAssets.Binance,
    chainId: "0x38",
    network: 4,
  },
];

export const helpDropdownOptions = [
  {
    title: "Docs",
    description: "Read about super staking",
    icon: dashboardAssets.Docs,
    url: "https://docs.dafiprotocol.io/",
  },
  {
    title: "Telegram",
    description: "Become a dafinaut",
    icon: dashboardAssets.Telegram,
    url: "https://t.me/dafiprotocol",
  },
  {
    title: "Discord",
    description: "Join the vibrant community",
    icon: dashboardAssets.Discord,
    url: "https://discord.com/invite/bwpCueVD8t",
  },
];
