// Ethereum Mainnet V1
export const NETWORK_DEMAND_MAINNET =
  "0x992b1F86FFD0b76AFA7c295eb6011d98a3e1d49c";

export const STAKING_DATABASE_ETH_MAINNET =
  "0x1d6F43E7d7Acd756f5D82b0826828BcF0ACe759A";
export const STAKING_MANAGER_ETH_MAINNET =
  "0x6a7081D6B70c89ccE858Ea5FB5eB10f31ACD6EE2";

export const DAFI_ADDRESS_ETH_MAINNET =
  "0xFc979087305A826c2B2a0056cFAbA50aad3E6439";

export const NETWORK_DEMAND_ETH_MAINNET =
  "0x03314437da44FD1CC01B436188F3dA85fF457553";

// End

// Ethereum V2 Rinkeby
export const DAFI_ADDRESS_RINKEBY_V2 =
  "0x58AABe567B6993e22c004AA635B3dC1ba3314C40";

export const NETWORK_DEMAND_RINKEBY_V2 =
  "0xd7c02fD566f57C34B57C55199b6E17Edd3082cA8";

export const STAKING_DATABASE_RINKEBY_V2 =
  "0x49122e3E5C30577799aaA480D097Cb2940a8faF3";

export const STAKING_MANAGER_RINKEBY_V2 =
  "0xd244FBBDd800A07B36dd44DaE061328bBDA9Bb88";

// End

// Ethereum V2 mainnet
export const DAFI_ADDRESS_ETH_MAINNET_V2 =
  "0xFc979087305A826c2B2a0056cFAbA50aad3E6439";

export const NETWORK_DEMAND_ETH_MAINNET_V2 =
  "0x3B5EA4aa9ff718441f469f0b84AdC804eAB273DF";

export const STAKING_DATABASE_ETH_MAINNET_V2 =
  "0x3320c38193F4c7Ae9bBd9B545de1234AecC9E8E3";

export const STAKING_MANAGER_ETH_MAINNET_V2 =
  "0x10c53DeBEc28cAb72E93bB9AC760A64DAd07229B";
// End

// Polygon Mumbai testnet
export const DAFI_ADDRESS_POLYGON_MUMBAI =
  "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

export const NETWORK_DEMAND_POLYGON_MUMBAI =
  "0xE14378f40379AB435c25f5DF5341F00eAB6065d1";

export const STAKING_DATABASE_POLYGON_MUMBAI =
  "0xeae3C9995fCcD6acad5c471241349C4913b82CBC";

export const STAKING_MANAGER_POLYGON_MUMBAI =
  "0xa37297F3e12BDbDF7d6377DDF916cdCaf3fD960e";
// End

// Polygon Mainnet
export const DAFI_ADDRESS_POLYGON_MAINNET =
  "0x638Df98Ad8069a15569dA5a6b01181804c47e34c";

export const NETWORK_DEMAND_POLYGON_MAINNET =
  "0x9f0dDC9444a7129DA4eF74439332Da2459BfB874";

export const STAKING_DATABASE_POLYGON_MAINNET =
  "0x3320c38193F4c7Ae9bBd9B545de1234AecC9E8E3";

export const STAKING_MANAGER_POLYGON_MAINNET =
  "0x10c53DeBEc28cAb72E93bB9AC760A64DAd07229B";
// End

// Binance Mainnet
export const DAFI_ADDRESS_BINANCE_MAINNET =
  "0x4e0Fe270B856EeBb91Fb4B4364312be59F499A3F";

export const NETWORK_DEMAND_BINANCE_MAINNET =
  "0xfc9064e2d2E22dbFdE036b01f7b982d6977138BA";

export const STAKING_DATABASE_BINANCE_MAINNET =
  "0xD6Bd7690929610E63e1F53f89A6dBddAb79AE9fE";

export const STAKING_MANAGER_BINANCE_MAINNET =
  "0x6A4B3FE069b69C0a2094Ce711B0CDCa9c9C844bF";
// End
