import { makeStyles, Box } from "@material-ui/core";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";
import { sixDigitsFormatter } from "../../utils/formatters";
import TokenStat from "../Misc/Market/TokenStat";

const TokenStatsCard = () => {
  const classes = useStyles();

  const {
    coinMarketData: {
      coinPrice,
      coin24hVolume,
      coin24hPriceChange,
      sevenDayHighLow,
    },
  } = useTypedSelector((state) => state.apiReducer);

  const { demandFactorFormatted } = useTypedSelector((state) => state.contract);

  return (
    <Box className={classes.container}>
      <TokenStat
        label="Price"
        value={coinPrice ? `$${coinPrice}` : "-"}
        trend={coin24hPriceChange?.toFixed(2)}
      />
      <TokenStat
        label="Market Cap"
        value={
          coinPrice
            ? `$${sixDigitsFormatter(
                appConstants.COIN_CIRCULATING_SUPPLY * coinPrice
              )}`
            : "-"
        }
      />
      <TokenStat
        label="Circ supply"
        value={`${sixDigitsFormatter(appConstants.COIN_CIRCULATING_SUPPLY)}`}
      />
      <TokenStat
        label="Trading Volume"
        value={coin24hVolume ? `$${sixDigitsFormatter(coin24hVolume)}` : "-"}
      />
      <TokenStat
        label="7d Low / 7d High"
        value={
          sevenDayHighLow[0] && sevenDayHighLow[1]
            ? `$${sevenDayHighLow[1]?.toFixed(
                6
              )} / $${sevenDayHighLow[0]?.toFixed(6)}`
            : "-"
        }
      />
      {
        // <TokenStat
        //   label="Demand Factor"
        //   value={`${demandFactor ? `${demandFactor} (v1), ` : ""}${
        //     demandFactorV2 ? `${demandFactorV2} (v2)` : ""
        //   }`}
        // />
        Boolean(demandFactorFormatted) && (
          <TokenStat
            label="Demand Factor"
            value={`${
              demandFactorFormatted ? `${demandFactorFormatted} (v2)` : ""
            }`}
          />
        )
      }
    </Box>
  );
};

export default TokenStatsCard;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      marginBottom: 16,
    },
  },
}));
