import { Box, makeStyles, Typography } from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import { appConstants } from "../../utils";
import RanksTableCard from "./RanksTableCard";
import SelfRank from "./SelfRank";
import StakeRanks from "./StakeRanks";
import { useAccount, useNetwork } from "wagmi";

const Leaderboard = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const { leaderboardStats } = useTypedSelector((state) => state.apiReducer);

  const [selfRank, setSelfRank] = useState<any>();

  useEffect(() => {
    let textContent: HTMLTitleElement = document.querySelector("title")!;
    textContent.textContent = appConstants.APP_STAKE_PAGE_TITLE;
  }, []);

  useEffect(() => {
    setSelfRank(null);
    if (address && leaderboardStats?.allUsers?.length > 0) {
      let allUsers = leaderboardStats?.allUsers;
      allUsers.filter((item: any) => {
        if (item.id === address!.toLowerCase()) {
          setSelfRank((val: any) => ({
            account: item.id,
            amount: item.amount,
            rankNo: item.index,
            rank: item.rank,
          }));
        }
      });
    }
  }, [address, leaderboardStats]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        className={`${classes.headerTextContainer} ${
          !address && classes.headerTextContainerCentered
        }`}
      >
        <Typography className={classes.headerText}>Ranks</Typography>
        <Typography className={classes.headerDesc}>
          This is the rank you are currently in. The higher your rank, the
          greater chance for you to win IDO allocations, limited edition NFTs,
          and more exclusive prizes with DAFI Nova.
        </Typography>
      </Box>

      <Box
        className={classes.container}
        style={!selfRank ? { justifyContent: "center" } : {}}
      >
        {/* mobile order 2 */}

        <Box className={classes.rank}>
          {selfRank && <SelfRank selfRank={selfRank} />}
        </Box>
        <Box className={classes.tables}>
          {/* mobile order 1 */}

          <Box>
            <StakeRanks />
          </Box>

          <Box className={classes.stakeTable}>
            <Typography className={classes.text} color="initial">
              Staking list
            </Typography>
            <RanksTableCard />
          </Box>
        </Box>

        {/* mobile order 3 */}
        <Box className={classes.mobileStake}>
          <Typography className={classes.text} color="initial">
            Staking list
          </Typography>
          <RanksTableCard />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",

    [theme.breakpoints.between("xs", 1350)]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.between("sm", 1350)]: {
      paddingRight: "50px",
      paddingLeft: "50px",
    },
  },

  text: {
    fontWeight: 700,
    fontSize: " 18px",
    lineHeight: "20px",
    /* identical to box height, or 133% */

    color: "#FFFFFF",
    padding: "10px",
  },
  stakeTable: {
    marginTop: "20px",
    display: "block",

    [theme.breakpoints.between("xs", 1350)]: {
      display: "none",
      marginTop: "20px",
    },
  },
  mobileStake: {
    display: "none",
    [theme.breakpoints.between("xs", 1350)]: {
      display: "block",
      order: 2,
      marginTop: "60px",
      marginBottom: "60px",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  rank: {
    order: 3,
  },
  tables: {
    order: 4,

    [theme.breakpoints.up(1350)]: {
      width: "80%",
    },
  },
  headerTextContainer: {
    marginBottom: 40,
    width: 700,

    [theme.breakpoints.between("sm", 1350)]: {
      paddingRight: "50px",
      paddingLeft: "50px",
    },

    [theme.breakpoints.down("sm")]: {
      width: 500,
    },

    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  headerTextContainerCentered: {
    width: "80%",
    alignSelf: "center",
  },
  headerText: {
    fontWeight: 900,
    fontSize: 44,
    lineHeight: "50px",
    color: "#fff",
    marginBottom: 10,
  },
  headerDesc: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: "#fff",
  },
}));

export default Leaderboard;
