import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  CssBaseline,
  Theme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import { dashboardAssets } from "../../assets/exports";
import { appConstants } from "../../utils";
import { menuItems } from "./SidebarData";

import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "@material-ui/styles";

const ResponsiveDrawer = ({ window }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme: Theme = useTheme();

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div className={classes.drawerContainer}>
      <div>
        <div
          style={{ padding: "24px 16px", marginBottom: 16 }}
          onClick={() => navigate("/")}
        >
          <img src={dashboardAssets.DafiLogo} />
        </div>

        <List>
          {menuItems.map((item, index) => (
            <Box display="flex" marginBottom="16px" key={index}>
              <div
                className={
                  location.pathname == item.path
                    ? classes.activeBar
                    : classes.inActiveBar
                }
              ></div>
              <ListItem
                button
                key={item.text}
                onClick={() => {
                  setMobileOpen(false);
                  navigate(item.path);
                }}
                className={location.pathname == item.path ? classes.active : ""}
              >
                <ListItemIcon>
                  {location.pathname === item.path ? (
                    <img src={item.activeIcon} style={{ height: 28 }} />
                  ) : (
                    <img src={item.icon} style={{ height: 28 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      className={
                        location.pathname === item.path
                          ? classes.listItemTextActive
                          : classes.listItemText
                      }
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          ))}
        </List>
      </div>

      <div className={classes.sideBarFooter}>
        <div className={classes.sideBarIconsContainer}>
          <Link
            className={classes.sideBarIcon}
            href="https://twitter.com/dafiprotocol"
            target="_blank"
          >
            <img src={dashboardAssets.Twitter} />
          </Link>
          <Link
            className={classes.sideBarIcon}
            href="https://dafiprotocol.medium.com/"
            target="_blank"
          >
            <img src={dashboardAssets.Medium} />
          </Link>
          <Link
            className={classes.sideBarIcon}
            href="https://t.me/dafiprotocol"
            target="_blank"
          >
            <img src={dashboardAssets.Telegram2} />
          </Link>
          <Link
            className={classes.sideBarIcon}
            href="https://www.linkedin.com/company/dafiprotocol"
            target="_blank"
            style={{ marginRight: 0 }}
          >
            <img src={dashboardAssets.LinkedIn} />
          </Link>
        </div>
        <div className={classes.copyRightText}>&copy; 2022 DAFI Protocol</div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarStyles}>
          <div style={{ padding: "24px 16px" }}>
            <img src={dashboardAssets.DafiLogo} />
          </div>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            // variant="temporary"
            // onEscapeKeyDown={handleDrawerToggle}
            // onBackdropClick={handleDrawerToggle}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: appConstants.DRAWER_WIDTH,
      flexShrink: 0,
    },

    background: "transparent",
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${appConstants.DRAWER_WIDTH}px)`,
      marginLeft: appConstants.DRAWER_WIDTH,
      display: "none",
    },

    display: "flex",

    background: "#0F0824",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarStyles: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerPaper: {
    width: appConstants.DRAWER_WIDTH,

    background: "transparent",
    opacity: 0.75,
    border: "1px solid rgba(230, 231, 233, 0.1)",
  },
  title: {
    padding: theme.spacing(2),
    color: "#fff",
  },

  listItemText: {
    color: "rgba(255,255,255,.7)",
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 400,
  },

  listItemTextActive: {
    color: "rgba(255,255,255)",
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 600,
  },

  active: {
    color: "#fff",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  activeBar: {
    width: "7px",
    backgroundColor: "#50EAF4",
    height: 60,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },

  inActiveBar: {
    borderLeft: "7px solid transparent",
    marginBottom: 16,
  },

  sideBarIcon: {
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#544F64",
    borderRadius: 8,
    marginRight: 10,
    cursor: "pointer",
  },

  sideBarIconsContainer: {
    display: "flex",

    alignItems: "center",
    marginBottom: "16px",
  },

  sideBarFooter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },

  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    marginBottom: "50px",
  },

  copyRightText: {
    fontSize: 14,
    lineHeight: "16px",
    color: "#fff",
  },
}));
