import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { stakePageAssets } from "../../assets/exports";
import { contractFunctions } from "../../contract";
import {
  useAPIActions,
  useContractActions,
  useUIActions,
} from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";
import { hasDecimal } from "../../utils/formatters";
import BlockButton from "../Buttons/BlockButton";
import AmountInput from "../Misc/AmountInput";
import WithdrawModal from "../Modal/WithdrawModal";
import WithdrawInfo from "./WithdrawInfo";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import Web3 from "web3";
import { networkContractAddresses } from "../../contract/functions/contractInit";

const Withdraw = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const {
    stakingDetails: { stakingPeriod, minStakingPeriod, stakingPeriodValue },
    dashboardCardsData: { userDafiStaked, rewards },
    rewardsByAmount,
    tokenBalance,
    demandFactorFormatted,
  } = useTypedSelector((state) => state.contract);

  const { currentNetwork, appLoading, networkSet } = useTypedSelector(
    (state) => state.uiReducer
  );

  const { setAppLoading, setSnackbar } = useUIActions();

  const { afterActionAPIDataCalls } = useAPIActions();

  const { getRewardsByAmount, afterActionContractDataCalls } =
    useContractActions();

  const [userInput, setUserInput] = useState("");

  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  const [actualReward, setActualReward] = useState(0);

  const [partialRewardLoading, setPartialRewardLoading] = useState(false);

  const [localRewards, setLocalRewards] = useState<any>("");

  useEffect(() => {
    if (currentNetwork === 1) {
      setLocalRewards(rewards);
    } else {
      setLocalRewards(rewardsByAmount);
    }
  }, [rewards, rewardsByAmount]);

  const onWithdrawCallback = () => {
    let counter = 0;

    setTimeout(() => {
      setSnackbar({
        message: "Updating values",
        open: true,
      });

      afterActionContractDataCalls(address!, currentNetwork);
      afterActionAPIDataCalls(address!, currentNetwork);
    }, 5000);

    let interval = setInterval(() => {
      if (counter < 5) {
        setSnackbar({
          message: "Updating values",
          open: true,
        });

        afterActionContractDataCalls(address!, currentNetwork);
        afterActionAPIDataCalls(address!, currentNetwork);

        console.log("Yeah it is being called every 10 seconds");

        counter = counter + 1;
      } else {
        clearInterval(interval);
      }
    }, 10000);
  };

  const onDebounce = () => {
    console.log("onDebounce => ", userInput);

    getRewardsByAmount(
      address!,
      Number(userInput) / demandFactorFormatted,
      setPartialRewardLoading
    );
  };

  function debounce(callback: any, delay: number) {
    let timeout: any;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(callback, delay);
    };
  }

  const getRewardsByInput = debounce(onDebounce, 2000);

  useEffect(() => {
    if (currentNetwork !== 1 && networkSet) {
      if (
        Number(userInput) &&
        Number(userInput) <= Number(rewards! * appConstants.AMOUNT_FEE_RATIO)
      ) {
        setPartialRewardLoading(true);
        // debounce(onDebounce, 2000)();
        getRewardsByInput();
      } else {
        setPartialRewardLoading(true);
        setTimeout(() => {
          getRewardsByAmount(address!, 0, setPartialRewardLoading);
        }, 2000);
      }
    }
  }, [userInput]);

  let cutReward = Number(rewards!) * appConstants.AMOUNT_FEE_RATIO;
  const { config } = usePrepareContractWrite({
    //@ts-ignore
    address: networkContractAddresses[currentNetwork][0],
    abi: [
      {
        inputs: [
          {
            internalType: "bool",
            name: "partialClaim",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "claimRewards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],

    functionName: "claimRewards",
    args: [
      Number(userInput) && Number(userInput) < Number(cutReward) ? true : false,
      //@ts-ignore
      Web3.utils.toWei(
        Number(userInput) && Number(userInput) < Number(cutReward)
          ? (Number(userInput) / demandFactorFormatted).toString()
          : "0"
      ),
    ],
  });
  const { data, write } = useContractWrite({
    ...config,
    // args: [Web3.utils.toWei(value.toString())]
  });
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const onWithdraw = async () => {
    let partialClaimBool = false;
    let cutReward = Number(rewards!) * appConstants.AMOUNT_FEE_RATIO;

    if (Number(userInput) && Number(userInput) < Number(cutReward)) {
      partialClaimBool = true;
    }

    console.log("OnWithdraw values : ", {
      partialClaimBool,

      actualReward,
      rewards: rewards!.toFixed(4),
      cutReward,
      userInput,
      contractInput: Number(userInput) / demandFactorFormatted,
      demandFactorV2: demandFactorFormatted,
    });

    // onWithdrawCallback();

    // await contractFunctions.withdrawAmount(
    //   address!,
    //   partialClaimBool,
    //   // partialClaimBool ? Number(userInput) / 22 : 0,
    //   partialClaimBool ? Number(userInput) / demandFactorFormatted : 0,
    //   setAppLoading,
    //   setOpen,
    //   setSnackbar,
    //   setUserInput,
    //   onWithdrawCallback
    // );
    try {
      setAppLoading(true);

      if (write) write();
    } catch (e) {
      setAppLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess && data?.hash) {
      setSnackbar({
        message: "You've successfully withdrawn your rewards",
        severity: "success",
      });
      setOpen(false);
      setAppLoading(false);

      setUserInput("");
      onWithdrawCallback();
    }
  }, [isLoading, isSuccess, data]);

  const handleClickOpen = () => {
    setOpen(true);
    setModalText(
      `This action will withdraw your calculated d${appConstants.APP_TOKEN_NAME} rewards until now`
    );
    setModalLoadText(`Withdrawing rewards`);
  };

  let modalFunc = () => {
    onWithdraw();

    // getChainDataFunc();
  };

  useEffect(() => {
    if (address) {
      setUserInput("");
    }
  }, [address, chain?.id]);

  const onChangeInput = (input: any) => {
    console.log("coming in changeInput => ", {
      input,
      condition: Number(input) <= Number(rewards) && Number(input) !== 0,
    });
    if (hasDecimal(input)) {
      let split = input.split(".");
      if (split[0].length > 18 || split[1].length > 6) {
        return;
      }
    } else if (input.length > 18) {
      return;
    }
    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    if (Number.isNaN(Number(input))) {
      if (Number(input)) {
        setInputErrText(
          "The amount entered is greater than your total value of rewards"
        );
        setShowInputErr(true);
      } else if (Number(input) === 0) {
        setInputErrText("Withdraw amount must be greater than zero");
        setShowInputErr(true);
      }

      return;
    }
    setShowInputErr(false);
    setInputErrText("");
    if (Number(input) <= Number(rewards) && Number(input) !== 0) {
      // input = Math.abs(input);
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
      setActualReward(input);
    } else if (
      Number(input) <= Number(rewards) * appConstants.AMOUNT_FEE_RATIO &&
      Number(input) !== 0
    ) {
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
      setActualReward(input);
    } else {
      if (Number(input)) {
        setUserInput(input);
        setActualReward(input);
        setInputErrText(
          "The amount entered is greater than your total value of rewards"
        );
      } else if (Number(input) === 0) {
        setUserInput(input);
        setActualReward(input);
        setInputErrText("Withdraw amount must be greater than zero");
      }
      setShowInputErr(true);
    }
  };

  const onMaxClick = () => {
    setShowInputErr(false);
    setInputErrText("");

    if (currentNetwork === 1) {
      setUserInput(
        Number(rewards! * appConstants.AMOUNT_FEE_RATIO)?.toFixed(4)
      );
    } else {
      setUserInput(Number(rewards)?.toFixed(4));
    }
    // else
    //   setUserInput(
    //     Number(rewards! * appConstants.AMOUNT_FEE_RATIO)?.toFixed(4)
    //   );
    setActualReward(Number(rewards));
  };

  return (
    <div className={classes.container}>
      <WithdrawModal
        open={open}
        onClose={() => setOpen(false)}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        stakingPeriod={stakingPeriod}
        stakingPeriodValue={stakingPeriodValue}
        minStakingPeriod={minStakingPeriod}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title}>Withdraw</Typography>
              <div className={classes.horizontalLine}></div>

              {(currentNetwork === 2 ||
                currentNetwork === 3 ||
                currentNetwork === 4) && (
                <div className={classes.withdrawInfo}>
                  <img
                    src={stakePageAssets.Info}
                    className={classes.infoIcon}
                  />
                  {appConstants.APP_UNSTAKING_MESSAGE}
                </div>
              )}
              <WithdrawInfo
                rewards={localRewards}
                loading={partialRewardLoading}
              />

              <AmountInput
                userBalance={tokenBalance}
                userInput={userInput}
                inputErrText={inputErrText}
                showInputErr={showInputErr}
                disabled={currentNetwork === 1 || !networkSet}
                maxDisabled={tokenBalance <= 0 || rewards! <= 0 || !networkSet}
                onChangeInput={onChangeInput}
                onMaxClick={onMaxClick}
              />

              <div className={classes.marginBtm}></div>
              <BlockButton
                label={!appLoading ? "Withdraw" : "Transaction Pending"}
                onClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  appLoading ||
                  !userInput ||
                  !address ||
                  partialRewardLoading
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Withdraw;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#ffffff",
    marginBottom: "38px",
  },

  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: "#FFF",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",

    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },
  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },
}));
