import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, TableContainer } from "@material-ui/core/";

import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { RanksTableType } from "./type";
import {
  conciseAddress,
  pad,
  sixDigitsFormatter,
} from "../../../utils/formatters";
import { dashboardAssets, leaderboardAssets } from "../../../assets/exports";
import { useUIActions } from "../../../hooks/useActions";
import CopyToClipboard from "react-copy-to-clipboard";

function createData(no: any, amount: any, address: any, time: any) {
  return { no, amount, address, time };
}

const RanksTable = ({ trimmedHistory, txNo }: RanksTableType) => {
  const classes = useStyles();

  const { leaderboardStats } = useTypedSelector((state) => state.apiReducer);

  const { setSnackbar } = useUIActions();

  return (
    <TableContainer className={classes.containerTemp}>
      <table className={classes.tableTemp} style={{ height: "100%" }}>
        <thead className={classes.tableHeaderTemp}>
          <tr className={classes.tableRowHeaderTemp}>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "10%" }}
            >
              No.
            </th>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "6%" }}
            ></th>
            <th
              className={[
                classes.tableHeaderHeadingTemp,
                classes.rankColumn,
              ].join(" ")}
              // style={{ width: "30%" }}
            >
              Rank
            </th>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "27%" }}
            >
              Address
            </th>
            <th
              className={classes.tableHeaderHeadingTemp}
              style={{ width: "27%" }}
            >
              Staked Tokens
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBodyTemp}>
          {trimmedHistory.map((row: any, index: number) => (
            <tr
              key={row.id}
              style={{ padding: "10px 0" }}
              className={classes.tableRowBodyTemp}
            >
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "10%" }}
              >
                {pad(txNo + index + 1, 3)}
              </td>
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "6%" }}
              >
                <img
                  width="40px"
                  src={
                    row.rank === "Supernova" && row.index === 1
                      ? leaderboardAssets.Master1
                      : row.rank === "Supernova" && row.index === 2
                      ? leaderboardAssets.Master2
                      : row.rank === "Supernova" && row.index === 3
                      ? leaderboardAssets.Master3
                      : row.rank === "Cosmonaut"
                      ? leaderboardAssets.Cosmonaut
                      : row.rank === "Apollo"
                      ? leaderboardAssets.Apollo
                      : row.rank === "Pioneer"
                      ? leaderboardAssets.Pioneer
                      : row.rank === "Lunar"
                      ? leaderboardAssets.Lunar
                      : leaderboardAssets.Voyager
                  }
                  alt=""
                />
              </td>
              <td
                className={[
                  classes.tableRowDivBodyTemp,
                  classes.rankColumn,
                ].join(" ")}
                // style={{ width: "30%" }}
              >
                <Box display="flex" alignItems="center">
                  <Typography>
                    {row.rank === "Supernova"
                      ? "Supernova"
                      : row.rank === "Cosmonaut"
                      ? "Cosmonaut"
                      : row.rank === "Apollo"
                      ? "Apollo"
                      : row.rank === "Pioneer"
                      ? "Pioneer"
                      : row.rank === "Lunar"
                      ? "Lunar"
                      : "Voyager"}
                  </Typography>
                </Box>
              </td>
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "27%" }}
              >
                <Box display="flex" alignItems="center">
                  <div style={{ minWidth: 100 }}>{conciseAddress(row.id)}</div>
                  <CopyToClipboard
                    text={row.id}
                    onCopy={() => {
                      setSnackbar({
                        message: "Address Copied",
                        severity: "success",
                      });
                    }}
                  >
                    <Button className={classes.copyBtn}>
                      <img src={dashboardAssets.Copy} />
                    </Button>
                  </CopyToClipboard>
                </Box>
              </td>
              <td
                className={classes.tableRowDivBodyTemp}
                style={{ width: "27%" }}
              >
                <div style={{ minWidth: 100 }}>
                  {row.amount ? sixDigitsFormatter(row.amount) : 0}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default RanksTable;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "transparent",
  },

  copyBtn: {
    padding: 0,
    minWidth: "auto",
  },
  tableTemp: {
    width: "100%",
    overflow: "hidden",
    minWidth: "635px",
  },
  tableHeaderTemp: { display: "block" },
  tableBodyTemp: {
    display: "block",

    overflowY: "auto",
    height: "88%",
  },
  tableRowBodyTemp: {
    display: "block",
    "&:after": {
      content: " ",
      display: "block",
      visibility: "hidden",
      clear: "both",
    },
  },
  tableHeaderHeadingTemp: {
    display: "block",
    textAlign: "left",
    color: "#BFB8BF",
    height: "30px",
    width: "19.2%",
    float: "left",
  },
  rankColumn: {
    width: "30% !important",
    paddingLeft: "10px",
    [theme.breakpoints.down(800)]: {
      paddingLeft: "20px",
    },
  },
  tableRowHeaderTemp: {
    display: "block",
    "&::after": {
      content: " ",
      display: "block",
      visibility: "hidden",
      clear: "both",
    },
  },
  tableRowDivBodyTemp: {
    display: "block",
    width: "19.2%",
    float: "left",
    color: "white",
  },
  containerTemp: {
    height: "100%",
  },
  // tableTemp: {
  //   height: "100%",
  // },
}));
