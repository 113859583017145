import Slider from "react-slick";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { sixDigitsFormatter } from "../../../utils/formatters";
import { leaderboardAssets } from "../../../assets/exports";

const MultipleItems = ({ roles }: { roles: number[] }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="subtitle1" className={classes.text}>
        {" "}
        Category
      </Typography>
      <Slider {...settings} className={classes.customSlider}>
        <div className={classes.master}>
          <Box className={classes.roleItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Cosmonaut}
              alt=""
            />

            <Typography> Cosmonaut</Typography>
            <Typography color="initial" align="center">
              {sixDigitsFormatter(roles[0])} Staked
            </Typography>
          </Box>
        </div>
        <div className={classes.master}>
          <Box className={classes.roleItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Apollo}
              alt=""
            />
            <Typography>Apollo</Typography>
            <Typography color="initial" align="center">
              {sixDigitsFormatter(roles[1])} Staked
            </Typography>
          </Box>
        </div>
        <div className={classes.master}>
          <Box className={classes.roleItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Lunar}
              alt=""
            />
            <Typography>Lunar</Typography>
            <Typography color="initial" align="center">
              {sixDigitsFormatter(roles[2])} Staked
            </Typography>
          </Box>
        </div>
        <div className={classes.master}>
          <Box className={classes.roleItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Pioneer}
              alt=""
            />
            <Typography>Pioneer</Typography>
            <Typography color="initial" align="center">
              {sixDigitsFormatter(roles[3])} Staked
            </Typography>
          </Box>
        </div>
        <div className={classes.master}>
          <Box className={classes.roleItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Voyager}
              alt=""
            />
            <Typography>Voyager</Typography>
            <Typography color="initial" align="center">
              {sixDigitsFormatter(roles[4])} Staked
            </Typography>
          </Box>
        </div>
      </Slider>
    </div>
  );
};

export default MultipleItems;

const settings = {
  className: "center",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  arrows: false,
  centerPadding: "150px",
};
const useStyles = makeStyles((theme) => ({
  customSlider: {
    border: "1px solid red",
  },
  master: {
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",

    // padding: "0px 10px",
    // paddingRight: 6,
    borderRadius: "15px",
    padding: 0,
  },
  masterImg: { height: 90 },
  roleItems: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "15px",
  },
  text: {
    fontWeight: 500,
    fontSize: " 15.2273px",
    lineHeight: "20px",
    /* identical to box height, or 133% */

    color: "#FFFFFF",
    padding: "30px",
  },
}));
