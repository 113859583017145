import { useEffect, useState } from "react";
import { Box, makeStyles, Typography, Grid, Button } from "@material-ui/core";

import Slider from "react-slick";

import MultipleItems from "../Misc/Ranks/MultipleItems";
import CopyToClipboard from "react-copy-to-clipboard";
import { useUIActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { LeaderboardStatsDataType } from "../../state/Type/APITypes";
import { conciseAddress, sixDigitsFormatter } from "../../utils/formatters";
import { dashboardAssets, leaderboardAssets } from "../../assets/exports";

const StakeRanks = () => {
  const classes = useStyles();

  const { setSnackbar } = useUIActions();

  const { leaderboardStats } = useTypedSelector((state) => state.apiReducer);

  const [masters, setMasters] = useState<any>([]);

  const [roles, setRoles] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    if (leaderboardStats.top100.length > 0) {
      setMasters(leaderboardStats.top100.slice(0, 3));
    }
  }, [leaderboardStats]);

  useEffect(() => {
    if (leaderboardStats.top100.length > 0) {
      let data = leaderboardStats.top100;

      setRoles([
        data[8].amount,
        data[14].amount,
        data[24].amount,
        data[49].amount,
        data[50].amount,
      ]);
    }
  }, [leaderboardStats]);

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      {/* BIG CHARTS */}
      {/* NUMBER 1 */}
      <Grid container style={{ gap: 20, marginBottom: "20px" }} spacing={2}>
        <Grid item xs={12} sm lg xl className={classes.master}>
          <Box className={classes.masterItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Master1}
              alt=""
            />
            <Box className={classes.text} display="flex" flexDirection="column">
              <Typography variant="h6" color="initial">
                #1 Supernova
              </Typography>

              {masters.length > 0 ? (
                <Box display="flex" alignItems="center">
                  <div style={{ fontSize: "12px", marginRight: 10 }}>
                    {conciseAddress(masters[0].id)}
                  </div>
                  <CopyToClipboard
                    text={masters[0].id}
                    onCopy={() => {
                      setSnackbar({
                        message: "Address Copied",
                        severity: "success",
                      });
                    }}
                  >
                    <Button className={classes.copyBtn}>
                      <img src={dashboardAssets.Copy} />
                    </Button>
                  </CopyToClipboard>
                </Box>
              ) : null}

              {masters.length > 0 && (
                <Typography
                  className={classes.amountStaked}
                  variant="subtitle2"
                  color="initial"
                >
                  {sixDigitsFormatter(masters[0]?.amount)} staked
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>

        {/* NUMBER 2 */}
        <Grid item xs sm md lg xl className={classes.master}>
          <Box className={classes.masterItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Master2}
              alt=""
            />
            <Box className={classes.text} display="flex" flexDirection="column">
              <Typography variant="h6" color="initial">
                #2 Supernova
              </Typography>
              {masters.length > 0 ? (
                <Box display="flex" alignItems="center">
                  <div style={{ fontSize: "12px", marginRight: 10 }}>
                    {conciseAddress(masters[1].id)}
                  </div>
                  <CopyToClipboard
                    text={masters[1].id}
                    onCopy={() => {
                      setSnackbar({
                        message: "Address Copied",
                        severity: "success",
                      });
                    }}
                  >
                    <Button className={classes.copyBtn}>
                      <img src={dashboardAssets.Copy} />
                    </Button>
                  </CopyToClipboard>
                </Box>
              ) : null}

              {masters.length > 0 && (
                <Typography
                  className={classes.amountStaked}
                  variant="subtitle2"
                  color="initial"
                >
                  {sixDigitsFormatter(masters[1]?.amount)} staked
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        {/* --------------- */}

        {/* NUMBER 3 */}

        <Grid item xs sm md lg xl className={classes.master}>
          <Box className={classes.masterItems}>
            <img
              className={classes.masterImg}
              src={leaderboardAssets.Master3}
              alt=""
            />
            <Box className={classes.text} display="flex" flexDirection="column">
              <Typography variant="h6" color="initial">
                #3 Supernova
              </Typography>
              {masters.length > 0 ? (
                <Box display="flex" alignItems="center">
                  <div style={{ fontSize: "12px", marginRight: 10 }}>
                    {conciseAddress(masters[2].id)}
                  </div>
                  <CopyToClipboard
                    text={masters[2].id}
                    onCopy={() => {
                      setSnackbar({
                        message: "Address Copied",
                        severity: "success",
                      });
                    }}
                  >
                    <Button className={classes.copyBtn}>
                      <img src={dashboardAssets.Copy} />
                    </Button>
                  </CopyToClipboard>
                </Box>
              ) : null}
              {masters.length > 0 && (
                <Typography
                  className={classes.amountStaked}
                  variant="subtitle2"
                  color="initial"
                >
                  {sixDigitsFormatter(masters[2]?.amount)} staked
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        {/* ----------- */}
      </Grid>
      {/* ---------- */}

      {/* SMALL MOBILE VIEW CHARTS */}
      {/* <Typography className={classes.category}>Category</Typography>
     

      {/* SMALL CHARTS */}

      <Grid container style={{ gap: 17 }}>
        <Grid item xs={12} className={classes.carousel}>
          <MultipleItems roles={roles} />
        </Grid>
        <Grid item xs className={classes.boxes}>
          <Box className={classes.roleItems}>
            <img
              className={classes.secondaryImg}
              src={leaderboardAssets.Cosmonaut}
              alt=""
            />

            <Typography> Cosmonaut</Typography>
            <Typography color="initial" align="center" style={{ fontSize: 12 }}>
              {sixDigitsFormatter(roles[0])} Staked
            </Typography>
          </Box>
        </Grid>

        <Grid item xs className={classes.boxes}>
          <Box className={classes.roleItems}>
            <img
              className={classes.secondaryImg}
              src={leaderboardAssets.Apollo}
              alt=""
            />
            <Typography>Apollo</Typography>
            <Typography color="initial" align="center" style={{ fontSize: 12 }}>
              {sixDigitsFormatter(roles[1])} Staked
            </Typography>
          </Box>
        </Grid>

        <Grid item xs className={classes.boxes}>
          <Box className={classes.roleItems}>
            <img
              className={classes.secondaryImg}
              src={leaderboardAssets.Lunar}
              alt=""
            />
            <Typography>Lunar</Typography>
            <Typography color="initial" align="center" style={{ fontSize: 12 }}>
              {sixDigitsFormatter(roles[2])} Staked
            </Typography>
          </Box>
        </Grid>

        <Grid item xs className={classes.boxes}>
          <Box className={classes.roleItems}>
            <img
              className={classes.secondaryImg}
              src={leaderboardAssets.Pioneer}
              alt=""
            />
            <Typography>Pioneer</Typography>
            <Typography color="initial" align="center" style={{ fontSize: 12 }}>
              {sixDigitsFormatter(roles[3])} Staked
            </Typography>
          </Box>
        </Grid>
        <Grid item xs className={classes.boxes}>
          <Box className={classes.roleItems}>
            <img
              className={classes.secondaryImg}
              src={leaderboardAssets.Voyager}
              alt=""
            />
            <Typography>Voyager</Typography>
            <Typography color="initial" align="center" style={{ fontSize: 12 }}>
              ANY Staked
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StakeRanks;

// SETTINGS
const settings = {
  className: "slider variable-width",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
    // marginBottom: "20px",
  },
  category: {
    fontWeight: 500,
    fontSize: " 15.2273px",
    // lineHeight: "20px",
    /* identical to box height, or 133% */

    color: "#FFFFFF",
    padding: "5px",
  },
  carousel: {
    display: "none",

    width: "100px",
    [theme.breakpoints.only("xs")]: {
      display: "block",
      marginBottom: "30px",
    },
  },
  masterImg: {
    // flexGrow: 1,
    height: 50,
    marginRight: 16,

    [theme.breakpoints.between(1350, 1425)]: {
      marginRight: 5,
    },
  },

  secondaryImg: {
    height: 60,
    marginBottom: 6,
  },
  text: {
    flexGrow: 0,
    display: "flex",
    // alignItems: "center",
    // paddingRight: "20px",
  },
  masterContainer: {},
  master: {
    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",

    // padding: "0px 10px",
    // paddingRight: 6,
    borderRadius: "15px",

    [theme.breakpoints.down("md")]: {
      padding: "14px !important",
    },
  },
  boxes: {
    // width: "100px",

    background:
      "linear-gradient(139.63deg, rgba(96, 83, 132, 0.7) -23.88%, rgba(15, 8, 36, 0.7) 100%)",

    // padding: "0px 10px",
    // paddingRight: 6,
    borderRadius: "15px",
    padding: 0,
    display: "block",

    [theme.breakpoints.only("xs")]: {
      display: "none",
      marginBottom: "10px",
    },
  },

  address: {
    fontSize: "8px",
    fontWeight: 400,
  },

  amountStaked: {
    fontSize: "10px",
    fontWeight: 400,
  },
  masterItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    padding: 0,
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  swiper: {
    display: "none",
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
  },
  gridView: {
    display: "block",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  roleItems: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "15px",
  },
  copyBtn: {
    padding: 0,
    minWidth: "auto",
  },
}));
