import { Dispatch } from "react";
import moment from "moment";

import {
  getCoinMarketGraphFormatted,
  getRewardGraphDataFormatted,
  getSuperPoolGraphDataFormatted,
  superPoolsGraphRoutes,
  getProtocolTwitterDataFormatted,
  getTotalTokensStakedFormatted,
  sortArrayByTimestamp,
  sortTopUsersArray,
} from "../../utils/apiHelpers";

import { API_BASE_URL, COIN_GECKO_API } from "../../utils/app-constants";
import fetch from "../../utils/fetch";
import {
  accumulateUniqueUsers,
  getTimezoneOffset,
} from "../../utils/formatters";
import { APIActionType } from "../action-types/APIActionType";
import {
  Action,
  setSuperPoolGraphData,
  superPoolsGraphKeys,
} from "../actions/APIAction";
import { appConstants } from "../../utils";
import { UIActionCreators } from "..";

export const makeInitialApiDataCalls =
  (account: string, network: number) => async (dispatch: any) => {
    dispatch(getAllPoolsStaked());
    dispatch(getRewardGraph(account, network));
    dispatch(getTransactionsHistory(network));
    dispatch(getTopUsers());
    dispatch(getLeaderboardStats());
    dispatch(getEstimateGasPrice());
    dispatch(getSuperPoolsGraph(network));
    dispatch(getAllPoolsAPY());
    dispatch(getAllPoolsPotentialAPY());
    dispatch(getProtocolTwitterData());
    dispatch(getCoinMarketGraph(moment().subtract(4, "hours").unix()));
    dispatch(getChainTokensHolders());
    dispatch(getCoinPrice());
    dispatch(getCoinPrice7DHL());
    dispatch(getCrownStatus(account));
  };

export const makeOffWalletApiDataCalls = () => async (dispatch: any) => {
  dispatch(getAllPoolsStaked());
  dispatch(getTopUsers());
  dispatch(getLeaderboardStats());
  dispatch(getEstimateGasPrice());
  dispatch(getProtocolTwitterData());
  dispatch(getAllPoolsAPY());
  dispatch(getAllPoolsPotentialAPY());
  dispatch(getSuperPoolsGraph(1));
  dispatch(getCoinMarketGraph(moment().subtract(4, "hours").unix()));
  dispatch(getChainTokensHolders());
  dispatch(getCoinPrice());
  dispatch(getCoinPrice7DHL());
  dispatch(getTransactionsHistoryAllNetworks());
};

export const afterActionAPIDataCalls =
  (account: string, network: number) => async (dispatch: any) => {
    dispatch(getAllPoolsStaked());
    dispatch(getTransactionsHistory(network));
    dispatch(getTopUsers());
    dispatch(getLeaderboardStats());
    dispatch(getAllPoolsAPY());
    dispatch(getAllPoolsPotentialAPY());
  };

export const getAllPoolsStaked = () => async (dispatch: Dispatch<Action>) => {
  try {
    const { payload, status } = await fetch(
      `${API_BASE_URL}/network-demand/getAllNetworksDafiStake`
    );

    dispatch({
      type: APIActionType.SET_ALL_POOLS_STAKED,
      payload: payload,
    });

    dispatch({
      type: APIActionType.SET_TOTAL_TOKENS_STAKED,
      payload: getTotalTokensStakedFormatted({ ...payload, bsc: 0 }),
    });
  } catch (error) {
    console.log("Error in getAllPoolsStaked: ", error);
  }
};

export const getRewardGraph =
  (account: string, network: number, filter = "day") =>
  async (dispatch: Dispatch<Action>) => {
    let url = "";

    let urlsList = [
      "ethereum",
      "ethereum-v2-mainnet",
      "polygon-mainnet",
      "binance-v2-mainnet",
    ];

    url = `${API_BASE_URL}/${
      urlsList[network - 1]
    }/${filter}/${getTimezoneOffset()}/${account}`;

    try {
      const { payload, status } = await fetch(url);

      console.log("getRewardGraph  response => ", { payload, url });
      dispatch({
        type: APIActionType.SET_REWARD_GRAPH,
        payload: getRewardGraphDataFormatted(payload),
      });
    } catch (error) {
      console.log("Error in getRewardGraph: ", error);
    }
  };

export const getSuperPoolsGraph =
  (chainId: number) => async (dispatch: Dispatch<Action>) => {
    if (chainId) {
      try {
        for (let routeKey in superPoolsGraphRoutes) {
          let key = routeKey as superPoolsGraphKeys;

          let url = `${API_BASE_URL}/${
            superPoolsGraphRoutes[key]
          }/superpool/day/${getTimezoneOffset()}`;
          const { payload, status } = await fetch(url);

          dispatch({
            type: APIActionType.SET_SUPER_POOLS_GRAPH,
            payload: { [key]: getSuperPoolGraphDataFormatted(payload) },
          } as setSuperPoolGraphData);
        }
      } catch (error) {
        console.log("Error in getSuperPoolsGraph: ", error);
      }
    }
  };

export const getCoinMarketGraph =
  (timestamp: number) => async (dispatch: Dispatch<Action>) => {
    try {
      const { payload, status } = await fetch(
        `${COIN_GECKO_API}/coins/dafi-protocol/market_chart/range?vs_currency=usd&from=${timestamp}&to=${moment()
          .subtract("hours")
          .unix()}`
      );

      dispatch({
        type: APIActionType.SET_COIN_MARKET_GRAPH,
        payload: getCoinMarketGraphFormatted(payload),
      });

      // UIActionCreators.setDataLoadings({
      //   coinMarketGraphLoading: false,
      // });
    } catch (error) {
      console.log("Error in getCoinMarketGraph: ", error);
    }
  };

export const getCoinPrice = () => async (dispatch: Dispatch<Action>) => {
  try {
    const { payload, status } = await fetch(
      `${COIN_GECKO_API}/simple/price?ids=dafi-protocol&vs_currencies=usd&include_24hr_vol=true&include_24hr_change=true`
    );

    dispatch({
      type: APIActionType.SET_COIN_PRICE,
      payload: payload["dafi-protocol"].usd,
    });
    dispatch({
      type: APIActionType.SET_COIN_PRICE_24HV,
      payload: payload["dafi-protocol"].usd_24h_vol,
    });
    dispatch({
      type: APIActionType.SET_COIN_PRICE_24HPC,
      payload: payload["dafi-protocol"].usd_24h_change,
    });
  } catch (error) {
    console.log("Error in getCoinPrice: ", error);
  }
};

export const getCoinPrice7DHL = () => async (dispatch: Dispatch<Action>) => {
  try {
    const { payload, status } = await fetch(
      `${COIN_GECKO_API}/coins/markets?vs_currency=usd&ids=dafi-protocol&order=market_cap_desc&per_page=100&page=1&sparkline=true&price_change_percentage=7d`
    );

    dispatch({
      type: APIActionType.SET_COIN_PRICE_7DHL,
      payload: [
        Math.max.apply(null, payload[0].sparkline_in_7d.price),
        Math.min.apply(null, payload[0].sparkline_in_7d.price),
      ],
    });
  } catch (error) {
    console.log("Error in getCoinPrice7DHL: ", error);
  }
};

export const getChainTokensHolders =
  () => async (dispatch: Dispatch<Action>) => {
    try {
      const { payload, status } = await fetch(
        `${API_BASE_URL}/market/getWalletHolders`
      );

      dispatch({
        type: APIActionType.SET_CHAIN_TOKENS_HOLDERS,
        payload: payload,
      });
    } catch (error) {
      console.log("Error in getChainTokensHolders: ", error);
    }
  };

export const getProtocolTwitterData =
  () => async (dispatch: Dispatch<Action>) => {
    try {
      const { payload, status } = await fetch(`${API_BASE_URL}/ethereum`);

      dispatch({
        type: APIActionType.SET_PROTOCOL_TWITTER_DATA,
        payload: getProtocolTwitterDataFormatted(payload),
      });
    } catch (error) {
      console.log("Error in getProtocolTwitterData: ", error);
    }
  };

export const getTransactionsHistory =
  (network: number) => async (dispatch: Dispatch<Action>) => {
    try {
      let url = "";

      if (network) {
        if (network === 1) {
          url = `${appConstants.GRAPH_BASE_URL}/dafi-subgraph-mainnet`;
        } else if (network === 2) {
          url = `${appConstants.GRAPH_BASE_URL}/dafi-staking-subgraph-mainnet-v2`;
        } else if (network === 3) {
          url = `${appConstants.GRAPH_BASE_URL}/dafi-staking-polygon-mainnet-v2`;
        } else if (network === 4) {
          url = `${appConstants.GRAPH_BASE_URL_2}/dafisubgraphbsc`;
        }

        let response = await fetch(
          url,
          {
            query: `{
                stakehistories(first:1000,orderBy:time, orderDirection:desc){
                  id
                  staker
                  amount
                  tx_hash
                  type
                  time
                }
              }`,
          },
          "post"
        );
        console.log("getTransactionsHistory response: ", url, response);

        dispatch({
          type: APIActionType.SET_TRANSACTIONS_HISTORY,
          payload: sortArrayByTimestamp(response.payload.data.stakehistories),
        });
      }
    } catch (error) {
      console.log("getTransactionsHistory error => ", error);
    }
  };

export const getTransactionsHistoryAllNetworks =
  () => async (dispatch: Dispatch<Action>) => {
    try {
      let url = "";
      let urls = [
        `${appConstants.GRAPH_BASE_URL}/dafi-subgraph-mainnet`,
        `${appConstants.GRAPH_BASE_URL}/dafi-staking-subgraph-mainnet-v2`,
        `${appConstants.GRAPH_BASE_URL}/dafi-staking-polygon-mainnet-v2`,
        `${appConstants.GRAPH_BASE_URL_2}/dafisubgraphbsc`,
      ];

      let responses = await Promise.all(
        urls.map((url) =>
          fetch(
            url,
            {
              query: `{
              stakehistories(first:1000,orderBy:time, orderDirection:desc){
                id
                staker
                amount
                tx_hash
                type
                time
              }
            }`,
            },
            "post"
          )
        )
      );

      console.log("getTransactionsHistoryAll response: ", responses);

      dispatch({
        type: APIActionType.SET_TRANSACTIONS_HISTORY,
        payload: sortArrayByTimestamp(
          [].concat(...responses.map((r) => r.payload.data.stakehistories))
        ),
      });
    } catch (error) {
      console.log("getTransactionsHistory error => ", error);
    }
  };

export const getTopUsers = () => async (dispatch: Dispatch<Action>) => {
  try {
    let url = "";

    let timeStamp24HoursAgo = moment().subtract(7, "day").unix();

    let urls = [
      {
        url: `${appConstants.GRAPH_BASE_URL}/dafi-staking-polygon-mainnet-v2`,
        network: "polygonV2",
      },
      {
        url: `${appConstants.GRAPH_BASE_URL}/dafi-staking-subgraph-mainnet-v2`,
        network: "ethV2",
      },
      {
        url: `${appConstants.GRAPH_BASE_URL_2}/dafisubgraphbsc`,
        network: "bscV2",
      },
    ];

    let responses: any = await Promise.all(
      urls.map(async (item) => {
        let res = await fetch(
          `${item.url}`,
          {
            query: `
          {stakehistories(first:1000,orderBy:amount,orderDirection:desc, where:{time_gte:${timeStamp24HoursAgo},type:"Stake"}){
            id
            time
            amount
            staker
          }
        }`,
          },
          "post"
        );

        res = res.payload.data.stakehistories.map((tx: any) => ({
          ...tx,
          network: item.network,
        }));

        return res;
      })
    );

    let uniqueUsersArray = accumulateUniqueUsers([
      ...responses[0],
      ...responses[1],
      ...responses[2],
    ]);

    dispatch({
      type: APIActionType.SET_TOP_USERS,
      payload: sortTopUsersArray(uniqueUsersArray).slice(0, 5),
    });
  } catch (error) {
    console.log("getTopUsers error => ", error);
  }
};

export const getLeaderboardStats = () => async (dispatch: Dispatch<Action>) => {
  try {
    let url = `${API_BASE_URL}/network-demand/getLeaderboardData`;

    const { payload, status } = await fetch(url);
    const top100 = payload?.allUsers.slice(0, 100);

    dispatch({
      type: APIActionType.SET_LEADERBOARD_STATS,
      payload: { top100, allUsers: payload.allUsers },
    });
  } catch (error) {
    console.log("getLeaderboardStats error => ", error);
  }
};

export const getEstimateGasPrice = () => async (dispatch: Dispatch<Action>) => {
  try {
    const { payload, status } = await fetch(
      `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=CH4P1UU8CNBDZD25WX9EXQY9XDGBZMG2VD`,
      null
    );

    dispatch({
      type: APIActionType.SET_ESTIMATE_GAS,
      payload: {
        fastGasPrice: payload.result.FastGasPrice,
        safeGasPrice: payload.result.SafeGasPrice,
        proposeGasPrice: payload.result.ProposeGasPrice,
      },
    });
  } catch (error) {
    console.log("getEstimateGasPrice error => ", error);
  }
};

export const getAllPoolsAPY = () => async (dispatch: Dispatch<Action>) => {
  try {
    let url = "";

    url = `${API_BASE_URL}/network-demand/getAPY/0xDbfA076EDBFD4b37a86D1d7Ec552e3926021fB97`;

    const { payload, status } = await fetch(url);

    dispatch({
      type: APIActionType.SET_ALL_POOLS_APY,
      payload: payload,
    });
  } catch (error) {
    console.log("getAllPoolsAPY error => ", error);
  }
};

export const getAllPoolsPotentialAPY =
  () => async (dispatch: Dispatch<Action>) => {
    try {
      let url = "";

      url = `${API_BASE_URL}/network-demand/getPotentialAPY/0xDbfA076EDBFD4b37a86D1d7Ec552e3926021fB97`;

      const { payload, status } = await fetch(url);

      dispatch({
        type: APIActionType.SET_ALL_POOLS_POTENTIAL_APY,
        payload: payload,
      });
    } catch (error) {
      console.log("getAllPoolsPotentialAPY error => ", error);
    }
  };

export const getCrownStatus =
  (account: string) => async (dispatch: Dispatch<Action>) => {
    try {
      let url = "";

      url = `${API_BASE_URL}/stats/${account}`;

      const { payload, status } = await fetch(url);

      dispatch({
        type: APIActionType.SET_CROWN,
        payload: payload.isGivingCrown,
      });
    } catch (error) {
      console.log("getCrownStatus error => ", error);
    }
  };

export const resetAPIData = () => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: APIActionType.RESET_API_DATA,
  });
};
