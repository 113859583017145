import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  Button,
} from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import Carousel from "react-material-ui-carousel";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { dashboardAssets, superpoolAssets } from "../../assets/exports";
import { useUIActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";
import SuperPoolCard from "./SuperPoolCard";
import SuperPoolGraph from "./SuperPoolGraph";
import { CarouselNavButtonType } from "./type";

const SuperPool = () => {
  // const web3React = useWeb3React();

  const classes = useStyles();

  const { allPoolsStaked, allPoolsAPY, allPoolsPotentialAPY } =
    useTypedSelector((state) => state.apiReducer);

  const { currentNetwork } = useTypedSelector((state) => state.uiReducer);
  const { setAppLoading } = useUIActions();

  const [loading, setLoading] = useState(false);

  const [cardsData, setCardsData] = useState([
    {
      title: "Ethereum V2",
      icon: dashboardAssets.Ethereum,
      dafiLocked: "0",
      APY: "0",
      potentialAPY: "0",
      themeColor: "#68E4FF",
      blur: false,
    },

    {
      title: "Polygon",
      icon: dashboardAssets.Polygon,
      dafiLocked: "0",
      APY: "0",
      potentialAPY: "0",
      themeColor: "#a37ce6",
      blur: false,
    },
    {
      title: "Binance",
      icon: dashboardAssets.Binance,
      dafiLocked: "0",
      APY: "0",
      potentialAPY: "0",
      themeColor: "#BBD0FF",
      blur: false,
    },
    {
      title: "Ethereum V1",
      icon: dashboardAssets.Ethereum,
      dafiLocked: "0",
      APY: "0",
      potentialAPY: "0",
      themeColor: "#BBD0FF",
      blur: false,
    },
  ]);

  const [activeCard, setActiveCard] = useState(0);

  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    let textContent: HTMLTitleElement = document.querySelector("title")!;
    textContent.textContent = appConstants.APP_SUPERPOOL_PAGE_TITLE;
  }, []);

  useEffect(() => {
    (async () => {
      setAppLoading(true);

      setCardsData([
        {
          title: "Ethereum V2",
          icon: dashboardAssets.Ethereum,

          dafiLocked: allPoolsStaked.ethV2,
          APY: allPoolsAPY.ethV2APY.toFixed(2),
          potentialAPY: allPoolsPotentialAPY.ethV2PotentialAPY
            ? allPoolsPotentialAPY.ethV2PotentialAPY.toFixed(2)
            : "0",
          themeColor: "#BBD0FF",
          blur: false,
        },

        {
          title: "Polygon",
          icon: dashboardAssets.Polygon,
          dafiLocked: allPoolsStaked.maticV2,
          APY: allPoolsAPY.maticV2APY.toFixed(2),
          potentialAPY: allPoolsPotentialAPY.maticV2PotentialAPY
            ? allPoolsPotentialAPY.maticV2PotentialAPY.toFixed(2)
            : "0",
          themeColor: "#a37ce6",
          blur: false,
        },
        {
          title: "Binance",
          icon: dashboardAssets.Binance,
          dafiLocked: allPoolsStaked.bscV2,
          APY: allPoolsAPY.bscV2APY.toFixed(2),
          potentialAPY: allPoolsPotentialAPY.bscV2PotentialAPY
            ? allPoolsPotentialAPY.bscV2PotentialAPY.toFixed(2)
            : "0",
          themeColor: "#F3BA2F",
          blur: false,
        },
        {
          title: "Ethereum V1",
          icon: dashboardAssets.Ethereum,

          dafiLocked: allPoolsStaked.eth,
          APY: allPoolsAPY.ethAPY.toFixed(2),
          potentialAPY: allPoolsPotentialAPY.ethPotentialApy
            ? allPoolsPotentialAPY.ethPotentialApy.toFixed(2)
            : "0",
          themeColor: "#68E4FF",
          blur: false,
        },
      ]);
      setAppLoading(false);
    })();
  }, [currentNetwork, allPoolsAPY, allPoolsPotentialAPY, allPoolsStaked]);

  return (
    <Box height="100%">
      <Box className={classes.mainContainer}>
        {matches ? (
          <Grid container justifyContent="center">
            <Carousel
              onChange={(index: number) => {
                if (!cardsData[index].blur) {
                  setActiveCard(index);
                }
              }}
              indicators={false}
              navButtonsAlwaysVisible
              autoPlay={false}
              animation="slide"
              NavButton={({ onClick, className, style, next, prev }) => {
                return next ? (
                  <Button
                    onClick={() => onClick()}
                    style={{
                      top: "calc(50% - 30px)",
                    }}
                  >
                    <img src={superpoolAssets.CarouselRightArrow} />
                  </Button>
                ) : (
                  <Button
                    onClick={() => onClick()}
                    style={{
                      top: "calc(50% - 30px)",
                      left: "-18px",
                    }}
                  >
                    <img src={superpoolAssets.CarouselLeftArrow} />
                  </Button>
                );
              }}
            >
              {cardsData.map((card, index) => (
                <Grid item style={{ flex: 1 }} key={index}>
                  <SuperPoolCard
                    card={card}
                    active={activeCard === index}
                    onClick={() => {
                      if (!card.blur) setActiveCard(index);
                    }}
                  />
                </Grid>
              ))}
            </Carousel>
          </Grid>
        ) : (
          <Slider {...settings} className={classes.customSlider}>
            {cardsData.map((card, index) => (
              <Grid item style={{ flex: 1 }} key={index}>
                <SuperPoolCard
                  active={activeCard === index}
                  onClick={() => {
                    if (!card.blur) setActiveCard(index);
                  }}
                  card={card}
                />
              </Grid>
            ))}
          </Slider>
        )}
      </Box>
      <SuperPoolGraph card={cardsData[activeCard]} loading={loading} />
    </Box>
  );
};

export default SuperPool;

const settings = {
  className: "center",
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  centerPadding: "150px",
  initialSlide: 0,
  // centerMode: true,

  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  customSlider: {
    // display: "flex",
    margin: "0 20px",
    marginLeft: "14px",
  },

  mainContainer: {
    width: "100%",
    margin: "0 auto",
    marginBottom: "8px",

    [theme.breakpoints.down(1700)]: {
      width: "1300px",
    },

    [theme.breakpoints.down(1600)]: {
      width: "1250px",
    },

    [theme.breakpoints.down(1530)]: {
      width: "1200px",
    },

    [theme.breakpoints.down(1500)]: {
      width: "1150px",
    },

    [theme.breakpoints.down(1450)]: {
      width: "1100px",
    },

    [theme.breakpoints.down(1400)]: {
      width: "1050px",
    },

    [theme.breakpoints.down(1350)]: {
      width: "1000px",
    },
    [theme.breakpoints.down(1280)]: {
      width: "1200px",
    },
    [theme.breakpoints.down(1230)]: {
      width: "1150px",
    },
    [theme.breakpoints.down(1180)]: {
      width: "1100px",
    },
    [theme.breakpoints.down(1130)]: {
      width: "1050px",
    },

    [theme.breakpoints.down(1100)]: {
      width: "1000px",
    },
    [theme.breakpoints.down(1030)]: {
      width: "950px",
    },

    [theme.breakpoints.down(1000)]: {
      width: "900px",
    },
    [theme.breakpoints.down(950)]: {
      width: "870px",
    },

    [theme.breakpoints.down(900)]: {
      width: "830px",
    },

    [theme.breakpoints.down(870)]: {
      width: "800px",
    },

    [theme.breakpoints.down(830)]: {
      width: "750px",
    },

    [theme.breakpoints.down(780)]: {
      width: "700px",
    },
    [theme.breakpoints.down(730)]: {
      width: "670px",
    },
    [theme.breakpoints.down(700)]: {
      width: "650px",
    },
    [theme.breakpoints.down(680)]: {
      width: "600px",
    },
    [theme.breakpoints.down(630)]: {
      width: "570px",
    },

    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
}));
