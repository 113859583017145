// V2 ABIs
import StakingAbiV2 from "../abis/StakingABIV2.json";
import DatabaseAbiV2 from "../abis/DatabaseABIV2.json";
import NetworkDemandAbiV2 from "../abis/NetworkDemandABIV2.json";
import DafiAbi from "../abis/DafiABI.json";

// V1 ABIs
import StakingAbi from "../abis/StakingABI.json";
import DatabaseAbi from "../abis/DatabaseABI.json";
import NetworkDemandAbi from "../abis/NetworkDemandABI.json";
import * as contractConstants from "../contract-constants";
import Web3 from "web3";

const StakingABIV1 = StakingAbi.abi;
const DatabaseABIV1 = DatabaseAbi.abi;
const NetworkDemandABIV1 = NetworkDemandAbi.abi;

const StakingABIV2 = StakingAbiV2.abi;
const DatabaseABIV2 = DatabaseAbiV2.abi;
const NetworkDemandABIV2 = NetworkDemandAbiV2.abi;
const DafiABI = DafiAbi.abi;

const contractABIs = {
  v2: [StakingABIV2, DatabaseABIV2, NetworkDemandABIV2, DafiABI],

  v1: [StakingABIV1, DatabaseABIV1, NetworkDemandABIV1, DafiABI],
};

export type networkContractAddressesKeys = 1 | 2 | 3 | 4;

export type versionKeys = "v2" | "v1";

export type web3ContractType = Web3;

export const initContract = async (
  network: networkContractAddressesKeys,
  web3: Web3,
  version: versionKeys = "v2"
) => {
  return networkContractAddresses[network].map(
    (address: string, index: number) =>
      new web3.eth.Contract(contractABIs[version][index] as any, address)
  );
};

export const getContractAddress = (network: networkContractAddressesKeys) => {
  return networkContractAddresses[network][0];
};

export const networkContractAddresses = {
  1: [
    contractConstants.STAKING_MANAGER_ETH_MAINNET,
    contractConstants.STAKING_DATABASE_ETH_MAINNET,
    contractConstants.NETWORK_DEMAND_ETH_MAINNET,
    contractConstants.DAFI_ADDRESS_ETH_MAINNET,
  ],
  2: [
    contractConstants.STAKING_MANAGER_ETH_MAINNET_V2,
    contractConstants.STAKING_DATABASE_ETH_MAINNET_V2,
    contractConstants.NETWORK_DEMAND_ETH_MAINNET_V2,
    contractConstants.DAFI_ADDRESS_ETH_MAINNET_V2,
  ],
  3: [
    contractConstants.STAKING_MANAGER_POLYGON_MAINNET,
    contractConstants.STAKING_DATABASE_POLYGON_MAINNET,
    contractConstants.NETWORK_DEMAND_POLYGON_MAINNET,
    contractConstants.DAFI_ADDRESS_POLYGON_MAINNET,
  ],

  4: [
    contractConstants.STAKING_MANAGER_BINANCE_MAINNET,
    contractConstants.STAKING_DATABASE_BINANCE_MAINNET,
    contractConstants.NETWORK_DEMAND_BINANCE_MAINNET,
    contractConstants.DAFI_ADDRESS_BINANCE_MAINNET,
  ],
};
