import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import { bridgeAssets } from "../../assets/exports";
import Text from "../Misc/Text";

const Bridge = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Box className={classes.card}>
        <Typography className={classes.title}>Cross-Chain dBridge</Typography>

        <Typography className={classes.subTitle}>
          Bridge your DAFI tokens from Ethereum to Binance Smart Chain and vice
          versa to unlock high-speed and low-cost transactions
        </Typography>

        <img src={bridgeAssets.BridgeIllustration} className={classes.logo} />

        <Link
          href="https://bridge.dafiprotocol.io/"
          target="_blank"
          className={classes.linkBtn}
        >
          <Text
            text="dBridge now!"
            fontWeight={500}
            fontSize={20}
            lineHeight={10}
            color="#090613"
          />
        </Link>
      </Box>
    </Box>
  );
};

export default Bridge;

const useStyles = makeStyles((theme) => ({
  container: {
    // height: "100%",
    paddingRight: 16,
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  logo: {
    [theme.breakpoints.down(415)]: {
      height: 300,
    },
    maxWidth: "1000px",

    marginTop: -200,
    marginBottom: -70,

    [theme.breakpoints.down(650)]: {
      height: 430,
    },

    [theme.breakpoints.down(400)]: {
      height: 350,
    },
  },

  title: {
    fontWeight: 800,
    fontSize: "70px",
    letterSpacing: "0.03em",

    color: "#FFFFFF",

    margin: "0 auto",
    marginBottom: 16,

    // textTransform: "uppercase",
    textAlign: "center",

    [theme.breakpoints.down(850)]: {
      fontSize: "60px",
    },

    [theme.breakpoints.down(700)]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "45px",
    },

    [theme.breakpoints.down(550)]: {
      fontSize: "40px",
    },

    [theme.breakpoints.down(500)]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "30px",
    },
  },

  subTitle: {
    fontWeight: 500,
    fontSize: "23px",
    letterSpacing: "0.03em",

    color: "#FFFFFF",
    maxWidth: "70%",

    margin: "0 auto",
    marginBottom: 40,
    textAlign: "center",

    [theme.breakpoints.down(800)]: {
      fontSize: "20px",
    },

    [theme.breakpoints.down(700)]: {
      fontSize: "18px",
      maxWidth: "60%",
    },

    [theme.breakpoints.down(550)]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "16px",
      maxWidth: "50%",
    },

    [theme.breakpoints.down(350)]: {
      fontSize: "14px",
    },
  },

  button: {
    height: 60,
    width: 340,
    borderRadius: 10,
    background: "#fff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      textDecoration: "none",
    },
  },

  buttonText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",

    color: "#090613",
  },
  linkBtn: {
    display: "flex",
    // flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    width: 300,

    marginRight: 15,

    borderRadius: 10,
    padding: 0,

    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    backgroundColor: "#e0e0e0",

    [theme.breakpoints.down(650)]: {
      marginBottom: 16,
      // width: "90%",
      marginRight: 0,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
