import { makeStyles, Card, Theme } from "@material-ui/core";
import { CustomCardStyleType, CustomCardType } from "./type";

const CustomCard = ({
  children,
  height,
  width,
  style,
  customStyles,
}: CustomCardType) => {
  const classes = useStyles({ height, width });

  return (
    <Card
      elevation={1}
      className={`${classes.card} ${customStyles}`}
      style={style}
    >
      {children}
    </Card>
  );
};

export default CustomCard;

const useStyles = makeStyles<Theme, CustomCardStyleType>((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: "1px solid rgba(230, 231, 233, 0.1)",
    borderRadius: 25,

    display: "flex",

    flexDirection: "column",
    boxShadow: "none",

    height: ({ height }) => (height ? height : 430),

    padding: 24,
    marginBottom: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
}));
