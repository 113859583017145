import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const MarketGraph = () => {
  const {
    coinMarketData: { coinMarketGraph },
  } = useTypedSelector((state) => state.apiReducer);

  const [series, setSeries] = useState<ApexAxisChartSeries>([]);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      width: 30,
      type: "area",
      zoom: {
        enabled: false,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#79E77D",
            opacity: 0.4,
          },
          stroke: {
            color: "#79E77D",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
      foreColor: "#fff",
      fontFamily: "Poppins",
    },

    colors: ["#79E77D"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [1, 2, 3, 4, 5, 6],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: true,
    },

    // xaxis: {
    //   show: false,
    // },

    grid: {
      show: false,
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
  });

  useEffect(() => {
    if (coinMarketGraph.pricesArray.length > 0) {
      setSeries([
        {
          name: "Price",
          data: coinMarketGraph.pricesArray,
        },
      ]);

      setOptions({
        ...options,

        xaxis: {
          type: "datetime",
          categories: coinMarketGraph.timestampArray,
          //   show: timeStampArray > 0,
          labels: {
            datetimeUTC: false,
          },
        },

        yaxis: {
          show: coinMarketGraph.pricesArray.length > 0,
          decimalsInFloat: 6,
        },

        grid: {
          show: coinMarketGraph.pricesArray.length > 0,
        },
      });
    }
  }, [coinMarketGraph]);
  return <Chart options={options} series={series} type="area" height={450} />;
};

export default MarketGraph;
