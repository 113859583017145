import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { stakePageAssets } from "../../assets/exports";
import { contractFunctions } from "../../contract";
import {
  useAPIActions,
  useContractActions,
  useUIActions,
} from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";
import { hasDecimal } from "../../utils/formatters";
import BlockButton from "../Buttons/BlockButton";
import AmountInput from "../Misc/AmountInput";
import UnstakeModal from "../Modal/UnstakeModal";
import UnstakeInfo from "./UnstakeInfo";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { abi as StakingABIV2 } from "../../contract/abis/StakingABIV2.json";
import Web3 from "web3";
import {
  getContractAddress,
  networkContractAddresses,
  networkContractAddressesKeys,
} from "../../contract/functions/contractInit";

const Unstake = () => {
  const classes = useStyles();

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const {
    stakingDetails: { stakingPeriod, minStakingPeriod, stakingPeriodValue },
    dashboardCardsData: { userDafiStaked, rewards },
    tokenBalance,
  } = useTypedSelector((state) => state.contract);

  const { currentNetwork, networkSet } = useTypedSelector(
    (state) => state.uiReducer
  );

  const { setAppLoading, setSnackbar } = useUIActions();

  const { afterActionContractDataCalls } = useContractActions();

  const { afterActionAPIDataCalls } = useAPIActions();

  const { appLoading } = useTypedSelector((state) => state.uiReducer);

  const [userInput, setUserInput] = useState("");

  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  useEffect(() => {
    setUserInput("");

    if (currentNetwork === 1 && userDafiStaked) {
      setUserInput(userDafiStaked.toString());
    }
  }, [currentNetwork, address, userDafiStaked]);

  let modalFunc = () => {
    onUnStake(userInput, userDafiStaked);
    // getChainDataFunc();
  };

  const getNetworkParam = (): networkContractAddressesKeys => {
    let networkParam: networkContractAddressesKeys = 1;
    if (chain?.id && !networkSet) {
      if (currentNetwork === 1) {
        networkParam = 1;
      } else if (
        currentNetwork === 2 ||
        (currentNetwork === 0 && chain?.id === 1)
      ) {
        // setCurrentNetwork(2); // Ethereum V2
        networkParam = 2;
      } else if (
        currentNetwork === 3 ||
        (currentNetwork === 0 && chain?.id === 137)
      ) {
        // setCurrentNetwork(3); // Polygon
        networkParam = 3;
      } else if (
        currentNetwork === 4 ||
        (currentNetwork === 0 && chain?.id === 56)
      ) {
        // setCurrentNetwork(4); // Binance
        networkParam = 4;
      }
      return networkParam;
    } else {
      return 1;
    }
  };

  const [contractAddress, setContractAddress] = useState(
    getContractAddress(getNetworkParam())
  );

  console.log("contractAddress -->", contractAddress);

  useEffect(() => {
    console.log("chain?.id && networkSet", currentNetwork, chain?.id);
    if (chain?.id) {
      const netParam = getNetworkParam();
      console.log("netParam ---> ", netParam);
      if (netParam) {
        let stakingManagerContractAdd = getContractAddress(netParam);
        setContractAddress(stakingManagerContractAdd);
        console.log("inside contractAddress -->", stakingManagerContractAdd);
      }
    }
  }, [chain?.id, networkSet, currentNetwork]);

  useEffect(() => {
    console.log("chain?.id && networkSet", currentNetwork, chain?.id);
    if (chain?.id) {
      const netParam = getNetworkParam();
      console.log("netParam ---> ", netParam);
      if (netParam) {
        let stakingManagerContractAdd = getContractAddress(netParam);
        setContractAddress(stakingManagerContractAdd);
        console.log("inside contractAddress -->", stakingManagerContractAdd);
      }
    }
  }, []);

  const { config } = usePrepareContractWrite({
    //@ts-ignore
    address: networkContractAddresses[currentNetwork][0],
    abi: [
      {
        inputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "unstake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],

    functionName: "unstake",
    //@ts-ignore
    args: [Web3.utils.toWei(userInput ? userInput.toString() : "0")],
  });
  const { data, write } = useContractWrite({
    ...config,
    // args: [Web3.utils.toWei(value.toString())]
  });
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const onUnStake = async (value: any, totalStaked: any) => {
    value = Number(value);
    totalStaked = Number(totalStaked);
    if (value <= totalStaked && write) {
      // await contractFunctions.unStakeAmount(
      //   value,
      //   address!,
      //   setAppLoading,
      //   setOpen,
      //   setSnackbar,
      //   setUserInput,
      //   onUnStakeCallback
      // );
      try {
        setAppLoading(true);
        await write();

        // var intervalVar = setInterval(async () => {
        //   console.log("receipt && isSuccess STARTED", { data, isSuccess });
        //   let receipt = data?.hash;
        //   console.log("receipt && isSuccess BEFORE", { receipt, isSuccess });
        //   if (receipt && isSuccess) {
        //     console.log("receipt && isSuccess", { receipt, isSuccess });
        //     setSnackbar({
        //       message: `You've unstaked ${userInput} DAFI`,
        //       severity: "success",
        //     });
        //     setOpen(false);
        //     setAppLoading(false);

        //     setUserInput("");
        //     onUnStakeCallback();
        //     clearInterval(intervalVar);
        //   }
        // }, 5000);
      } catch (e) {
        setAppLoading(false);
      }
    } else if (value > totalStaked) {
    }
  };

  useEffect(() => {
    if (isSuccess && data?.hash) {
      setSnackbar({
        message: `You've unstaked ${userInput} DAFI`,
        severity: "success",
      });
      setOpen(false);
      setAppLoading(false);

      setUserInput("");
      onUnStakeCallback();
    }
  }, [isLoading, isSuccess, data]);

  useEffect(() => {
    console.log(
      "CHECKERS =====> isLoading, isSuccess, data",
      isLoading,
      isSuccess,
      data
    );
  }, [isLoading, isSuccess, data]);

  const onUnStakeCallback = () => {
    let counter = 0;

    setTimeout(() => {
      setSnackbar({
        message: "Updating values",
        open: true,
      });

      afterActionContractDataCalls(address!, currentNetwork);
      afterActionAPIDataCalls(address!, currentNetwork);
    }, 5000);

    let interval = setInterval(() => {
      if (counter < 5) {
        setSnackbar({
          message: "Updating values",
          open: true,
        });

        afterActionContractDataCalls(address!, currentNetwork);
        afterActionAPIDataCalls(address!, currentNetwork);

        console.log("Yeah it is being called every 10 seconds");

        counter = counter + 1;
      } else {
        clearInterval(interval);
      }
    }, 10000);
  };

  const handleClickOpen = () => {
    setModalText(
      `Unstaking will automatically convert your d${appConstants.APP_TOKEN_NAME} rewards into ${appConstants.APP_TOKEN_NAME}`
    );
    setModalLoadText(`Unstaking ${userInput} ${appConstants.APP_TOKEN_NAME}`);
    setOpen(true);
  };

  const onChangeInput = (input: any) => {
    console.log("input", input);
    if (hasDecimal(input)) {
      let split = input.split(".");
      if (split[0].length > 18 || split[1].length > 6) {
        return;
      }
    } else if (input.length > 18) {
      return;
    }
    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    console.log("IsNan => ", Number.isNaN(Number(input)));

    if (Number.isNaN(Number(input))) {
      if (Number(input)) {
        setInputErrText(
          `The amount entered is greater than your total number of staked ${appConstants.APP_TOKEN_NAME}`
        );
        setShowInputErr(true);
      } else if (Number(input) === 0) {
        setInputErrText("Unstake amount must be greater than zero");
        setShowInputErr(true);
      }
      return;
    }
    setShowInputErr(false);
    setInputErrText("");
    if (Number(input) <= Number(userDafiStaked) && Number(input) !== 0) {
      // input = Math.abs(input);
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
    } else {
      if (Number(input)) {
        setUserInput(input);
        setInputErrText(
          `The amount entered is greater than your total number of staked ${appConstants.APP_TOKEN_NAME}`
        );
      } else if (Number(input) === 0) {
        setUserInput(input);
        setInputErrText("Unstake amount must be greater than zero");
      }
      setShowInputErr(true);
    }
  };

  const onMaxClick = () => {
    setShowInputErr(false);
    setInputErrText("");
    setUserInput(Number(userDafiStaked).toString());
  };

  return (
    <div className={classes.container}>
      <UnstakeModal
        open={open}
        onClose={() => setOpen(false)}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        stakingPeriod={stakingPeriod}
        stakingPeriodValue={stakingPeriodValue}
        minStakingPeriod={minStakingPeriod}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title}>Unstake</Typography>
              <div className={classes.horizontalLine}></div>

              {(currentNetwork === 3 ||
                currentNetwork === 2 ||
                currentNetwork === 4) && (
                <div className={classes.withdrawInfo}>
                  <img
                    src={stakePageAssets.Info}
                    className={classes.infoIcon}
                  />
                  {appConstants.APP_UNSTAKING_MESSAGE}
                </div>
              )}
              <UnstakeInfo stakedAmount={userDafiStaked!} rewards={rewards!} />

              {currentNetwork !== 1 && (
                <>
                  <AmountInput
                    userBalance={tokenBalance}
                    userInput={userInput}
                    inputErrText={inputErrText}
                    showInputErr={showInputErr}
                    onChangeInput={onChangeInput}
                    onMaxClick={onMaxClick}
                    maxDisabled={userDafiStaked! <= 0 || !networkSet}
                    disabled={!networkSet}
                  />

                  <div className={classes.marginBtm}></div>
                </>
              )}

              <BlockButton
                label={!appLoading ? "Unstake" : "Transaction Pending"}
                onClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  appLoading ||
                  (!userInput && currentNetwork !== 1) ||
                  !userInput ||
                  Number(userInput) <= 0 ||
                  !address
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Unstake;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    width: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#ffffff",
    marginBottom: "38px",
  },
  verticalLine: {
    borderLeft: "1px solid #ffffff1A",
    height: "52px",
    marginRight: "17px",
  },
  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: "#FFF",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",

    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },

  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },
}));
