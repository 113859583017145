import {
  Box,
  ClickAwayListener,
  makeStyles,
  Typography,
} from "@material-ui/core";
// import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { dashboardAssets } from "../../assets/exports";
import { contractFunctions } from "../../contract";
import {
  useAPIActions,
  useContractActions,
  useUIActions,
} from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { SUPPORTED_CHAIN_IDS } from "../../utils/app-constants";
import Text from "../Misc/Text";
import { networkOptions } from "./ModalsData";
import { useNetwork } from "wagmi";

const NetworkDropdown = () => {
  const classes = useStyles();
  // const web3React = useWeb3React();
  const { chain } = useNetwork();

  const [showDropdown, setShowDropdown] = useState<Boolean>(false);
  const [activeChain, setActiveChain] = useState(-1);

  const { currentNetwork, isTrustWallet } = useTypedSelector(
    (state) => state.uiReducer
  );

  const { setCurrentNetwork, resetUIData, setNetwork, setFirstAppLoading } =
    useUIActions();

  const { resetContractData } = useContractActions();

  const { resetAPIData } = useAPIActions();
  useEffect(() => {
    if (currentNetwork === 1) {
      setActiveChain(0);
    } else if (currentNetwork === 2) {
      setActiveChain(1);
    } else if (currentNetwork === 3) {
      setActiveChain(2);
    } else if (currentNetwork === 4) {
      setActiveChain(3);
    }
  }, [currentNetwork]);

  useEffect(() => {
    if (SUPPORTED_CHAIN_IDS.indexOf(chain?.id!) === -1) {
      setActiveChain(-1);
    }
  }, [chain?.id]);

  const onChainChanged = (network: any, index: any) => {
    // resetUIData();
    setCurrentNetwork(network); // HELL NO
    resetContractData();
    resetAPIData();
    setNetwork(false);

    setActiveChain(index);
  };

  const onNetworkChange = async (chainId: any, network: any, index: any) => {
    if (!isTrustWallet) {
      if (parseInt(chainId, 16) === chain?.id) {
        console.log("conditionTrue => ", parseInt(chainId, 16));
        setFirstAppLoading(true);
        onChainChanged(network, index);
      } else
        contractFunctions.chainChangeRequest(
          chainId,
          () => onChainChanged(network, index),
          setFirstAppLoading
        );
    } else {
      // resetUIData();
      if (currentNetwork === 1 && network === 2) {
        // dispatch(setCurrentNetwork(network));
      } else if (currentNetwork === 2 && network === 1) {
        // dispatch(setCurrentNetwork(network));
      } else {
        setCurrentNetwork(network);
      }
    }
  };
  return (
    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
      <Box className={classes.container}>
        <Box
          className={classes.itemShowContainer}
          onClick={() => setShowDropdown((val) => !val)}
        >
          {SUPPORTED_CHAIN_IDS.indexOf(chain?.id!) !== -1 &&
          activeChain !== -1 ? (
            <>
              <img
                src={networkOptions[activeChain].icon}
                className={classes.networkImage}
              />

              <Text
                text={networkOptions[activeChain].title}
                fontSize={16}
                lineHeight={14}
                fontWeight={400}
                color="#fff"
              />

              <img src={dashboardAssets.ArrowDown} style={{ marginLeft: 10 }} />
            </>
          ) : (
            <Text
              text="Unsupported Network"
              fontSize={14}
              lineHeight={14}
              fontWeight={600}
              color="#fff"
            />
          )}
        </Box>

        {showDropdown && (
          <Box className={classes.itemsContainer}>
            {networkOptions.map((option, index) => (
              <Box
                className={classes.itemContainer}
                key={index}
                onClick={() => {
                  console.log("activeChain index ", {
                    activeChain,
                    index,
                  });
                  if (activeChain !== index)
                    onNetworkChange(option.chainId, option.network, index);
                }}
              >
                <Box display="flex" alignItems="center" marginBottom="5px">
                  <Box className={classes.iconContainer}>
                    <img src={option.icon} className={classes.icon} />
                  </Box>

                  <Typography className={classes.title}>
                    {option.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NetworkDropdown;

const useStyles = makeStyles((theme) => ({
  container: {
    // height: "100%",
    width: "200px",
    height: 50,
    // marginBottom: "3rem",

    background: theme.palette.cardBgColor,

    border: "2px solid rgba(13, 243, 196, 0.25)",
    borderRadius: "10px",
    // border: "1px solid rgba(255, 255, 255, 0.08)",

    cursor: "pointer",

    position: "relative",
    marginRight: 15,

    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },

  currentItem: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: 400,
    color: "#fff",
  },

  item: {
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    fontWeight: 400,
    color: "#fff",
    padding: "1rem",

    "&:hover": {
      textDecoration: "none",
    },
  },

  itemContainer: {
    padding: "10px 0px",
  },

  itemsContainer: {
    position: "absolute",
    top: 48,
    width: "100%",
    right: 0,
    borderRadius: 10,

    background: theme.palette.cardBgColor,

    border: "1px solid rgba(255, 255, 255, 0.28)",

    zIndex: 10000,

    // borderRadius: "25px",

    boxShadow:
      "inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)",
    backdropFilter: "blur(40px)",

    padding: "10px 15px",
  },

  itemShowContainer: {
    // padding: "0 2rem",

    height: "100%",
    width: "100%",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    padding: "0 15px",
  },
  infoIcon: {
    color: "#fff",
  },

  iconContainer: {
    height: 20,
    width: 20,
    // backgroundColor: "#797290",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    borderRadius: 10,
  },
  icon: {
    height: 20,
    width: 20,
  },
  title: {
    fontSize: "16px",
    lineHeight: "14px",
    fontWeight: 400,
    color: "#fff",
  },

  description: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#fff",
  },
  networkImage: {
    height: 20,
    width: 20,
    marginRight: 8,
  },

  modal: {
    display: "flex",
    // marginTop: "10%",
    alignItems: "center",
    justifyContent: "center",
  },
}));
