import { Grid, Typography } from "@material-ui/core";
import { FC, useEffect } from "react";
import { APP_DASHBOARD_TITLE } from "../../utils/app-constants";
import DashboardCards from "./DashboardCards";
import PoolDominanceCard from "./PoolDominanceCard";
import RewardGraphCard from "./RewardGraphCard";
import TopUsersCard from "./TopUsersCard";
import TransactionCard from "./TransactionCard";

const Dashboard: FC = () => {
  useEffect(() => {
    let textContent: HTMLTitleElement = document.querySelector("title")!;
    textContent.textContent = APP_DASHBOARD_TITLE;
  }, []);
  return (
    <Grid>
      <Grid container justifyContent="space-between">
        <DashboardCards />
      </Grid>

      <Grid container>
        <Grid item xs={12} md={7} lg={8}>
          <RewardGraphCard />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <PoolDominanceCard />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={7} lg={8}>
          <TransactionCard />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <TopUsersCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
