import {
  Backdrop,
  Box,
  Button,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { dashboardAssets } from "../../assets/exports";
import { contractFunctions } from "../../contract";
import { useAPIActions, useUIActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";
import { SUPPORTED_CHAIN_IDS } from "../../utils/app-constants";
import { conciseAddress, sixDigitsFormatter } from "../../utils/formatters";
import ConnectWalletModal from "../Modal/ConnectWalletModal";
import HelpDropdown from "../Modal/HelpDropdown";
import MessageModal from "../Modal/MessageModal";
import NetworkDropdown from "../Modal/NetworkDropdown";
import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { useAccount, useNetwork } from "wagmi";

const Header = () => {
  const { setCurrentNetwork, resetUIData } = useUIActions();
  const { resetAPIData } = useAPIActions();

  const { open, close } = useWeb3Modal();

  const { currentNetwork } = useTypedSelector((state) => state.uiReducer);
  const { isCrown } = useTypedSelector((state) => state.apiReducer);
  const { tokenBalance } = useTypedSelector((state) => state.contract);

  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const classes = useStyles();

  const location = useLocation();

  const [modal, setModal] = useState(false);

  const [messageModal, setMessageModal] = useState(false);

  return (
    <>
      <Box className={classes.connectBtnContainer}>
        <Box></Box>
        {!address && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.infoContainer}
          >
            <img src={dashboardAssets.WarningBlue} style={{ height: 24 }} />
            <Typography className={classes.info}>
              Please connect your wallet to view your information
            </Typography>
          </Box>
        )}
        <Box className={classes.connectWalletContainer}>
          {isCrown && (
            <Button className={classes.crown}>
              <img src={dashboardAssets.Crown} width="100%" height="100%" />
            </Button>
          )}

          {address && currentNetwork !== 0 && (
            <Button
              className="addDafiBox-tooltip"
              onClick={() => contractFunctions.registerToken()}
            >
              <img src={dashboardAssets.DafiAdd} />

              <Box className="addDafiBox-tooltipText">
                <Typography className={classes.dafiAddToolTipText}>
                  Add {appConstants.APP_TOKEN_NAME} to your MetaMask wallet
                </Typography>
              </Box>
            </Button>
          )}
          {address && <NetworkDropdown />}

          {currentNetwork !== 0 ? (
            <Box
              className={`${classes.connectBtn} ${
                address && classes.connectedBtn
              }`}
            >
              {address && (
                <Box className={classes.dafiCountBox}>
                  <Typography className={classes.dafiCountText}>
                    {sixDigitsFormatter(
                      Number(Number(tokenBalance).toFixed(4))
                    )}
                    {" " + appConstants.APP_TOKEN_NAME}
                  </Typography>
                </Box>
              )}
              <Button
                className={`${
                  address ? classes.addressBox : classes.connectBtnOnly
                }`}
                onClick={() => setModal(true)}
                variant={address && address ? "outlined" : "contained"}
              >
                {address && address && (
                  <div className={classes.activeDot}></div>
                )}
                <Typography className={`${address && classes.connectedText}`}>
                  {address && address
                    ? conciseAddress(address)
                    : "CONNECT WALLET"}
                </Typography>
              </Button>
            </Box>
          ) : (
            !address && (
              <Button
                className={`${classes.connectBtnOnly}`}
                onClick={() => open()}
                variant={"contained"}
              >
                <Typography>CONNECT WALLET</Typography>
              </Button>
            )
          )}

          {/* {!address && <Web3Button />} */}

          {/* {SUPPORTED_CHAIN_IDS.indexOf(web3React.chainId!) === -1 && (
            <Box className={classes.unsupportedBox}>
              <Typography className={classes.connectedText}>
                Unsupported Network
              </Typography>
            </Box>
          )} */}
          <HelpDropdown />
        </Box>
      </Box>

      {chain?.id === 1 &&
        (location.pathname === "/" || location.pathname === "/stake") && (
          <Button
            className={classes.mobileNetworkSwitchBtn}
            onClick={() => {
              resetUIData();
              resetAPIData();

              if (currentNetwork === 1) {
                setCurrentNetwork(2);
              } else {
                setCurrentNetwork(1);
              }
            }}
          >
            <Typography style={{ color: "#fff" }}>
              {currentNetwork === 1
                ? "Switch to Ethereum V2"
                : "Switch to Ethereum V1"}
            </Typography>
          </Button>
        )}

      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => setModal(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <ConnectWalletModal onSuccess={() => setModal(false)} />
        </Fade>
      </Modal> */}
      <Modal
        className={classes.modal}
        open={messageModal}
        onClose={() => setMessageModal(false)}
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <Fade in={messageModal}>
          <MessageModal
            onSuccess={() => setMessageModal(false)}
            message="v1 is undergoing maintenance and will be resuming shortly."
          />
        </Fade>
      </Modal>
    </>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  connectBtn: {
    color: "#fff",
    width: 240,
    height: 50,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "10px",
    textTransform: "capitalize",
    marginRight: 10,
  },
  crown: {
    width: "50px",
    height: "50px",
    padding: "0px 4px !important",
    marginRight: "16px",
    [theme.breakpoints.down(786)]: {
      display: "none",
    },
  },

  unsupportedBox: {
    height: 50,
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    marginRight: 20,
    padding: "0 20px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: 10,
  },
  connectBtnContainer: {
    marginBottom: 30,
    display: "flex",

    justifyContent: "space-between",
    // marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginRight: 0,
    },

    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },

    [theme.breakpoints.down(500)]: {
      alignItems: "stretch",
    },
  },

  connectWalletContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      // marginBottom: 20,
      justifyContent: "flex-end",
      display: "flex",
    },
  },

  modal: {
    display: "flex",
    // marginTop: "10%",
    alignItems: "center",
    justifyContent: "center",
  },

  info: {
    fontSize: 14,
    lineHeight: "20px",
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      marginLeft: 10,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },

  infoContainer: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 10,
    minHeight: 50,
    padding: "0 30px",

    [theme.breakpoints.down("sm")]: {
      width: "80%",
      justifyContent: "center",
      alignSelf: "center",
    },

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "90%",
      minHeight: 100,
      justifyContent: "center",
    },
  },

  connectedBtn: {
    background: theme.palette.cardBgColor,

    border: "1px solid rgba(255, 255, 255, 0.28)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    width: 350,

    [theme.breakpoints.down(500)]: {
      width: "90%",
    },

    [theme.breakpoints.down(400)]: {
      width: "90%",
      marginRight: 8,
    },
  },

  connectedText: {
    color: "#fff",
    fontSize: 16,
    lineHeight: "14px",
    fontWeight: 400,
  },

  activeDot: {
    background: "linear-gradient(180deg, #00E2FE 0%, #19FADD 100%)",
    height: 20,
    width: 20,
    borderRadius: 100,
    marginRight: 10,

    [theme.breakpoints.down(600)]: {
      height: 10,
      width: 10,
    },
  },

  networkBtn: {
    height: 50,
    background: "rgba(15, 8, 36, 0.55)",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    borderRadius: "10px",
    marginRight: 15,
    width: "fit-content",
    [theme.breakpoints.down(500)]: {
      width: 90,
    },

    [theme.breakpoints.down(400)]: {
      width: 80,
      marginRight: 8,
    },
  },

  networkImage: {
    height: 20,
    width: 20,
    marginRight: 8,
  },

  InfoButton: {
    padding: 0,
    background: "#190E2B",

    border: "1px solid rgba(255, 255, 255, 0.58)",
    borderRadius: "10px",
    [theme.breakpoints.down(500)]: {},
  },

  infoIcon: {
    color: "#fff",
  },

  dafiAddToolTipText: {
    fontSize: 14,
    lineHeight: "17px",
    fontWeight: 600,
    color: "#fff",
  },

  networkTextBox: {
    background: "#190E2B",
    borderRadius: "10px",
    display: "flex",
    height: 40,
    padding: "0 10px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },

  networkTextBoxSelected: {
    border: "1px solid rgba(130, 158, 255, 1)",
  },

  addressBox: {
    background: "rgba(15, 8, 36, 0.55)",
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    height: "100%",

    borderRadius: 10,
    flex: 4,
  },

  connectBtnOnly: {
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    height: "100%",

    borderRadius: 10,
    flex: 4,

    marginRight: 20,
  },
  dafiCountBox: {
    padding: "15px",
    flex: 2,

    [theme.breakpoints.down(400)]: {
      padding: "10px",
    },
  },
  dafiCountText: {
    fontSize: 16,
    lineHeight: "14px",
    fontWeight: 400,
    color: "#fff",
  },

  mobileNetworkSwitchBtn: {
    background: "rgba(15, 8, 36, 0.55)",
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    height: "50px",

    borderRadius: 10,
    margin: "0 auto",
    marginBottom: 20,

    [theme.breakpoints.up(600)]: {
      display: "none",
    },
  },
}));
