import { contractFunctions } from "../contract";

export const getRewardGraphDataFormatted = (payload: any) => {
  return {
    timestampArray: [
      ...payload.timedataForWeekay.sort((x: any, y: any) => x - y),
    ].map((item) => Number(item)),
    rewardArray: [
      ...payload.rewardBalancedataForWeekay.map((val: any) => {
        let formatted = val?.toFixed(20);

        return Number(formatted)?.toFixed(5);
      }),
    ],
  };
};

export const getSuperPoolGraphDataFormatted = (payload: any) => {
  return {
    timestampArray: [
      ...payload.timedataForWeekay.sort((x: any, y: any) => x - y),
    ].map((item) => Number(item)),
    stakesArray: [
      ...payload.rewardBalancedataForWeekay.map((val: any) => {
        let formatted = val?.toFixed(20);

        return Number(formatted)?.toFixed(5);
      }),
    ],
  };
};

export const getCoinMarketGraphFormatted = (payload: any) => {
  return {
    timestampArray: [...payload?.prices.map((item: any) => item[0])],
    pricesArray: [...payload?.prices.map((item: any) => item[1])],
  };
};

export const getProtocolTwitterDataFormatted = (payload: any) => {
  return payload.data.map((tweet: any) => {
    return tweet.text;
  });
};

export const getTotalTokensStakedFormatted = (payload: any): number => {
  return Number(
    Object.values(payload).reduce(
      (total: any, val: any) => Number(val) + Number(total),
      0
    )
  );
};

export const sortArrayByTimestamp = (payload: any) => {
  return payload.sort((x: any, y: any) => y.time * 1000 - x.time * 1000);
};

export const sortTopUsersArray = (payload: any) => {
  return payload.sort((x: any, y: any) => Number(y.amount) - Number(x.amount));
};

export const superPoolsGraphRoutes = {
  eth: "ethereum",
  ethV2: "ethereum-v2-mainnet",
  polygonV2: "polygon-mainnet",
  binanceV2: "binance-v2-mainnet",
};
