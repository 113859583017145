import moment from "moment";
import Web3 from "web3";

export const getTimezoneOffset = () => {
  function z(n: any) {
    return (n < 10 ? "0" : "") + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + z(offset % 60);
};

export const conciseAddress = (address: string) => {
  if (Web3.utils.isAddress(address)) {
    return `${address.slice(0, 6)}...${address.slice(
      address.length - 4,
      address.length
    )}`;
  }
  return "-";
};

export const sixDigitsFormatter = (num: number) => {
  if (num < 1) {
    return num;
  }
  if (false && num <= 9999.99) {
    let amount = truncateExact(num, 2);
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (false && num <= 999999.99) {
    let amount = Math.trunc(num);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    let amount = truncate(num / si[i].value) + si[i].symbol;
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const truncateExact = (num: number, fixed: number) => {
  if (num) {
    let sNumber = num.toString();
    let index = sNumber.indexOf(".");
    let newNumber = index !== 0 ? sNumber : "0" + sNumber;
    let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    let number = newNumber.toString().match(re);
    if (number) {
      return number[0];
    }
  } else {
    return num;
  }
};
export const truncate = (num: number) => {
  if (num) {
    const floatedTo = num >= 1 ? 2 : 3;
    let re: RegExp = new RegExp(
      "^-?\\d+(?:.\\d{0," + (floatedTo || -1) + "})?"
    );
    let number = num?.toString()?.match(re);
    if (number) {
      return number[0];
    }
  } else {
    return num;
  }
};

export const numberTest = (n: any) => {
  var result = n - Math.floor(n) !== 0;
  if (result && n < 1) return true;
  else return false;
};

export const trunc = (val: number) => {
  let number = ogTrunc(val);
  if (number) {
    let _val: string = number.toString();
    const _val2 = _val.split(".");
    if (_val2[0].length > 8) {
      console.log("");
      const _val = _val2[0].slice(0, 4);
      const __val = _val2[0].slice(_val2.length - 4, -1);
      const joined = [_val, __val].join("..");
      return joined;
    }
    if (_val.includes(".")) {
      const splitedVal = _val.split(".");
      if (val < 0.0001) {
        return "<0.0001";
      }
      return [
        splitedVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        splitedVal[1],
      ].join(".");
    } else {
      return Number(_val).toLocaleString();
    }
  }
};

export const ogTrunc = (val: number) => {
  if (!Number(val)) {
    return 0;
  }
  const _val: Number = (val * 100 - Math.trunc(val) * 100) / 100;
  if (!_val) {
    return Math.trunc(Number(val));
  }
  let decimal = 0;
  if (_val < 0.000001) {
    decimal = 7;
    return parseFloat(val.toString()).toFixed(7);
  } else if (_val < 0.00001) {
    decimal = 6;
  } else if (_val < 0.0001) {
    decimal = 5;
  } else if (_val < 0.001) {
    decimal = 4;
  } else if (_val < 0.01) {
    decimal = 3;
  } else if (_val < 1) {
    decimal = 2;
  } else {
    return Math.trunc(val).toString();
  }
  let number = _val
    .toString()
    ?.match(new RegExp("^-?\\d+(?:.\\d{0," + decimal + "})?"));
  if (number) {
    return Math.trunc(val) + Number(parseFloat(number[0]).toFixed(decimal));
  }
};

export const calculateSpecificDaysToTimestamp = (createdOn: any, days: any) => {
  console.log("calculateSpecificDaysToTimestamp => ", { createdOn, days });
  let createdOnDate = moment.unix(createdOn);

  let daysToDate = createdOnDate.add(days, "days").format("YYYY MMM DD h:mm A");

  let nowDate = moment();

  let timestampDiff = nowDate.diff(daysToDate);

  let diffInDays = moment.duration(timestampDiff).days();

  let roundedDays = diffInDays;
  console.log("logss", {
    roundedDays,
    roundedDaysY: days - roundedDays,
    createdOnDate,
    daysToDate,
    nowDate,
    timestampDiff,
    diffInDays,
  });

  return diffInDays < 0 ? false : true;
};

export const pad = (num: number, size = 4): string => {
  let numInString: string = num.toString();
  while (numInString.length < size) numInString = "0" + numInString;
  return numInString;
};

export const hasDecimal = (num: any) => {
  return num.includes(".") && /\d/.test(num);
};

export const getDifferenceInDaysFromNow = (
  stakedTimestamp: any,
  minStakingPeriod: any
) => {
  console.log("stakedTimestamp => ", stakedTimestamp);
  var stakedAt = moment(stakedTimestamp * 1000).format("MMM DD h:mm A");
  var stakedAtObj = moment(stakedAt);

  let minStakingDays = (minStakingPeriod * 1000) / (60 * 60 * 24 * 1000);

  console.log(stakedAt);
  var nextLimitDate = moment(stakedTimestamp * 1000)
    .add(minStakingDays, "days")
    .format("YYYY MMM DD h:mm A");

  console.log({ nextLimitDate });
  console.log(typeof stakedAtObj);
  var now = moment().format("YYYY MMM DD h:mm A");
  var nowObj = moment(now);
  console.log(now);
  console.log(typeof nowObj);
  var diff = nowObj.diff(nextLimitDate);
  console.log(diff);
  var duration = moment.duration(diff, "milliseconds");
  var days = duration.asDays();
  console.log(days);

  let roundedDays = Math.abs(Math.round(days));

  if (roundedDays === 0) {
    let hours = Math.abs(duration.hours());

    if (hours > 0) {
      return `${Math.abs(duration.hours())} hour(s)`;
    } else {
      return `${Math.abs(duration.asMinutes())} min(s)`;
    }
  } else {
    return `${roundedDays} day(s)`;
  }
};

export const accumulateUniqueUsers = (array: any) => {
  let uniqueUser: any = {};

  array.map((user: any) => {
    let staker = uniqueUser[user.staker];

    if (staker) {
      uniqueUser[user.staker] = {
        ...uniqueUser[user.staker],
        amount: Number(uniqueUser[user.staker]?.amount) + Number(user.amount),
      };
    } else {
      uniqueUser[user.staker] = user;
    }
  });

  console.log("accumulateUniqueTopUsers => ", Object.values(uniqueUser));
  return Object.values(uniqueUser);
};

export const getBalanceNumberFormatted = (number: any) => {
  if (number) {
    let arr = number.toString().split(".");

    if (arr[0] > 0 && arr.length > 1) {
      return [arr[0], arr[1]?.slice(0, 2)].join(".");
    } else {
      return number;
    }
  } else {
    return 0;
  }
};
