import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appConstants } from "../../utils";

import TweetCard from "../Misc/Market/TweetCard";
import Text from "../Misc/Text";

const TwitterFeedCard = () => {
  const classes = useStyles();

  const { protocolTwitterData } = useTypedSelector((state) => state.apiReducer);

  return (
    <Box className={classes.container}>
      <Box display="flex" marginBottom={"20px"}>
        <Text
          text="Twitter Feed"
          fontSize={24}
          lineHeight={19}
          marginRight={8}
        />

        <Text
          text={appConstants.APP_TWITTER_HANDLE}
          fontSize={16}
          lineHeight={19}
          color="rgba(255, 255, 255, 0.27)"
        />
      </Box>

      <Box className={classes.tweetBox}>
        {protocolTwitterData.map((tweet: any) => (
          <TweetCard
            text={tweet}
            attachment={tweet.attachments ? tweet?.attachments[0] : null}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TwitterFeedCard;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    minHeight: 270,

    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down(650)]: {
      height: 500,
    },
  },

  tweetBox: {
    maxHeight: 330,
    overflow: "scroll",

    [theme.breakpoints.down(1100)]: {
      maxHeight: 400,
    },

    [theme.breakpoints.down(650)]: {
      maxHeight: "100%",
    },
  },
}));
