import {
  Box,
  Button,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { TransactionHistoryType } from "../../state/Type/APITypes";
import { appConstants } from "../../utils";
import GraphFilterButton from "../Buttons/GraphFilterButton";
import CustomCard from "../Misc/CustomCard";
import Text from "../Misc/Text";
import TransactionTable from "../Misc/TransactionTable";

const TransactionCard = () => {
  const classes = useStyles();

  const { transactionsHistory } = useTypedSelector((state) => state.apiReducer);

  const [filters, setFilters] = useState([true, false, false]);

  const [filter, setFilter] = useState("Stake");

  const [txLength, setTxLength] = useState(0);

  const [trimmedHistory, setTrimmedHistory] = useState<
    Array<TransactionHistoryType>
  >([]);
  const [currentPageCount, setCurrentPageCount] = useState(0);

  const [filteredArray, setFilteredArray] = useState<
    Array<TransactionHistoryType>
  >([]);

  useEffect(() => {
    setFilteredArray(
      transactionsHistory.filter((item) => item.type === filter)
    );
    setCurrentPageCount(0);
  }, [transactionsHistory, filter]);

  useEffect(() => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          currentPageCount * appConstants.COUNT_PER_PAGE,
          appConstants.COUNT_PER_PAGE * (currentPageCount + 1)
        )
      );
    } else {
      setTrimmedHistory([]);
    }
  }, [filteredArray]);

  useEffect(() => {
    setTxLength(filteredArray.length);
  }, [filteredArray]);

  const getPrevTrimmedArrayElements = () => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          (currentPageCount - 1) * appConstants.COUNT_PER_PAGE,
          appConstants.COUNT_PER_PAGE * currentPageCount
        )
      );
      setCurrentPageCount((val) => val - 1);

      // 0 - 5
      // 5 - 10
      // 10 -15
    }
  };

  const getNextTrimmedArrayElements = () => {
    if (filteredArray.length > 0) {
      setTrimmedHistory(() =>
        filteredArray.slice(
          (currentPageCount + 1) * appConstants.COUNT_PER_PAGE,
          appConstants.COUNT_PER_PAGE * (currentPageCount + 2)
        )
      );
      setCurrentPageCount((val) => val + 1);

      // 0 - 5
      // 5 - 10
      // 10 -15
    }
  };

  return (
    <CustomCard customStyles={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
          marginBottom={"24px"}
          className={classes.header}
        >
          <Text
            text={`Transactions (${txLength})`}
            customStyles={classes.text}
          />

          <Box className={classes.filterButtonsContainer}>
            <GraphFilterButton
              text={`Stake ${appConstants.APP_TOKEN_NAME}`}
              active={filters[0]}
              onClick={() => {
                setFilter("Stake");

                setFilters([true, false, false]);
              }}
            />
            <GraphFilterButton
              text={`Unstake ${appConstants.APP_TOKEN_NAME}`}
              active={filters[1]}
              onClick={() => {
                setFilter("Unstake");
                setFilters([false, true, false]);
              }}
            />
            <GraphFilterButton
              text="Claim"
              active={filters[2]}
              onClick={() => {
                setFilter("Claim");
                setFilters([false, false, true]);
              }}
            />
          </Box>
        </Box>
        <TransactionTable
          transactions={trimmedHistory}
          txNo={appConstants.COUNT_PER_PAGE * currentPageCount}
        />

        {filteredArray.length > 0 && (
          <Box className={classes.paginationContainer}>
            <Button
              className={classes.paginationBtn}
              onClick={getPrevTrimmedArrayElements}
              disabled={currentPageCount === 0}
            >
              <ArrowBackIosOutlined className={classes.paginationIcon} />
            </Button>
            <Typography className={classes.paginationText}>
              {currentPageCount + 1} of{" "}
              {filteredArray?.length <= appConstants.COUNT_PER_PAGE
                ? "1"
                : Math.ceil(
                    filteredArray?.length / appConstants.COUNT_PER_PAGE
                  )}
            </Typography>
            <Button
              className={classes.paginationBtn}
              onClick={getNextTrimmedArrayElements}
              // disabled={
              //   Math.round(filteredArray?.length / COUNT_PER_PAGE) ===
              //   currentPageCount + 1
              // }

              disabled={
                (currentPageCount + 1) * appConstants.COUNT_PER_PAGE >=
                filteredArray?.length
              }
            >
              <ArrowForwardIosOutlined className={classes.paginationIcon} />
            </Button>
          </Box>
        )}
      </CardContent>
    </CustomCard>
  );
};

export default TransactionCard;

const useStyles = makeStyles((theme) => ({
  card: {
    justifyContent: "space-between",
    marginBottom: 16,
    marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,

      height: 500,
      marginRight: 0,
      paddingBottom: 6,
    },

    paddingBottom: 6,
  },
  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: 12,
  },

  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    border: 0,
    padding: 0,
    paddingBottom: "0 !important",
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 12,
    },
  },
  paginationContainer: {
    padding: "0",
    display: "flex",
    marginTop: "auto",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down(600)]: {
      padding: "5px 0",
    },

    height: 50,
  },

  paginationText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "rgba(255,255,255,.8)",
    margin: "0 5px",
  },
  paginationBtn: {
    minWidth: "auto",
  },

  paginationIcon: {
    color: "#fff",
    fontSize: 16,
  },

  filterButtonsContainer: {
    display: "flex",
  },
}));
