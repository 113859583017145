import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

// import { useWeb3React } from "@web3-react/core";
import { appConstants } from "../../utils";
import { numberTest, sixDigitsFormatter, trunc } from "../../utils/formatters";
import Text from "../Misc/Text";
import { DashboardCardStyleType, DashboardCardType } from "./type";
import { useAccount, useNetwork } from "wagmi";

const DashboardCard = ({ card, index }: DashboardCardType) => {
  // const web3React = useWeb3React();
  const { address } = useAccount();
  const { chain } = useNetwork();

  const classes = useStyles({
    chain,
    index,
  });

  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.cardContent}>
        {card.text === appConstants.APP_FEES_COLLECTED_TEXT ? (
          <img
            className={classes.image}
            src={card.icon}
            style={{ height: "80px" }}
          />
        ) : (
          <img
            className={classes.image}
            src={card.icon}
            style={{ height: "80px" }}
          />
        )}

        <Box className={classes.textContainer}>
          {appConstants.CHAIN_IDS.find((e) => e === chain?.id) ||
          card.public ? (
            <Box>
              {card.text === appConstants.APP_FEES_COLLECTED_TEXT ? (
                <Text
                  fontSize={28}
                  lineHeight={20}
                  fontWeight={600}
                  color="#fff"
                  marginBottom={20}
                  // marginTop={75}
                  text={
                    Number(card.value) > 0 && !numberTest(card?.value)
                      ? sixDigitsFormatter(card?.value)
                      : Number(card.value)
                      ? trunc(card?.value)
                      : 0
                  }
                />
              ) : (
                <Text
                  fontSize={28}
                  lineHeight={20}
                  fontWeight={600}
                  color="#fff"
                  marginBottom={20}
                  text={
                    Number(card.value) > 0 && !numberTest(card?.value)
                      ? sixDigitsFormatter(card?.value)
                      : Number(card.value)
                      ? trunc(card?.value)
                      : 0
                  }
                />
              )}
            </Box>
          ) : (
            <Box className={classes.noValue}></Box>
          )}

          <Box display="flex" alignItems="center">
            <Text
              text={card.text}
              fontSize={18}
              lineHeight={24}
              textAlign="center"
              customStyles={classes.text}
            />
            {card.infoText && (
              <Tooltip
                title={card.infoText}
                arrow
                // style={{ marginLeft: -8 }}
                TransitionComponent={Zoom}
              >
                <IconButton aria-label="delete" className={classes.iconButton}>
                  <InfoOutlined style={{ color: "#fff", fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;

const useStyles = makeStyles<Theme, DashboardCardStyleType>((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "none",
    height: 230,
    minWidth: 200,
    [theme.breakpoints.down("md")]: {
      minWidth: "229px",
    },

    [theme.breakpoints.down(1200)]: {
      minWidth: "200px",
    },

    [theme.breakpoints.down(1050)]: {
      minWidth: "260px",
    },

    [theme.breakpoints.down(1000)]: {
      minWidth: "250px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "258px",
    },

    [theme.breakpoints.down(600)]: {
      minWidth: "300px",
    },
    [theme.breakpoints.up("md")]: {
      // minWidth: "173px",
    },
    [theme.breakpoints.up("lg")]: {
      // minWidth: "184px",
    },
    marginBottom: 16,
    [theme.breakpoints.up(1600)]: {
      width: 250,
      maxWidth: 250,
    },
  },
  iconButton: {
    padding: 0,
    marginLeft: 5,
  },
  value: {
    marginBottom: 20,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  image: {
    alignItems: "center",
    // width: 80,
    // height: 80,
    marginBottom: 24,
  },
  text: {
    whiteSpace: "nowrap",
  },
  infoIcon: {
    padding: 0,
    paddingLeft: 12,
  },
  noValue: {
    height: 6,
    width: 40,
    background: theme.palette.themeColor1,
    marginBottom: ({ chain, index }) =>
      chain?.id == undefined && index == 4 ? 10 : 20,
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: ({ chain, index }) =>
      chain?.id == undefined && (index == 2 || index == 3 || index == 4)
        ? 15
        : 0,
  },
}));
