import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StakeTabsButtonType } from "./type";

const StakeTabsButton = ({
  label,
  selected,
  onChangeTab,
}: StakeTabsButtonType) => {
  const classes = useStyles();

  return (
    <div>
      <button
        onClick={() => onChangeTab(label)}
        className={[
          classes.btn,
          selected == label ? classes.selected : "",
        ].join(" ")}
      >
        {label}
      </button>
    </div>
  );
};

export default StakeTabsButton;

const useStyles = makeStyles({
  btn: {
    backgroundColor: "transparent",
    border: "transparent",
    width: "155px",
    height: "40px",
    color: "#fff",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    "@media(max-width:550px)": {
      width: "130px",
    },

    "@media(max-width:450px)": {
      width: "120px",
    },
    "@media(max-width:400px)": {
      width: "105px",
    },
  },
  selected: {
    backgroundColor: "rgba(15, 8, 36, 0.55);",
    border: "1px solid rgba(230, 231, 233, 0.1)",
    borderRadius: "50px",
  },
});
