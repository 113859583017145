import { combineReducers } from "redux";

import APIReducer from "./APIReducer";
import UIReducer from "./UIReducer";
import ContractReducer from "./ContractReducer";

const reducers = combineReducers({
  apiReducer: APIReducer,
  uiReducer: UIReducer,
  contract: ContractReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
