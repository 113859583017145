import Web3 from "web3";
import { initContract, versionKeys } from "./contractInit";
import { StakingABIV2 } from "../types/StakingABIV2";
import { DatabaseABIV2 } from "../types/DatabaseABIV2";
import { NetworkDemandABIV2 } from "../types/NetworkDemandABIV2";
import { DafiABI } from "../types/DafiABI";
import { InitializeContractStuffType } from "./types";

let stakingManagerContract: StakingABIV2;
let stakingDatabaseContract: DatabaseABIV2;
let networkDemandContract: NetworkDemandABIV2;
let tokenContract: DafiABI;
let web3: Web3;

web3 = new Web3(
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
);

export const initializeContractStuff: InitializeContractStuffType = async (
  network,
  provider,
  setNetwork
) => {
  try {
    web3 = new Web3(provider);

    let version: versionKeys = network === 1 ? "v1" : "v2";

    [
      stakingManagerContract as any,
      stakingDatabaseContract as any,
      networkDemandContract as any,
      tokenContract as any,
    ] = await initContract(network, web3, version);

    setNetwork(true);

    console.log("initializeContractStuff", {
      stakingManagerContract,
      stakingDatabaseContract,
      networkDemandContract,
      tokenContract,
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  stakingManagerContract,
  stakingDatabaseContract,
  networkDemandContract,
  tokenContract,
  web3,
};
