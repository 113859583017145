import { Box, makeStyles, Theme, Typography } from "@material-ui/core";

const NotFound = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.text404}>404</Typography>
      <Typography className={classes.text404Sub}>Page Not Found</Typography>
    </Box>
  );
};

export default NotFound;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    height: "60vh",
  },

  text404: {
    fontSize: "150px",
    lineHeight: "130px",
    color: theme.palette.textColor,
  },

  text404Sub: {
    fontSize: "40px",
    fontWeight: 600,
    color: theme.palette.textColor,
  },
}));
