import { ContractActionType } from "../action-types/ContractActionType";
import { ContractAction } from "../actions/ContractAction";
import { ContractState } from "../Type/ContractTypes";

const initialState: ContractState = {
  tokenBalance: 0,

  dashboardCardsData: {
    feesCollected: 0,
    userDafiStaked: 0,
    rewards: 0,
    potentialRewards: 0,
  },
  rewardsByAmount: 0,
  minStakingAmount: 0,

  stakingDetails: {
    stakingPeriod: false,
    stakingPeriodValue: "",
    minStakingPeriod: "",
  },

  demandFactorFormatted: 0,
};

const reducer = (
  state: ContractState = initialState,
  action: ContractAction
) => {
  switch (action.type) {
    case ContractActionType.SET_TOKEN_BALANCE:
      return {
        ...state,
        tokenBalance: action.payload,
      };

    case ContractActionType.SET_DASHBOARD_CARDS_DATA:
      return {
        ...state,
        dashboardCardsData: {
          ...state.dashboardCardsData,
          ...action.payload,
        },
      };

    case ContractActionType.SET_MINIMUM_STAKE_AMOUNT:
      return {
        ...state,
        minStakingAmount: action.payload,
      };

    case ContractActionType.SET_STAKING_DETAILS:
      return {
        ...state,
        stakingDetails: action.payload,
      };

    case ContractActionType.SET_DEMAND_FACTOR:
      return {
        ...state,
        demandFactorFormatted: action.payload,
      };

    case ContractActionType.SET_REWARD_POTENTIAL_REWARD:
      return {
        ...state,
        dashboardCardsData: { ...state.dashboardCardsData, ...action.payload },
      };

    case ContractActionType.SET_REWARDS_BY_AMOUNT:
      return {
        ...state,
        rewardsByAmount: action.payload,
      };
    case ContractActionType.RESET_CONTRACT_DATA:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
