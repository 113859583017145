import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { BlockButtonType } from "./type";

const BlockButton = ({ label, onClick, disabled }: BlockButtonType) => {
  return (
    <CustomBtn fullWidth disabled={disabled} onClick={() => onClick()}>
      {label}
    </CustomBtn>
  );
};

BlockButton.defaultProps = {
  onClick: () => {},
  disabled: false,
};
export default BlockButton;

const CustomBtn = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
    padding: "6px 12px",
    border: "1px solid",
    borderRadius: "10px",
    background: theme.palette.themeColor1,

    height: "50px",
    "&:hover": {
      background: `rgba(${theme.palette.themeColor1RGBA},.7)`,
      boxShadow: "none",
    },
    "@media(max-width:550px)": {
      fontSize: "15px",
      fontWeight: 500,
    },
    "&:disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      color: "#fff",
      border: "1px solid transparent",
    },
  },
}))(Button);
