import React from "react";
import { makeStyles, Box, Typography, Theme } from "@material-ui/core";
import { TokenStatType } from "./type";
import { marketAssets } from "../../../assets/exports";

const TokenStat = ({ label, value, trend }: TokenStatType) => {
  const classes = useStyles({ trend });

  return (
    <Box className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      <Box display="flex">
        <Typography className={classes.value}>{value}</Typography>
        {trend && (
          <Box display="flex" alignItems="center">
            <Typography className={classes.trend}>{`${trend}%`}</Typography>
            {trend > 0 ? (
              <img src={marketAssets.TrendingUp} />
            ) : (
              <img src={marketAssets.TrendingDown} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TokenStat;

const useStyles = makeStyles<Theme, { trend: any }>((theme) => ({
  container: {
    borderBottom: "2px solid rgba(255, 255, 255, 0.02)",
    padding: "20px 0",
  },

  label: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 5,
    fontWeight: 400,
  },

  value: {
    fontSize: "20px",
    lineHeight: "20px",
    color: "#fff",
    fontWeight: 600,
    marginRight: 7,
  },

  trend: {
    fontSize: "14px",

    fontWeight: 400,
    letterSpacing: "-0.5px",

    color: (props) => (props.trend > 0 ? "#79E77D" : "#FF3636"),
    marginRight: 3,
  },
}));
